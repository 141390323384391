<template>
    <div class="columns is-centered claim-new">
        <div class="column is-full-tablet is-three-quarters-widescreen">
            <div id="claim-box" class="has-text-centered toast-container box">
                <b-tabs type="is-toggle" v-model="currentTab" expanded destroy-on-hide @change="changeTab">
                	<!-- 0 -->
                    <b-tab-item label="Default">
                        <hr>
                        <NewClaim type="default" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 1 -->
                    <b-tab-item label="Emergency">
                        <hr>
                        <NewClaim type="emergency" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 2 -->
                    <b-tab-item label="Temporary Visit">
                        <hr>
                        <NewClaim type="temporary" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 3 -->
                    <b-tab-item label="PPS Only">
                        <hr>
                        <NewClaim type="pps" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 4 -->
                    <b-tab-item label="EC Resident">
                        <hr>
                        <NewClaim type="EU" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 5 -->
                    <b-tab-item label="Maternity">
                        <hr>
                        <NewClaim type="maternity" :user="this.user" @settab="setCurrentTab($event)"></NewClaim>
                    </b-tab-item>
                    <!-- 6 -->
                    <b-tab-item label="Insured">
                        <hr>
                        <ClaimSelect :user="this.user"></ClaimSelect>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
    import NewClaim from "./NewClaim";
    import ClaimSelect from "./PrivateClaim/ClaimSelect";
    export default {
        components: {NewClaim, ClaimSelect},
        data(){
            return {
                // TODO: It#s possible to do all fetching here and passing the relevent information to the components.
                currentTab: 0,
                is_private: false,
            }
        },
        props: ['user', 'group_settings'],
        methods: {
            changeTab(value){
                // Reset the signature store to force retaking
                this.$store.dispatch('setSignature', '');
                this.$store.dispatch('setSigTaken', false);
                this.$store.dispatch('setSTC', '');
            },
            setCurrentTab(tabInt) {
            	console.log('Switching tab to ' + tabInt);
            	this.currentTab = tabInt;
            },
        },
        computed: {

        },
        mounted() {
            this.$store.dispatch('statics/fetchGroupMembers');
            //this.$store.dispatch('statics/fetchMRC');
            this.$store.dispatch('statics/fetchGroupSettings');
            this.$store.dispatch('statics/fetchStateIdentifiers');
            this.$store.dispatch('statics/fetchSTC');
            this.$store.dispatch('statics/fetchSTCTypes');
            this.$store.dispatch('statics/fetchVaccBatches');
        }
    }
</script>
<style scoped>
    #claim-box {
        width: 100%;
        margin-top: 30px;
    }
    @media only screen and (max-width: 800px) {
        #claim-box {
            width: 100%;
            margin-top: 1px;
        }
    }


    .claim-new {
        margin-top: 20px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .sigbox {
        height: 100vh;
        width: 100%;
        margin-bottom: auto;
    }

    .is-half {
        width: 50%;
    }

</style>
