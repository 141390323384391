<template>
<div id="big-box">
    <b-loading v-model="isLoading"></b-loading>

    <b-field>
        <b-button @click="showAddGroupModal()" class="is-info">Add Group</b-button>
    </b-field>

    <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Table">
            <b-table
                :data="groups"
                :columns="groupsColumns"
                :selected.sync="selected"
                :debounce-search="300"
                :paginated="true"
                :per-page="10"
                focusable>
            </b-table>
        </b-tab-item>
        <b-tab-item
            :label=selected.name>
            <template>
                <div v-if="selected.id === undefined">
                    <p class="has-text-centered"><b>Select a group from the table.</b></p>
                </div>

                <div v-else class="container">

                    <p class="has-text-centered"><b>Group ID: {{ selected.id }}</b></p>

                    <b-field grouped >
                        <b-field label="Group Name">
                            <b-input v-model="loaded_group.name"></b-input>
                        </b-field>

                        <b-field label="Cluster">
                            <b-select v-model="loaded_group.cluster_id" placeholder="None">
                                <option
                                    v-for="cluster in clusters"
                                    :key="cluster.id"
                                    :value="cluster.id">
                                    {{ cluster.name }}
                                </option>
                                <option :value="null">None</option>
                            </b-select>
                        </b-field>

                        <b-field label="Private">
                            <b-switch v-model="isGroupPrivate"
                                size="is-medium"
                            >
                                {{ this.isPrivate() }}
                            </b-switch>
                        </b-field>

                    </b-field>

                    <b-field grouped>
                        <b-field>
                            <b-button @click="confirmSaveGroupDetails()" type="is-primary">Save Group Details</b-button>
                        </b-field>
                    </b-field>

                    <hr>

                    <b-field label="Mapped Cert" grouped>

                        <b-message v-if="isCertMissing()" type="is-danger" has-icon>
                            No cert found. Upload a cert and fill in the details below.
                        </b-message>

                        <b-button v-else @click="confirmLoginTest()" type="is-warning">Test Cert Login</b-button>

                    </b-field>

                    <b-field grouped>

                        <b-field class="file is-primary" :class="{'has-name': !!file}">
                            <b-upload v-model="file"
                                      class="file-label"
                                      accept=".p12, .pfx"
                                      required
                                      validationMessage="Please select a file"
                            >
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">Click to upload new cert</span>
                                </span>

                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                            </b-upload>
                        </b-field>
                    </b-field>

                    <b-field grouped>

                        <b-field label="Cert ID">
                            <b-input v-model="mapped_cert.ext_id" pattern="^\d{8} \d{2}$"></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.ext_id)" type="is-primary">Copy</b-button>
                        </b-field>

                        <b-field label="Cert Password">
                            <b-input type="password" v-model="mapped_cert.cert_pass" password-reveal></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.cert_pass)" type="is-primary">Copy</b-button>
                        </b-field>

                    </b-field>

                    <b-field grouped>

                        <b-field label="Login Username">
                            <b-input v-model="mapped_cert.username"></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.username)" type="is-primary">Copy</b-button>
                        </b-field>

                        <b-field label="Login Password">
                            <b-input type="password" v-model="mapped_cert.password" password-reveal></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.password)" type="is-primary">Copy</b-button>
                        </b-field>

                        <b-field label="Password Expiry Date">
                            <b-datepicker
                                v-model="password_expiry_date"
                                placeholder="DD/MM/YYYY"
                                trap-focus>

                            </b-datepicker>

                            <b-button @click="copyToClipboard(mapped_cert.password_expiry_date)" type="is-primary">Copy</b-button>
                        </b-field>

                        <b-field v-if="isPasswordExpired()" label="PASSWORD APPEARS EXPIRED">
                            <b-button @click="confirmResetPasswordFlag()" type="is-danger">Reset Password Flag</b-button>
                        </b-field>

                    </b-field>

                    <b-field grouped>

                        <b-field label="Cold Chain Number">
                            <b-input v-model="mapped_cert.cold_chain_number"></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.cold_chain_number)" type="is-primary">Copy</b-button>
                        </b-field>

                        <b-field label="GMS">
                            <b-input v-model="mapped_cert.gms"></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.gms)" type="is-primary">Copy</b-button>
                        </b-field>

                    </b-field>

                    <b-field grouped>
                        <b-field label="Notes">
                            <b-input v-model="mapped_cert.notes" maxlength="5000" type="textarea"></b-input>
                            <b-button @click="copyToClipboard(mapped_cert.notes)" type="is-primary">Copy</b-button>
                        </b-field>
                    </b-field>

                    <b-field grouped>

                        <b-field>
                            <b-button @click="confirmSaveMappedCert()" type="is-primary">Save Cert Details</b-button>
                        </b-field>

                    </b-field>

                    <hr>

                    <b-field label="Member details" expanded>
                        <b-field>
                            <b-button @click="showAddMemberModal" type="is-primary">Add Member</b-button>
                        </b-field>
                    </b-field>

                    <b-field>

                        <b-table
                            :data="loaded_group.clients"
                            :debounce-search="300"
                            :paginated="true"
                            :per-page="10"
                            focusable>

                            <b-table-column field="id" label="ID" width="5%" numeric v-slot="props" searchable sortable>
                                {{ props.row.id }}
                            </b-table-column>

                            <b-table-column field="user.id" label="User ID" width="10%" numeric v-slot="props"
                                            searchable
                                            sortable
                            >
                                {{ props.row.user ? props.row.user.id : 'N/A' }}
                            </b-table-column>

                            <b-table-column field="user.name" label="Username" width="10%" v-slot="props"
                                            searchable
                            >
                                {{ props.row.user ? props.row.user.name : 'N/A' }}
                            </b-table-column>

                            <b-table-column field="prefix" label="Prefix" width="10%" v-slot="props"
                                            searchable
                                            sortable
                            >
                                {{ props.row.prefix }}
                            </b-table-column>

                            <b-table-column field="firstname" label="Member Name" width="25%" v-slot="props"
                                            searchable
                                            sortable
                            >
                                {{ props.row.firstname }}
                            </b-table-column>

                            <b-table-column field="lastname" label="Member Surname" width="25%" v-slot="props"
                                            searchable
                                            sortable
                            >
                                {{ props.row.lastname }}
                            </b-table-column>

                            <b-table-column field="actions" label="Actions" width="25%" v-slot="props">
                                <b-button @click="confirmRemoveClient(props.row.id)" type="is-danger">Remove</b-button>
                            </b-table-column>

                            <template #empty>
                                <div class="has-text-centered">No members</div>
                            </template>

                        </b-table>

                    </b-field>

                </div>

            </template>
        </b-tab-item>
    </b-tabs>

    <b-modal :active.sync="isNewGroupModalActive" close-button-aria-label="Close">
        <NewGroupModal v-on:save-new-group="saveNewGroup"></NewGroupModal>
    </b-modal>

    <!--
        add client modal
        show a list of all clients that are not already members of the group
    -->
    <b-modal :active.sync="isNewMemberModalActive" close-button-aria-label="Close">
        <NewMemberModal v-on:member-added="addMember" :group_id="this.loaded_group.id"></NewMemberModal>
    </b-modal>

</div>
</template>

<script>
import axios from "axios";
import NewMemberModal from "./modals/NewMemberModal.vue";

export default {
    name: "GroupManagement",
    components: {
        NewMemberModal
    },
    data() {
        return {
            selected: {name: "Group..."},
            groups: [],
            clusters: [],
            isLoading: false,
            loaded_group:{},
            mapped_cert: {},
            file: null,
            isNewGroupModalActive: false,
            isNewMemberModalActive: false,
            isGroupPrivate: false,
            password_expiry_date: null,
            groupsColumns: [
                {
                    field: 'id',
                    label: 'ID',
                    numeric: true,
                    searchable: true,
                    sortable: true,
                    width: '10%'
                },
                {
                    field: 'name',
                    label: 'Name',
                    searchable: true,
                    sortable: true,
                    width: '40%'
                },
                {
                    field: 'cluster.name',
                    label: 'Cluster',
                    searchable: true,
                    sortable: true,
                    width: '15%'
                },
                {
                    field: 'cert.ext_id',
                    label: 'Cert ID',
                    searchable: true,
                    sortable: true,
                    width: '30%'
                },
                {
                    field: 'clients_count',
                    label: 'Client Count',
                    searchable: false,
                    sortable: true,
                    width: '5%'
                }
            ],
        }
    },
    methods: {
        getGroups() {
            this.isLoading = true;
            axios.get('/admin/getGroups')
                .then(response => {
                    this.groups = Object.values(response.data);
                    this.isLoading = false;
                })
        },
        getClusters() {
            this.isLoading = true;
            axios.get('/getAllClusters', {params: {simple: 1}})
                .then(response => {
                    this.clusters = Object.values(response.data);
                    this.isLoading = false;
                })
        },
        loadGroup() {
            this.isLoading = true;
            axios.get('/admin/getGroup', {params: {id: this.selected.id} } )
                .then(response => {
                    this.loaded_group = response.data;
                    this.mapped_cert = this.loaded_group.cert?this.loaded_group.cert:{};
                    this.isGroupPrivate = this.loaded_group.settings?this.loaded_group.settings.medserv:false;
                    this.isCertMissing();
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                    this.mapped_cert = {};
                })
                .finally(() => {
                    this.isLoading = false;
                    this.file = null;
                })
        },
        confirmSaveGroupDetails(type=null) {
            this.$buefy.dialog.confirm({
                message: 'Overwrite group details?',
                onConfirm: () => this.saveGroupDetails(type)
            })
        },
        saveGroupDetails(type=null) {
            this.isLoading = true;
            let update_data = {
                id: this.loaded_group.id,
                name: this.loaded_group.name,
                cluster_id: this.loaded_group.cluster_id,
                medserv: this.isGroupPrivate,
            };
            axios.post('/admin/saveGroupDetails', update_data)
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    this.selected.cert_id = this.mapped_cert.ext_id;
                    this.selected.name = this.loaded_group.name
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        flashMessage(message, type, duration = 5000) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                duration: duration,
                position: 'is-bottom',
            })
        },
        confirmSaveMappedCert() {
            this.$buefy.dialog.confirm({
                message: 'Save cert details?',
                onConfirm: () => this.saveCertDetails()
            })
        },
        copyToClipboard(field) {
            // if field isn't empty
            if (!field) {
                this.flashMessage("Nothing to copy", "is-danger");
                return;
            }
            const textField = document.createElement('textarea');
            textField.innerText = field;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            this.flashMessage("Copied", "is-success");
        },
        saveCertDetails() {
            if(this.file && Object.keys(this.mapped_cert).length === 0) {
                if(!this.mapped_cert.ext_id || !this.mapped_cert.cert_pass || !this.mapped_cert.username || !this.mapped_cert.password) {
                    this.flashMessage("Please fill in all required fields", "is-danger");
                    return;
                }
            }

            this.isLoading = true;
            let formData = new FormData();
            formData.append('group_id', this.loaded_group.id);
            formData.append('ext_id', this.mapped_cert.ext_id);
            formData.append('cert_pass', this.mapped_cert.cert_pass);
            formData.append('username', this.mapped_cert.username);
            formData.append('password', this.mapped_cert.password);
            formData.append('cold_chain_number', this.mapped_cert.cold_chain_number);
            formData.append('gms', this.mapped_cert.gms);
            formData.append('notes', this.mapped_cert.notes);

            if(!this.password_expiry_date) {
                this.flashMessage("Please fill in the password expiry date", "is-danger");
                return;
            }

            let date = this.password_expiry_date;
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let formatted_date = year + '-' + month + '-' + day;

            formData.append('password_expiry_date', formatted_date);
            if (this.file) formData.append('file', this.file);

            axios.post('/admin/saveCertDetails', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.flashMessage(response.data.message, response.data.type);
                this.isCertMissing();
                this.mapped_cert.password_expired = 0;
            })
            .catch(error => {
                this.flashMessage(error.message, error.type);
            })
            .finally(() => {
                this.isLoading = false;
                this.isPasswordExpired();
            });
        },
        confirmResetPasswordFlag() {
            this.$buefy.dialog.confirm({
                message: 'Reset password flag? This DOES NOT save a new password. To save a new password you must Save Cert Details.',
                onConfirm: () => this.resetPassFlag()
            })
        },
        resetPassFlag() {
            this.isLoading = true;
            axios.post('/admin/resetPassFlag', {group_id: this.loaded_group.id})
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    this.mapped_cert.password_expired = 0;
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.isPasswordExpired();
                });
        },
        isCertMissing() {
            return Object.keys(this.mapped_cert).length === 0;
        },
        isPasswordExpired() {
            return this.mapped_cert.password_expired === 1;
        },
        showAddGroupModal() {
            this.isNewGroupModalActive = !this.isNewGroupModalActive;
        },
        showAddMemberModal() {
            this.isNewMemberModalActive = !this.isNewMemberModalActive;
        },
        saveNewGroup(groupName) {
            this.isLoading = true;
            axios.post('/admin/createGroup', {name: groupName})
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    //add new group to this.groups and select it
                    this.groups.push(response.data.group);
                    this.selected = response.data.group;
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isNewGroupModalActive = false;
                    this.newGroupName = '';
                });
        },
        confirmRemoveClient(id) {
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to remove this client from group?',
                onConfirm: () => this.removeClient(id)
            })
        },
        removeClient(id) {
            this.isLoading = true;
            axios.post('/removeGroupMember', {client_id: id, group_id: this.selected.id})
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    this.loaded_group.clients = this.loaded_group.clients.filter(client => client.id !== id);
                    this.selected.clients_count--;
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        addMember(member) {
            this.loaded_group.clients.push(member);
            this.selected.clients_count++;
        },
        isPrivate() {
            return this.isGroupPrivate?'Yes':'No';
        },
        confirmLoginTest() {
            this.$buefy.dialog.confirm({
                message: 'Are you sure you want to test cert login?',
                onConfirm: () => this.loginTest()
            })
        },
        loginTest() {
            this.isLoading = true;
            axios.post('/admin/loginTest', {cert_id: this.mapped_cert.id})
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
        //TODO: allow to download cert file from here
    },
    computed: {
    },
    watch: {
        selected: function() {
            this.isLoading = true;
            this.loadGroup();
        },
        mapped_cert: function () {
            this.password_expiry_date = this.mapped_cert.password_expiry_date ? new Date(this.mapped_cert.password_expiry_date) : new Date(this.password_expiry_date);
        }
    },
    mounted() {
        this.getGroups();
        this.getClusters();
    }
}
</script>

<style scoped>
 #big-box {
     width: 100%;
     margin-top: 30px;
     padding:5px;
     background: white;
     color: black;
 }
</style>
