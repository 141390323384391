<template>
    <div class="container">
        <div>
            <h1 class="title is-3" style="color: #8a28d7;">Claims Spread Graph</h1>
            <div class="button is-medium is-primary" @click="showGraph = !showGraph" :disabled="loaded === false">Toggle Graph</div>
            <TheGraph v-if="isGraphVisibe" ref="graph" :graph-data="this.graphData" width="80vw" height="60vh"></TheGraph>
            <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
        </div>
        <div>
            <div class="columns is-tablet">
                <b-field class="column is-4" label="Date Range">
                    <b-radio class="column" v-model="dateAllRadio" native-value="range">Select Range</b-radio>
                    <b-radio class="column" v-model="dateAllRadio" native-value="all">All time</b-radio>
                </b-field>
            </div>
            <div class="columns is-tablet">
                <b-field class='column is-2' label="Claim Date From:">
                    <b-input v-model="search_query.from" :disabled="dateAllRadio==='all'" type="date" name="from" id="from"/>
                </b-field>
                <b-field class='column is-2' label="Claim Date To:">
                    <b-input v-model="search_query.to" :disabled="dateAllRadio==='all'" type="date" name="to" id="to"/>
                </b-field>
                <div class='column'>
                    <b-field label="Search multiple claim numbers">
                        <b-input v-model="search_query.claims" placeholder="Enter claim numbers then spaces" icon="search" icon-pack="fas"/>
                    </b-field>
                </div>
                <div class='column is-4'>
                    <b-field label="Special Type Consultation">
                        <b-select placeholder="Select a name" v-model="search_query.stc" expanded>
                            <option
                            :value="0"
                            :key="0"></option>
                            <option
                                v-for="option in stc_list"
                                :value="option.id"
                                :key="option.id">
                                {{ option.symbol }} - {{ option.subtype }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns is-tablet">
                <div class="column">

                    <b-field label="Search by name or claim number"></b-field>
                    <b-field>
                        <b-input v-model="search_query.name" placeholder="Patient Name" type="search" icon="search" icon-pack="fas"/>
                    </b-field>
                </div>
                <div class='column is-6'>
                    <b-field label="Claim Status"></b-field>

                    <b-field position="is-centered">
                        <b-radio-button v-model="status_group"
                                        native-value="All"
                                        type="is-success">
                            All
                        </b-radio-button>

                        <b-radio-button v-model="status_group"
                                        native-value="Created"
                                        type="is-success">
                            Created
                        </b-radio-button>

                        <b-radio-button v-model="status_group"
                                        native-value="Progress"
                                        type="is-success">
                            In-Progress
                        </b-radio-button>

                        <b-radio-button v-model="status_group"
                                        native-value="Completed"
                                        type="is-success">
                            Completed
                        </b-radio-button>
                        <b-radio-button v-model="status_group"
                                        native-value="Custom"
                                        ref="customButton"
                                        type="is-success">
                            Custom filter
                        </b-radio-button>
                        <b-radio-button v-show="isCustomSearch"
                                        v-model="status_group"
                                        native-value="Clear"
                                        >
                            Clear
                        </b-radio-button>
                    </b-field>

                    <b-field expanded>
                        <b-dropdown
                            v-model="search_query.status_in"
                            multiple
                            aria-role="list"
                            v-show="isCustomSearch"
                            expanded>
                            <template #trigger>
                                <b-button
                                    :type="claimButtonStatusColor"
                                    expanded>
                                    <p v-if="search_query.status_in.length > 0">{{ activeStatusNames }} </p>
                                    <p v-else>Click here or use buttons below to filter...</p>
                                </b-button>
                            </template>
                            <b-dropdown-item
                                v-for="option in statuses"
                                :value="option"
                                :key="option.name"
                                @click="setStatusGroup('Custom')">
                                <span>{{ option.name }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-field>

                </div>
                <div class="column is-2">
                    <b-field>&nbsp;</b-field>
                    <b-field><div class="button is-medium is-primary" @click="refresh" :disabled="loaded === false">Filter</div></b-field>
                    <b-field>
                            <JsonExcel
                                class="button is-medium is-primary"
                                :data="excelExport"
                                :disabled="loaded === false"
                                worksheet="My Worksheet"
                                type="xls"
                                name="claimsExport.xls"
                            >
                                Download Excel
                            </JsonExcel>
                    </b-field>
                </div>
            </div>
			<div v-if="this.loaded === undefined || this.loaded === false" class="columns is-centered">
            	<div class="column is-narrow">
            		<div class="has-text-centered">
            			<div class="spring-spinner">
                        	<div class="spring-spinner-part top">
                            	<div class="spring-spinner-rotator"></div>
                            </div>
                            <div class="spring-spinner-part bottom">
                                <div class="spring-spinner-rotator"></div>
                            </div>
                        </div>
	            	</div>
	            </div>
	    	</div>
			<div v-if="this.loaded">
				<b-table :data="filter"
                         :paginated="true"
                         detailed
                         detailed-key="claimBaseID"
                         :per-page="perPage"
                         :current-page="currentPage"
                         default-sort="modifiedDateTime"
                         default-sort-direction="desc"
                         hoverable>
                    <b-table-column
                        field="claimNo"
                        label="Claim Number"
                        v-slot="props"
                    >
                        {{ props.row.claimNo === '' ? 'No Claim No.' : props.row.claimNo }}
                    </b-table-column>

                    <b-table-column
                        field="patientsName"
                        label="Name"
                        v-slot="props"
                    >
                        {{ props.row.patientsName === '' ? 'John Doe' : props.row.patientsName }}
                    </b-table-column>

                    <b-table-column
                        field="claimDate"
                        label="Claim Date"
                        centered
                        sortable
                        v-slot="props"
                    >
                        {{ props.row.claimDate }}
                    </b-table-column>

                    <b-table-column
                        field="modifiedDateTime"
                        label="Date Modified"
                        centered
                        sortable
                        v-slot="props"
                    >
                        {{ props.row.modifiedDateTime }}
                    </b-table-column>

                    <b-table-column
                        field="stcDesc"
                        v-slot="props"
                        label="STC"
                        centered
                    >
                        {{ props.row.stcDesc }}
                    </b-table-column>

                    <b-table-column
                        field="claimStatus"
                        label="Claim Status"
                        centered
                        v-slot="props"
                    >
                        {{ props.row.claimStatus }}
                    </b-table-column>

                    <b-table-column
                        field="signatureNumber"
                        label="Signature"
                        centered
                        v-slot="props"
                    >
                        <div v-if="props.row.signatureNumber === 'Not signed'">
                            <div class="button is-info" @click="openSigBox(props.row.claimBaseID)">
                                Take Signature
                            </div>
                        </div>
                        <div v-else-if="props.row.claimStatusNum == '13'">
                            {{ props.row.signatureNumber }}&nbsp;
                            <div class="button is-info" @click="openSigBox(props.row.claimBaseID)">
                                Update
                            </div>
                        </div>
                        <div v-else>
                            {{ props.row.signatureNumber }}&nbsp;
                        </div>
                    </b-table-column>
                    <b-table-column
                        field="printButton"
                        label="Print"
                        centered
                        v-slot="props"
                    >
                    <div>
                        <div class="button is-info margin-5" @click="showClaimPreview(props.row.claimBaseID)">
                            Preview
                        </div>
                    </div>
                    </b-table-column>

	                <template #detail="props">
	                    <div class="columns has-text-left">
	                        <div class="column col-align">
                                <div v-if="props.row.claimStatusNum === 5">
                                    <b-field horizontal label="Paid Date">
                                        <p>{{ props.row.paid_at }}</p>
                                    </b-field>
                                </div>
	                            <b-field horizontal label="Panel">
	                                <p>{{ props.row.panel ? 'Yes' : 'No'}}</p>
	                            </b-field>
	                            <b-field horizontal label="STC">
	                                <p>{{ props.row.stcDesc }}</p>
	                            </b-field>
                                <div v-if="props.row.claimTypeNum === 1">
                                    <b-field horizontal label="State">
                                        <p>{{ props.row.extraState }}</p>
                                    </b-field>
                                    <b-field horizontal label="EC Resident's DOB">
                                        <p>{{ props.row.extraDOB }}</p>
                                    </b-field>
                                    <b-field horizontal label="Prescription No">
                                        <p>{{ props.row.extra_prescription }}</p>
                                    </b-field>
                                    <b-field horizontal label="ID Card No">
                                        <p>{{ props.row.extra_id_card_no }}</p>
                                    </b-field>
                                    <b-field horizontal label="ID Card Expiry Date">
                                        <p>{{ props.row.extra_id_card_expiry }}</p>
                                    </b-field>
                                    <b-field horizontal label="Personal ID Card No">
                                        <p>{{ props.row.extra_personal_id }}</p>
                                    </b-field>
                                </div>
	                            <div v-if="props.row.vaccID">
	                                <b-field horizontal label="Vaccine Batch">
	                                    <p>{{ props.row.vaccBatch }}</p>
	                                </b-field>
	                                <b-field horizontal label="Vaccine Expiry">
	                                    <p>{{ props.row.vaccExp }}</p>
	                                </b-field>
	                                <b-field horizontal label="Vaccine Injection Site">
	                                    <p>{{ props.row.vaccInjSite }}</p>
	                                </b-field>
	                            </div>
                                <div v-if="props.row.claimStatusNum === 8">
                                    <b-field horizontal label="Rejected Reason">
                                        <p>{{ props.row.rejected_reason }}</p>
                                    </b-field>
                                </div>
	                        </div>
	                    </div>
	                </template>
                    <template #empty>
                        <p>No results found</p>
                    </template>
	            </b-table>
			</div>
			<b-modal id="claimPreview" :active.sync="claimPreview" full-screen>
				<div id="claimPreviewContainer" class="has-text-centered" @click="hideClaimPreview()">
					<iframe id="claimPreviewFrame" :src="claimPreviewURL"></iframe>
				</div>
			</b-modal>
			<div v-if="claimPreview" id="claimPreviewCloseButton">
                <div class="button is-info" @click="printClaim(claimToPrint)">
                    Print
                </div>
				<div class="button is-info" @click="hideClaimPreview()">
                    Close
                </div>
			</div>
            <b-modal :active.sync="openModal" full-screen scroll="keep">
                <div id="signatureForm">
                    <div id="sigScreenOrientationMessage" ref="sigScreenOrientationMessage">
                        <div class="columns is-centered">
                            <div class="column is-half has-text-centered">
                                <article class="message is-danger is-medium">
                                    <div class="message-header">
                                        <p>Turn Device</p>
                                    </div>
                                    <div class="message-body">
                                        This section is only viewable in landscape mode
                                    </div>
                                </article>
                                <p><img width="70%" src="/images/rotate.png"></p>
                            </div>
                        </div>
                    </div>
                    <div class="box sigbox" id="sigbox">
                        <VueSignaturePad class="sigarea" ref="signaturePad" width="100%" height="60vh" max-width="1280px" max-height="720px"/>
                        <button class="button is-success" @click="save">Save</button>
                        <button class="button is-warning" @click="undo">Undo</button>
                        <button class="button is-pulled-right is-danger" @click="closeModal">Close</button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import JsonExcel from "vue-json-excel";
    import {mapState} from "vuex";
    import TheGraph from "./TheGraph";

    let moment = require('moment');
    let axios = require('axios');
    export default {
        components: {
            JsonExcel, TheGraph
        },
        props: [],
        data() {
            return {
                // Signature Data
                signature: '',
                sig_taken: false,
                openModal: false,
                current_editing: null, // JSON index of the currently edited row
                current_claim_id: null, // claim ID of the currently edited row
                // Component data
                loaded: true,
                currentPage: 1,
                data: [],
                user: {}, // dummy
                stcs: {},

                //status grouping
                status_group: 'Progress',
                all_group: {},
                progress_group: {},
                created_group: {},
                completed_group: {},

                search_query: {
                    name: '',
                    from: '',
                    to: '',
                    stc: '',
                    status_in: []
                },
                excelExport: [],

                graphDataLoaded: false,
                showGraph: true,

                // Printing data
                claimPreview: false,
                claimPreviewURL: '',
                claimToPrint: '',
                dateAllRadio: 'range'
            };
        },
        methods: {
        	reloadRows() {
                let type = this.search_query.status_in.map(el => el.id);
                let from = this.search_query.from;
                let to = this.search_query.to;
                let claims = this.search_query.claims;
                let stc = this.search_query.stc;
                this.graphDataLoaded = false;
        		this.loaded = false;
                axios.get('/getClaims', {params: {type, from, to, claims, stc}})
                .then(response => {
                    this.excelExport = response.data.excel;
                    this.data = response.data.results;
                    this.user_info = response.data.userInfo;
                    this.loaded = true;
                })
                .catch(error => {
                    this.flashMessage('Error reading claims', 'is-danger')
                    this.loaded = true;
                });

                axios.get('/fetchGraphData', {params: {from, to}}).then(response => {
                    this.graphData = response.data
                    this.graphDataLoaded = true;
                }).catch(error => {
                    this.flashMessage('Error fetching claim spread graph data.', 'is-danger')
                    this.graphDataLoaded = true;
                });
        	},
            refresh(){
                this.reloadRows();
            },
            openSigBox(claimID){
              	this.openModal = true;
             	this.current_claim_id = claimID;
             	let current_index = null;
             	this.data.map(function (row, index) {
             		if (row.claimBaseID == claimID) {
             			current_index = index;
             		}
             	});
             	this.current_editing = current_index;
            },
            printClaim(claimID) {
            	window.open('/print/pdf?id=' + claimID, '_blank');
            },
            showClaimPreview(claimID) {
                this.claimToPrint = claimID
            	this.claimPreviewURL = "/print/pdf?htmlOnly&id=" + claimID;
            	this.claimPreview = true;
            },
            hideClaimPreview() {
                this.claimToPrint = ''
            	this.claimPreviewURL = "";
            	this.claimPreview = false;
            },
            showOptions: function () {
                this.showDropDown = true;
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            },
            save() {
                const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
                if(!isEmpty){
                    let claimID = this.current_claim_id;
                    axios.post('/saveSig', {'signature': data, 'claim_id' : claimID})
                        .then(res => {
                            this.data[this.current_editing].signatureNumber = 'Signed';
                            this.closeModal();
                            this.signature = data;
                            this.sig_taken = true;
                            this.flashMessage('Signature Recorded', 'is-primary')
                        })
                        .catch(err => {
                            this.flashMessage('Signature could not be recorded', 'is-danger')
                        })

                }
            },
            closeModal() {
                this.current_editing = null;
                this.current_claim_id = null;
                this.openModal = false;
            },
            flashMessage(message, type){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
            fetchDoctors(){
                // obsolete, see user_info
            },
            searchClaims(event){
                if (event.code === 'Space' || event.code === 'Enter') {
                    this.reloadRows();
                }
            },
            groupStatuses() {
                let group = function (arr, ids) {
                    return arr.filter(el => {
                        return ids.indexOf(el.id) !== -1
                    })
                };
                this.created_group = group(this.statuses, this.$claimStatusGroups.CREATED);
                this.progress_group = group(this.statuses, this.$claimStatusGroups.IN_PROGRESS);
                this.completed_group = group(this.statuses, this.$claimStatusGroups.COMPLETED);
                this.all_group = [...this.statuses];
            },
            setStatusGroup(group) {
                this.status_group = group;
                this.updateClaimStatusSearch();
            },
            updateClaimStatusSearch() {
                switch (this.status_group) {
                    case 'Created':
                        this.search_query.status_in = this.created_group;
                        break;
                    case 'Progress':
                        this.search_query.status_in = this.progress_group;
                        break;
                    case 'Completed':
                        this.search_query.status_in = this.completed_group;
                        break;

                    case 'Custom':
                        break;
                    case 'Clear':
                    case 'All':
                    default:
                        this.search_query.status_in = [];
                        break;
                }
            },
            firstDayOfTheMonth() {
                let date = new Date();
                return moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD");
            }

        },
        watch: {
            'statuses': function() {
                this.groupStatuses();
                this.setStatusGroup('Progress');
                this.reloadRows();
            },
            'status_group': function (newGroup, oldGroup) {
                this.setStatusGroup(newGroup);
            },
           /* 'search_query.from': function (newFrom, oldFrom) {
                this.reloadRows();
            },
            'search_query.to': function (newTo, oldTo) {
                this.reloadRows();
            },*/
            'search_query.claims': function () {
               document.addEventListener('keyup', this.searchClaims);
            },/*
            'graphData': function(d) {
                this.graphDataLoaded = true;
            }*/

            'dateAllRadio': function(newStatus) {
                if(newStatus === 'all') {
                    this.search_query.from = '';
                } else {
                    this.search_query.from = this.firstDayOfTheMonth();
                }
            }
        },
        computed: {
            ...mapState('statics', {
                stc_list: state => state.stc_list,
                statuses: state => state.statuses,
                //graphData: state => state.claims_graph_data
            }),
            filter: function () {
                let name_re = new RegExp(this.search_query.name, 'i');
                let data = [];
                for (let i=0; i<this.data.length; i++) {
                    if (this.data[i].patientsName.match(name_re) || this.data[i].claimNo.match(name_re)) {
                        data.push(this.data[i])
                    }
                }
                return data
            },
            perPage: function(){
              if(this.$mq === 'sm'){
                  return 2;
              } else if(this.$mq === 'md') {
                  return 6;
              } else {
                  return 10;
              }
            },
            isEditable: function(row){
                // TODO: This will need to be replacted by the created a time
                if(moment(row.modifiedDateTime).add(1, 'hour').isAfter(moment())){
                    // Less than an hour since it was created
                    return true;
                }

                if(row.sigDetails === ''){
                    return true;
                }

                return false;
            },
            isCustomSearch: function() {
                return this.status_group === 'Custom';
            },
            claimButtonStatusColor: function () {
                if(this.search_query.status_in.length > 0) return 'is-primary';
                else return ''
            },
            activeStatusNames: function() {
                return this.search_query.status_in.map(el => el.name).join(', ')
            },
            isGraphVisibe: function() {
                return this.graphDataLoaded && this.showGraph
            },
            isLoading: function() {
                return !this.graphDataLoaded;
            }
        },
        mounted() {
            this.search_query.from = this.firstDayOfTheMonth();
            this.$store.dispatch('statics/fetchSTC');
            this.$store.dispatch('statics/fetchStatuses');
            //this.$store.dispatch('statics/fetchClaimsGraphData');
        }
    };
</script>

<style scoped>
    .container {
        margin-top: 50px;
    }
    @media only screen and (max-width: 800px) {
        .container {
            margin-top: 3px;
        }
    }
    .button {
            margin: 1px;
    }
    .box {
        margin-top: 10px;
    }

    .is-horizontal >>> .field-label{
        text-align: left;
    }

    .sigbox {
        height: 100vh;
        margin-bottom: auto;
    }

    .sigarea {
        border: 1px solid darkgrey;
        margin-bottom: 10px;
        border-radius: 10px;
    }

	#claimPreview {
		/*void*/
		overflow: hidden;
		padding: 0;
		margin: 0;
	}

	#claimPreviewContainer {
		width: 100%;
		height: 297mm;
		padding: 0;
		margin: 0;
	}

    #claimPreviewFrame {
    	border-width: 1px;
	    border-style: solid;
	    border-color: grey;
    	width: 210mm;
    	height: 297mm;
    	padding: 0;
    	margin: 0;
    	overflow: hidden;
    }

    @media only screen and (max-width: 210mm) {
    	#claimPreviewContainer {
    		width: 210mm;
		}
    }

    #claimPreviewCloseButton {
    	z-index: 100;
	    position: fixed;
	    bottom: 0;
	    right: 0;
	    padding: 3vh 3vw;
	    margin: 1vh 1vw;
    }

    @media only screen and (orientation:portrait){
        #sigScreenOrientationMessage {
            display: block;
        }
        #sigbox {
            display: none;
        }
    }

    @media only screen and (orientation:landscape){
        #sigScreenOrientationMessage {
            display: none;
        }
        #sigbox {
            display: block;
        }
    }
</style>
