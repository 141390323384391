<template>
    <div id="main">
        <div v-if="show">
            <svg id="graph" width="80vw" height="60vh"></svg>
        </div>
        <div v-else>
            <p>No results found.</p>
        </div>
    </div>
</template>

<script>

import * as d3 from "d3";
import * as d3annotation from "d3-svg-annotation";

export default {
    name: "TheGraph",

    props: ['graphData'],
    data(){
        return {
            show: true
        }
    },

    methods: {

    },

    computed: {
    },
    mounted: function () {
        //get the data, filter and sort
        let dataset = this.graphData;
        let groups = dataset.groups.filter(a => a.count > 0);
        let indiv = dataset.indiv.filter(a => a.count > 0);

        this.show = groups.length !== 0;


        let width = 1120,
            height = 800,
            radius = Math.min(560, 500) / 2;

        const color = d3.scaleOrdinal(d3.schemeCategory10);
        //make an SVG
        const svg = d3.select("#graph")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        makeDonut(groups, radius-180, radius-100, 'inner', false);
        makeDonut(indiv, radius-90, radius-10, 'outer', true);

        function makeDonut(graphData, innerRadius, outerRadius, name='donut', showAnnotations = true) {

            let pie = d3.pie()
                .sort(null).value(function (d) {
                    return d.count;
                });

            let arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius);

            let g = svg.selectAll(`${name}`)
                .data(pie(graphData))
                .enter().append("g")
                .attr("class", `${name}`);


            g.append("path")
                .attr("d", arc)
                .style("fill", function (d) {
                    if(d.data.color)
                        return d.data.color;
                    else
                        return color(d.data.name);
                });
            /*
                .on("mouseover", handleMouseOver)
                .on("mouseout", handleMouseOut);*/

            //calculate annotations positions on the donut and apply there bigbrain time
            const total = graphData.reduce((a, b) => a + b.count, 0); // needed for crazy positioning, love it
            let startAngle = 0;
            let endAngle = 0;

            if(showAnnotations === true) {
                let annotations = graphData.map((a,i) => {
                    let deg = a.count * Math.PI*2 / total
                    let perc = Math.round((a.count*100/total + Number.EPSILON)*100) / 100;
                    startAngle = endAngle;
                    endAngle += deg;

                    let xpos = -Math.cos( ( (startAngle+endAngle)/2 + Math.PI/2) ) * outerRadius;
                    let ypos = -Math.sin( ( (startAngle+endAngle)/2 + Math.PI/2) ) * outerRadius;

                    a = Object.assign({}, a, {
                        note: {
                            title: `${a.name.toUpperCase()}`,
                            label: `Count: ${a.count} (${perc}%)`
                        },
                        x: xpos,
                        y: ypos,
                        dx: (outerRadius-innerRadius)/4 * Math.sign(xpos) * (i%3 +1),
                        dy: (outerRadius-innerRadius)/4 * Math.sign(ypos) * (i%3 +1),
                        color: a.color
                    });
                    return a;
                });

                const makeAnnotations = d3annotation.annotation().type(d3annotation.annotationCallout)
                    .annotations(annotations);

                g.append("g")
                    .attr("class", "annotation-group")
                    .call(makeAnnotations)
            }

            g.append("text")
                .style('font-weight', 'bold')
                .attr("x", 0)
                .attr("dy", ".35em")
                .attr("text-anchor", "middle")
                .attr("font-size", 10)
                .attr("transform", d => {
                    return `translate(${arc.centroid(d)})`;
                })
                .attr("dy", ".35em")
                .selectAll("tspan")
                .data(d => {
                    return `${d.data.name.toUpperCase()}`.split(/\n/);
                })
                .join("tspan")
                    .attr("x", 0)
                    .attr("y", (_, i) => `${i * 1.1}em`)
                    .attr("font-weight", (_, i) => i ? null : "bold")
                    .text(d => d);

            /**LEGEND**/

            const legend = d3.select('#main')
                .append("svg")
                .attr('width', '80vw')
                .attr('height', '30px')
                .attr('class', `${name}`);// <svg id="legend" width="80vw" height="60px"></svg>

            let tom = legend.append('g')
                .attr("class", `${name}`);

            let lg = tom.selectAll('g')
                .data(graphData).enter()
                .append('g')
                    .attr("class", `swatch`)
                    .attr('transform', (d, i) => (`translate(${i * 130}, 0)`));

            lg.append('rect')
                .attr('width', 20)
                .attr('height', 20)
                .attr('fill', (d) => {
                    if(d.color)
                        return d.color;
                    else
                        return color(d.name);
                });

            lg.append('text')
                .text((d, i) => d.name.toUpperCase())
                .attr('x', 25)
                .attr('y', 20)
                .attr('text-anchor', 'left')
                .attr('font-size', '12')

        }

        function handleMouseOver(d, i) {
            //d3.select(this).style("fill", '#8a28d7');
            /*d3.select(this.parentNode).selectAll("text").text((d) => d.data.name.toUpperCase() + " (" + d.data.count + ")");
            svg.append("path")
                .attr("d", d3.select(this).attr("d"))
                .attr("id", "arcSelection")
                .style("fill", "none")
                .style("stroke", "#8a28d7")
                .style("stroke-width", 5);*/
        }
        function handleMouseOut(d, i) {
            /*d3.select(this).style("fill", function (d) {
                return color(d.data.name);
            });*/
            //d3.select("#arcSelection").remove();
            //d3.select(this.parentNode).selectAll("text").text((d) => "");

        }

    }
}
</script>

<style scoped>

</style>
