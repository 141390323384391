<template>
    <div>
        <div v-if="this.user.is_private == true">
            <div class="box">
                <b-field label="Select an Insurer Form">
                    <b-select expanded @input="displayForm" v-model="selected_insurer" placeholder="Click to select an option">
                        <option
                            v-for="option in insurers"
                            :value="option.id"
                            :key="option.id">
                            {{ option.name }}
                        </option>
                    </b-select>
                </b-field>
            </div>
            <div v-if="show_claim && this.$store.state.private_claims.pendingClaim">
                <ClaimForm :user="this.user" :selected_insurer="this.selected_insurer"></ClaimForm>
            </div>
        </div>
        <div v-else>
            <div v-if="this.interested_clicked === false">
                <b-message  type="is-info" has-icon>
                    If you are interested in using GPServ for your Insured claims or would like us to help you register your
                    practice for Insured claims, please click on one of the buttons below and a member of our team will be
                    in touch.<br><br>
                    <b-button
                        label="I'm interested"
                        type="is-primary"
                        size="is-medium"
                        @click="interestedClient('interested')" />

                    <b-button
                        label="Help Me Register"
                        type="is-primary"
                        size="is-medium"
                        @click="interestedClient('help me register')" />
                </b-message>
            </div>
            <div v-else>
                <b-message  type="is-success" has-icon>
                    <b>Thank you!</b><br><br>A member of our team will be in touch to help!<br><br>
                </b-message>
            </div>

        </div>
    </div>
</template>

<script>
    import ClaimForm from "./ClaimForm";
    import axios from "axios";
    export default {
        components: {ClaimForm},
        props: ['user', 'claim'],
        data() {
            return {
                interested_clicked: false,
                selected_insurer: null,
                show_claim: false,
                insurers: [
                    {name: 'VHI', id: 1},
                    {name: 'Laya Healthcare', id: 2},
                    {name: 'Irish Life Health', id: 3},
                    {name: 'ESB', id: 4},
                    {name: 'Garda', id: 5},
                    {name: 'Poma', id: 6},
                    {name: 'No insurance', id: 7},
                    {name: 'Company', id: 8},
                    {name: 'Glo Health', id: 10},
                ]
            }
        },
        watch: {
            // whenever selected insurer changes
            selected_insurer: function (newVal, oldVal) {
            	this.$store.dispatch('setPrivateInsurer', newVal);
            }
        },
        computed: {
            // classNames: function () {
            //     return this.hasError ? 'otp-input error' : 'otp-input';
            // }
        },
        mounted() {
            if(this.claim) {
                this.selected_insurer = this.claim.insurer
                this.$store.dispatch('setPrivateInsurer', this.selected_insurer);
                this.displayForm();
            }
            // console.log('Component mounted.')
        },
        methods: {
            displayForm(){
                this.show_claim = false;

                axios.get('/privateClaim', {
                    params: {
                        insurer: this.selected_insurer
                    }
                })
                .then(response => {
                    // dispatch the claim to teh vuex store
                    let claim = {prefilled: response.data}
                    this.$store.dispatch('setPendingClaim', claim);
                    this.show_claim = true;
                })
                .catch(error => {
                });
            },

            //silly thing with these if statements, but requested an email even if client clicked 'maybe later' ....
            interestedClient(which) {
                axios.post('/interestedPrivate',
                    {client_id: this.user.id, which}
                ).then(response => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `Thank you, we will be in contact with you soon.`,
                        position: 'is-top',
                        type: 'is-success'
                    })
                    this.interested_clicked = true;
                }).catch(error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `Sorry, we've encountered an error. Please try again.`,
                        position: 'is-top',
                        type: 'is-danger'
                    })
                })

            },
        }
    }
</script>

<style lang="scss">

</style>
