require('./bootstrap');
import Vue from 'vue';
import Buefy from 'buefy'
import VueMq from 'vue-mq'
import VueRouter from 'vue-router'
import store from './store/index'
import VueScrollTo from 'vue-scrollto';
// Autoregistration of Vue Components
const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));


// Signature Pad Dependancy
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

// Adding Buefy for styling
Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultLocale: 'en-GB'
});

// Scroller
Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: -55,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})


// Router
Vue.use(VueRouter);

// Breakpoints for mobile viewing
Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        sm: 601,
        md: 1031,
        lg: Infinity,
    },
    defaultBreakpoint: 'md' // customize this for SSR
});

Vue.prototype.$claimStatusGroups = {
    "CREATED": [1,2,11],
    "IN_PROGRESS": [3,4,6,9,10,12,13,14,15,16],
    "COMPLETED": [5,7,8]
}

const app = new Vue({
    el: '#app',
    store,
});

// SPECIFIC USE
require('./loader');

require('./claims');

require('./patients');

require('./general');

require('./account');

require('./functions');

/*
 Add onclicks to all nav burgers for mobile
 */
// Global Mobile Burger Activation
document.addEventListener('DOMContentLoaded', () => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');
            });
        });
    }
});
