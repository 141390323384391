<template>
    <div class="box">
        <div class="field">
            <label class="label">New Client</label>
            <div class="control">
                <b-field label="Firstname">
                    <b-input v-model="firstname"></b-input>
                </b-field>
            </div>
            <div class="control">
                <b-field label="Lastname">
                <b-input v-model="lastname"></b-input>
            </b-field>
            </div>
        </div>
        <div class="field">
            <div class="control">
                <button class="button is-primary" @click="saveNewClient">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewClientModal",
    data() {
        return {
            firstname: '',
            lastname: '',
        }
    },
    methods: {
        saveNewClient() {
            this.$emit('save-new-client', this.firstname, this.lastname);
        }
    }
}

</script>

<style scoped>

</style>
