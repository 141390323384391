<template>
    <section>
        <div class="box">
            <b-menu>
                <b-menu-list label="Panel Listings">
                    <div v-for="item in plistings">
                        <b-menu-item :label="item[0]">
                            <div v-for="i in item[1]">
                                <a :href="i.url" target="_blank">{{ i.name }}</a>
                            </div>
                        </b-menu-item>
                    </div>
                </b-menu-list>
            </b-menu>
        </div>
    </section>
</template>

<script>
export default {
    name: "PanelListings",
    props: ["panellistings"],
    data() {
        return {
        }
    },
    computed: {
        plistings() {
            return Object.entries(this.panellistings).reverse();
        }
    },

}
</script>

<style scoped>

</style>
