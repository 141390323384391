<template>
    <div v-if="!runMe" class="">
    	<button class="button is-light" v-on:click="run(true)">Export</button>
    </div>
</template>
<script>
	import axios from 'axios';
	import moment from 'moment';
    export default {
        components: {
        	// void
        },
        props: {
        	runMe: {default: false, type: Boolean},
        	filenameString: {default: 'export', type: String},
        },
        data(){
            return {
                currentView: {},
                logging: false,
            }
        },
        methods: {
        	log(message) {
        		this.message = message;
    			if (this.logging) {
    				console.log(this.message);
    			}
        	},
			formatter(d) {
		    	return d.toDateString();
		    },
		    toProper(possibleString) {
		    	let newString = '';
		    	if (typeof possibleString === 'string' || possibleString instanceof String) {
		    		String.prototype.toProperCase = function(opt_lowerCaseTheRest) {
	    				return (opt_lowerCaseTheRest ? this.toLowerCase() : this)
	    					.replace(/(^|[\s\xA0])[^\s\xA0]/g, function(s){ return s.toUpperCase(); });
	    			};
		    		newString = possibleString.toProperCase(true);
		    	}
		    	return newString;
		    },
		    run(run = this.runMe) {
		    	//console.log(this.runMe);
		    	//console.log(this.filenameString);
		    	if (run) {
		    		this.getCurrentView();
		    		this.exportData();
		    	}
		    },
		    getCurrentView() {
		    	this.log('Getting current view');
		    	this.currentView = {};
		    	let i = 0;
		    	let j = 0;
		    	let k = 0;
		    	let l = 0;
		    	let tables = document.getElementsByTagName("table");
		    	//console.log(tables);
		    	this.log('Tables count: ' + tables.length);
		    	let cells = null;
		    	let key = '';
		    	let rowCount = 0;
		    	let cellCount = 0;
		    	let content = '';
		    	
		    	for (i = 0; i < tables.length; i++) {
		    		rowCount = tables[[i]].rows.length;
		    		this.log('Table ' + i + ', ' + rowCount + ' rows');
		    		for (j = 0; j < rowCount; j++) {
		    			cells = tables[[i]].rows.item(j).cells;
		    			cellCount = cells.length;
		    			this.log('Row ' + j + ', ' + cellCount + ' cells');
		    			for (k = 0; k < cellCount; k++) {
		    				
		    				if (tables[[i]].tHead.children[[k]] == undefined || k == 0) {
		    					if (tables[[i]].tHead.children[0] == undefined) {
		    						key = 'undefined keys';
		    					} else {
		    						if (tables[[i]].tHead.children[0].children[[k]] == undefined) {
		    							if (tables[[i]].tHead.children[[k]] == undefined) {
		    								key = 'undefined key';
		    							} else {
		    								key = tables[[i]].tHead.children[[k]];
		    							}
		    						} else {
		    							key = tables[[i]].tHead.children[0].children[[k]].innerHTML;
		    						}
		    					}
		    				} else {
		    					key = tables[[i]].tHead.children[[k]].innerHTML;
		    				}
		    				content = cells.item(k).innerHTML;
		    				content = content.replace(/(\r\n|\r|\n)/gm, " ");
		    				content = content.replace(/"/g, "");
		    				content = content.replace(/'/g, "");
		    				content = content.replace(/,/g, " ");
		    				this.log('Cell ' + k + ' header: ' + key + ', content: ' + content);
		    				if (this.currentView[[i]] == undefined) {
		    					this.currentView[[i]] = {};
		    				}
	    					if (this.currentView[[i]][[j]] == undefined) {
	    						this.currentView[[i]][[j]] = {};
	    					}
	    					this.currentView[[i]][[j]][[key]] = content;
		    			}
		    		}
		    	}
		    },
		    exportData() {
		    	let i = 0;
		    	let csvContent = "data:text/csv;charset=utf-8,";
						    	
		    	for (let [tableKey, csvTable] of Object.entries(this.currentView)) {
		    		i = 0;
		    		for (let [rowKey, csvRow] of Object.entries(csvTable)) {
		    			if (csvRow != undefined) {
		    				if (i == 0) {
			    				csvContent += '"' + Object.keys(csvRow).join('","') + '"';
			    				csvContent += "\n";
			    			}
			    			//csvContent += Object.entries(csvRow).join(",");
			    			csvContent += '"' + Object.values(csvRow).join('","') + '"';
			    			csvContent += "\n";
			    			i++;
		    			}
		    		}
		    		csvContent += "\n";
		    		csvContent = csvContent.replace(/(^\[)|(\]$)/gm, "");
		    	}
		    	this.log(csvContent);
		        const data = encodeURI(csvContent);
		        const link = document.createElement("a");
		        link.setAttribute("href", data);
		        link.setAttribute("download", this.filenameString + ".csv");
		        link.click();
		    },
        },
        watch: {
        	'trigger' : function(newVal, oldVal) {
        		this.log('trigger value: ' + oldVal + ' to ' + newVal);
        	},
        },
        computed: {
        	computedFunction() {
        		let result = true;
                return result;
            },
        },
        mounted() {
        	this.run();
        },
        beforeDestroy() {
        	// void
        },
    }
</script>
<style>
		
	.hidden {
		display: none;
	}

</style>
