<template>
        <div id="claims-select" class="has-text-centered toast-container box your-claims">
            <b-tabs type="is-toggle" v-model="currentTab" expanded destroy-on-hide @change="changeTab">
                <b-tab-item label="GMS/PPS Claims" @settab="setCurrentTab($event)">
                    <hr>
                    <ClaimsTable ref="claims"></ClaimsTable>
                </b-tab-item>
                <b-tab-item label="Insured Claims" @settab="setCurrentTab($event)">
                    <hr>
                    <PrivateClaimsTable ref="pclaims"></PrivateClaimsTable>
                </b-tab-item>
            </b-tabs>
        </div>
</template>

<script>

import ClaimsTable from "./ClaimsTable";
import PrivateClaimsTable from "./PrivateClaimsTable";

export default {
    components: {ClaimsTable, PrivateClaimsTable},
    name: "YourClaims",
    data(){
        return {
            currentTab: 0,
        }
    },
    methods: {
        changeTab(value){
        },
        setCurrentTab(tabInt) {
            this.currentTab = tabInt;
        },
    },
}
</script>

<style scoped>
.your-claims {
    margin-top: 20px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>
