<template>
    <section class="box" id="claim-form">
        <b-steps :animated="false" has-navigation size="is-large" v-model="activeStep" :type="$store.getters.theme">
            <b-step-item label="Claim Form" step="1">
                <hr>
<!--                <h2 v-if="this.$store.state.private_claims.pendingClaim.client_name" class="subtitle has-text-grey-dark">{{this.$store.state.private_claims.pendingClaim.client_name}}</h2>-->
<!--                <h1 class="title has-text-grey-dark">{{this.$store.state.private_claims.pendingClaim.insurer.name}} Claim Form</h1>-->
                <div class="columns is-centered">
                    <div class="column has-text-left">
<!--                        <b-message type="is-info">-->
<!--                            If you're having any problems with your claim feel free to <router-link to="/contact">contact us here</router-link>-->
<!--                        </b-message>-->
                        <b-message type="is-warning" v-if="this.$store.state.private_claims.pendingClaim.submitted">
                            <strong>This claim has already been submitted.</strong> <br>
                            You may edit fields until it is sent to the insurer, at which point you will no longer be
                            able to navigate to this page
                        </b-message>
                        <!--CLAIM FORM-->
                        <h1 class="subtitle is-1 has-text-weight-bold has-text-grey-dark">Procedure Details</h1>
                        <ProcedureCodeSection name='procedure' :user="this.user" ref="ProcedureCodeSection"></ProcedureCodeSection>
                        
                        <div class="label">Claim Date:</div>
                        <b-datepicker
                            :value="claimDate"
                            v-model="claimDate"
                            icon="calendar"
                            placeholder="Click to select..."
                            trap-focus
                            editable
                        >
                        </b-datepicker>
                        <hr>
                        <div v-show="this.selected_insurer === 5">
                            <Garda ref="Garda"></Garda>
                        </div>
                        <h1 class="subtitle is-1 has-text-weight-bold has-text-grey-dark">Policy Details</h1>
                        <ClaimFormSection name="policy" :user="this.user"></ClaimFormSection>
                        <hr>
                        <h1 class="subtitle is-1 has-text-weight-bold has-text-grey-dark">History of Illness</h1>
                        <ClaimFormSection name="history"></ClaimFormSection>
                        <hr>
                        <h1 class="subtitle is-1 has-text-weight-bold has-text-grey-dark">Injury Details</h1>
                        <ClaimFormSection name="injury"></ClaimFormSection>
                        <hr>
                    </div>
                </div>
            </b-step-item>
            <b-step-item label="Agreement" step="2">
                <div class="has-text-left">
                    <span v-html="this.agreement[0].value"></span>
                    <hr>
                    <div v-if="this.$store.getters.isInjury">
                        <span v-html="this.agreement[1].value"></span>
                        <hr>
                    </div>
                    <div class="field has-text-centered">
                        <b-checkbox :type="$store.getters.theme" size="is-large" v-model="agreement_confirm">I Agree</b-checkbox>
                    </div>
                </div>
            </b-step-item>
            <b-step-item clickable style="padding-right: 0px; padding-left: 0px; margin-left: -15px; margin-right: -15px;" id="#signature-step" label="Sign" step="3">
                <hr>
                <!--                    Signature Buttons -->
                <div class="columns">
                    <SignatureOptions :allow-sigs="allowSigs" :sig-error-message="sigErrorMessage"
                                    :user="this.user" :stc_text="this.stc" v-on:onSubmit="sigTaken">
                    </SignatureOptions>

                </div>
            </b-step-item>
            <template
                slot="navigation"
                slot-scope="{previous, next}">
                <b-button
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                    icon-left="chevron-left"
                    icon-pack="fas"
                    outlined
                    type="is-danger">
                    Previous
                </b-button>
                <b-button
                    :disabled="next.disabled"
                    @click="validateStep(next)"
                    icon-pack="fas"
                    icon-right="chevron-right"
                    outlined
                    type="is-success"
                    v-if="!next.disabled">
                    Next
                </b-button>
                <b-button
                    @click="validateStep(next)"
                    icon-pack="fas"
                    outlined
                    type="is-success"
                    v-else>
                    Submit
                </b-button>
            </template>
        </b-steps>
    </section>
</template>

<script>
    import ClaimFormSection from "./ClaimFormSection";
    import ProcedureCodeSection from "./ProcedureCodeSection";
    import axios from "axios";
    import FullScreenSignature from "../modals/FullScreenSignature";
    import Garda from "./Garda";

    export default {
        components: {
            ClaimFormSection,
            ProcedureCodeSection,
            FullScreenSignature,
            Garda
        },
        props: ['user', 'selected_insurer'],
        data() {
            return {
                // Loading
                loading: true,

                // Form step driver
                activeStep: 0,

                // TODO: Remove this, use store directly
                agreement: this.$store.state.private_claims.pendingClaim.prefilled[1],
                agreement_confirm: false,
                form: {},

                // Error Handling
                hasError: false,
                errorMessage: '',
                sigErrorMessage: 'Must enter STC to sign',
                stc: 'Private Claim',

                claimDate: new Date(),
            }
        },
        computed: {
            isInjury() {
                for (let i = 0; i < this.$store.state.private_claims.pendingClaim.prefilled[0].length; i++) {
                    let entry = this.$store.state.private_claims.pendingClaim.prefilled[0][i];
                    if (entry.key === 'injury' && typeof entry.value !== 'undefined' && entry.value !== null && entry.value !== '' && entry.value !== false) {
                        return true;
                    }
                }
                return false;
            },
            allowSigs() {
                let allow = true;
                return !allow;
            },
        },
        watch: {
            claimDate: function (newValue, oldValue) {
                this.storeClaimDate()
            }
        },
        mounted: function() {
            this.storeClaimDate()
        },
        methods: {
            openFullScreenSig(){
                this.$buefy.modal.open({
                    events: {signatureTaken: this.fullScreenSigResponse},
                    component: FullScreenSignature,
                    trapFocus: true,
                    fullScreen: true,
                });
            },
            fullScreenSigResponse(sig){
                this.$store.dispatch('attachSignature', sig)
                    .then(() => {
                        this.$buefy.toast.open({
                            duration: 10000,
                            message: `Claim Submitted`,
                            position: 'is-top',
                            type: 'is-success'
                        })
                        this.submitForm();
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `Could not submit the claim`,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    })

            },
            updateField(event, key) {
                let value = null;
                if (typeof event === 'object' && event !== null) {
                    value = event.target.value;
                } else {
                    value = event;
                }
                this.$store.dispatch('updatePendingClaim', {key, value});
            },
            validateStep(nextStep) {
                // Check all required fields have values
                // console.log(this.activeStep);

                if (this.activeStep === 0) {
                    // Claim Form
                    let validity = this.$store.getters.claimValidity;
                    if (validity.valid) {
                        this.$scrollTo('#claim-form');
                        nextStep.action();
                    } else {
                        // Scroll to the first invalid key
                        this.$scrollTo('#' + validity.invalidKeys[0].key)
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `Please enter all required fields`,
                            position: 'is-top',
                            type: 'is-danger'
                        })
                    }
                } else if (this.activeStep === 1) {
                    // Agreement
                    if (!this.agreement_confirm) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `Please agree to the terms on this page`,
                            position: 'is-top',
                            type: 'is-danger'
                        })
                    } else {
                        this.$scrollTo('#claim-form');
                        nextStep.action();
                        /*
                            Vue signature pad has an issues being put into the stepper form
                            Some info here: https://github.com/neighborhood999/vue-signature-pad/issues/62
                            On rendering it, we need to call the resize method manually to set the
                            canvas size properly
                         */
                        /*this.$nextTick(() => {
                            this.$refs.signaturePad.resizeCanvas()
                        })*/
                    }
                } else if (this.activeStep === 2) {
                    // Signature
                    const data = this.$store.state.signature;
                    if (!!data === false) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `No signature detected`,
                            position: 'is-top',
                            type: 'is-danger'
                        })
                    } else {
                        //attach sig and submit
                        this.$store.dispatch('attachSignature', data)
                            .then(() => {
                                this.submitForm();
                            })
                            .catch(() => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: `Claim could not be submitted`,
                                    position: 'is-bottom',
                                    type: 'is-danger'
                                })
                            })
                    }
                }
            },
            submitForm() {
                let sub_doc = this.$refs.ProcedureCodeSection.doctor_selection ?? this.user.id
                let doccost = '';

                if(this.$refs.ProcedureCodeSection.freetext === true) {
                    let procedure = {code: 'EX', docdescrip: this.$refs.ProcedureCodeSection.freetext_value };
                    this.$store.dispatch('setProcedure', procedure)
                    if(!isNaN(this.$refs.ProcedureCodeSection.freetext_cost))
                        doccost = parseFloat(this.$refs.ProcedureCodeSection.freetext_cost).toFixed(2)
                }

                axios.post('/insertPrivateClaim', {
                    data: this.$store.state.private_claims.pendingClaim,
                    insurer: this.$store.state.private_insurer,
                    code: this.$store.state.select_procedure_obj.code,
                    docdescrip: this.$store.state.select_procedure_obj.docdescrip,
                    doccost: doccost,
                    submitting_doc: sub_doc,
                    garda_extra: this.$refs.Garda.garda_extra,
                    claim_type: 1,
                    claim_date: this.$store.state.claim_date,
                }).then(res => {
                    if(res.data.created !== true)
                        throw res.data.message
                    // Display success redirect?
                    this.$buefy.toast.open({
	                    duration: 5000,
	                    message: `Private claim received`,
	                    position: 'is-bottom',
	                    type: 'is-success'
                    });
                    this.resetForm();
                }).catch(err => {
                	this.$buefy.toast.open({
	                    duration: 5000,
	                    message: `Claim could not be saved`,
	                    position: 'is-bottom',
	                    type: 'is-danger'
	                });
                    console.log(err)
                });
            },
            resetForm() {
                this.$store.dispatch('setProcedure', {code: '', description: ''})
                this.$store.dispatch('setProcedureText', '')
                this.$store.dispatch('setSignature', '');
                this.$store.dispatch('setSigTaken', false);
                this.$store.dispatch('setPendingClaim', null);
                this.$store.dispatch('setPrivateInsurer', 0);
                //this.$store.dispatch('setPrivateClaimDoctor', null);
                this.$parent.selected_insurer = null;
                this.$refs.ProcedureCodeSection.doctor_selection = null;
                this.$refs.Garda.member_type = null;
                this.$refs.Garda.superintendents_email = '';
            },
            sigTaken(sig){
                this.awaiting_sig = false;
                this.$store.dispatch('setSigTaken', true)
            },
            storeClaimDate(){
                this.$store.dispatch('setClaimDate', this.claimDate)
            }
        },
    }
</script>

<style lang="scss" scoped>
    /*@media (max-width: 510px) {*/
    /*    #claim-form {*/
    /*        margin: 0;*/
    /*        width: 100%;*/
    /*    }*/
    /*}*/
    .sigbox {
        -moz-box-shadow: inset 0 0 10px #808080;
        -webkit-box-shadow: inset 0 0 10px #808080;
        box-shadow: inset 0 0 10px #808080;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 600px) {
        #signature-step{
            padding-left: 0px;
            padding-right: 0px;
            margin-left: -15px;
            margin-right: -15px;
        }
        /*#claim-form{*/
        /*    padding-left: 5px;*/
        /*    padding-right: 5px;*/
        /*}*/
    }
</style>
