
// viewClaims logic
window.showClaimSignature = function showClaimSignature (base64) {
    var container = document.getElementById('bigSignatureImageContainer');
    container.innerHTML = '<img id="bigSignatureImage" src="' + base64 + '" class="sigImage" width="50%"></img>';
    container.style.display = 'block';
};

window.hideClaimSignature = function hideClaimSignature () {
    var container = document.getElementById('bigSignatureImageContainer');
    container.innerHTML = '';
    container.style.display = 'none';
};

window.displayClaimFormSection = function displayClaimFormSection(sectionNumber) {
    hideClaimFormElements();
    var i;
	var elements = document.getElementsByClassName('newClaimFormPart' + sectionNumber);
	for (i=0;i<elements.length;i++) {
		elements[i].style.display = 'block';
	}
};

function hideClaimFormElements(numberOfSections = 4) {
	var i;
	var j;
	var elements = [];
	for (i=1;i<=numberOfSections;i++) {
		elements[i] = document.getElementsByClassName('newClaimFormPart' + i);
		for (j=0;j<elements[i].length;j++) {
			elements[i][j].style.display = 'none';
		}
	}
}

window.showClaimsDetails = function showClaimsDetails() {
	var i;
	var elements = document.getElementsByClassName('claimsTableHiddenColumn');
	for (i=0;i<elements.length;i++) {
		elements[i].style.display = 'table-cell';
	}
	document.getElementById('claimsTableExtendButton').style.display = 'none';
	document.getElementById('claimsTableHideButton').style.display = 'inline-block';
}

window.hideClaimsDetails = function hideClaimsDetails() {
	var i;
	var elements = document.getElementsByClassName('claimsTableHiddenColumn');
	for (i=0;i<elements.length;i++) {
		elements[i].style.display = 'none';
	}
	document.getElementById('claimsTableExtendButton').style.display = 'inline-block';
	document.getElementById('claimsTableHideButton').style.display = 'none';
}

window.preValidateClaimForm = function preValidateClaimForm() {

	// PRE-VALIDATION (validation before submitting)

	if (!confirm("Are you sure?")) {
		return false;
	}

	// Error section
	var errSection = document.getElementById('customClaimFormValidation');

	var errMessages = [];
	var errText = '';
	var i;

	// Input fields
	var typeInput      = document.getElementById('formTypeSelect');
	var subtypeInput   = document.getElementById('formSubtypeSelect');
	var visitInput     = document.getElementById('formVisitSelect');
	var patientInput   = document.getElementById('formPatientSelect');
	var serviceInput   = document.getElementById('formServiceSelect');
	var stcInput       = document.getElementById('formSTCSelect');
	var insuranceInput = document.getElementById('formInsuranceSelect');
	var dateInput      = document.getElementById('formDateField');
	var gmsInput       = document.getElementById('formGMSText');
	var locationInput  = document.getElementById('formLocationText');
	var distanceInput  = document.getElementById('formDistanceText');
	var signatureInput = document.getElementById('capturedSignature');
	var signatureField = document.getElementById('newClaimSignatureButton');

	// Fields colors reset
	signatureField.style.borderStyle = '';
	signatureField.style.borderColor = '';
	typeInput.style.borderStyle = '';
	typeInput.style.borderColor = '';
	subtypeInput.style.borderStyle = '';
	subtypeInput.style.borderColor = '';
	patientInput.style.borderStyle = '';
	patientInput.style.borderColor = '';
	dateInput.style.borderStyle = '';
	dateInput.style.borderColor = '';

	// Signature validation
	if (signatureInput.value == null || signatureInput.value == '') {
		errMessages.push("Please remember to accept the signature");
		signatureField.style.borderStyle = 'solid';
		signatureField.style.borderColor = 'red';
	}

	// Type validation
	if (!(typeInput.value>0)) {
		errMessages.push("Please choose a correct claim type");
		typeInput.style.borderStyle = 'solid';
		typeInput.style.borderColor = 'red';
	}

	// Subtype validation
	if (!(subtypeInput.value>0)) {
		errMessages.push("Please choose a correct claim subtype");
		subtypeInput.style.borderStyle = 'solid';
		subtypeInput.style.borderColor = 'red';
	}

	// Patient validation
	if (!(patientInput.value>0)) {
		errMessages.push("Please choose a patient from the list");
		patientInput.style.borderStyle = 'solid';
		patientInput.style.borderColor = 'red';
	}

	// Date validation
	if (dateInput.value == '' || dateInput.value == null) {
		errMessages.push("Please choose a correct date");
		dateInput.style.borderStyle = 'solid';
		dateInput.style.borderColor = 'red';
	}

	// Summary
	if (errMessages.length > 0) {
		for (i=0;i<errMessages.length;i++) {
			errText = errText + errMessages[i] + '<br>';
		}
		errSection.innerHTML = errText;
		return false;
	}

}

window.newClaimSignatureShow = function newClaimSignatureShow() {
	sigComponent = document.getElementById('newClaimSignatureField');
	sigComponent.style.display = 'block';
}

window.showNewClaimDetails = function showNewClaimDetails() {
	document.getElementById('newClaimFormDetailsButtonContainer').style.display = 'none';
	document.getElementById('newClaimFormDetails').style.display = 'block';
}

window.hideNewClaimDetails = function hideNewClaimDetails() {
	document.getElementById('newClaimFormDetails').style.display = 'none';
	document.getElementById('newClaimFormDetailsButtonContainer').style.display = 'block';
}

window.toggleClaimHistory = function toggleClaimHistory(tableId) {
	if (document.getElementById(tableId).style.display == "block") {
		document.getElementById(tableId).style.display = "none";
	} else {
		document.getElementById(tableId).style.display = "block";
	}
	
}