<template>
    <div class="has-text-centered" id="fullscreensig">
        <VueSignaturePad class="sigbox" height="60vh" max-width="1280px" max-height="720px"
                         ref="fullScreenSigPad"/>
        <button @click="undo" class="button is-pulled-left is-warning">Undo</button>
        <button @click="save" class="button is-success">Submit</button>
        <button @click="close" class="button is-pulled-right is-danger">Close</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {
            // classNames: function () {
            //     return this.hasError ? 'otp-input error' : 'otp-input';
            // }
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.fullScreenSigPad.resizeCanvas()
            })
        },
        methods: {
            save(){
                const {isEmpty, data} = this.$refs.fullScreenSigPad.saveSignature();
                if (isEmpty) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `No signature detected`,
                        position: 'is-top',
                        type: 'is-danger'
                    })
                } else {
                    this.$emit('signatureTaken', data)
                    this.$parent.close();
                }
            },
            close(){
                this.$parent.close();
            },
            undo() {
                this.$refs.fullScreenSigPad.undoSignature();
            },
        },
    }
</script>

<style lang="scss">
    .sigbox {
        -moz-box-shadow: inset 0 0 10px #808080;
        -webkit-box-shadow: inset 0 0 10px #808080;
        box-shadow: inset 0 0 10px #808080;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    #fullscreensig{
        padding: 10px;
    }
</style>
