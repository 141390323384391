<template>
<div id="big-box">
    <b-loading v-model="isLoading"></b-loading>
    <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Table">
            <b-table
                :data="users"
                :columns="columns"
                :selected.sync="selected"
                :debounce-search="300"
                :paginated="true"
                :per-page="10"
                focusable>
            </b-table>
        </b-tab-item>
        <b-tab-item
            :label=selected.name>
            <template>
                <div v-if="selected.id === undefined">
                    <p class="has-text-centered"><b>Select a user from the table.</b></p>
                </div>

                <div v-else class="container">

                    <p class="has-text-centered"><b>User ID: {{ selected.id }}</b></p>

                    <b-field grouped >
                        <b-field label="Name">
                            <b-input v-model="loaded_user.name"></b-input>
                        </b-field>

                        <b-field label="Login">
                            <b-input v-model="loaded_user.loginname"></b-input>
                        </b-field>

                        <b-field label="Email">
                            <b-input type="email" v-model="loaded_user.email"></b-input>
                        </b-field>

                        <b-field label="Save">
                            <b-button @click="confirmSaveUserDetails()" type="is-primary">Save</b-button>
                        </b-field>
                    </b-field>

                    <hr>

                    <b-field grouped label="New User Password">
                        <b-field label="New Password">
                            <b-input type="password" v-model="newUserPassword"></b-input>
                        </b-field>

                        <b-field label="Save Password">
                            <b-button @click="changeUserPassword()" type="is-primary" :disabled="isSavePasswordDisabled">Save New Password</b-button>
                        </b-field>
                    </b-field>

                    <hr>

                    <b-field label="Mapped Client" grouped>
                        <b-field label="Client ID">
                            <b-input v-model="mapped_client"></b-input>
                        </b-field>
                        <b-field label="Map Client to User">
                            <b-button @click="confirmSaveUserDetails('map')" type="is-primary" :disabled="clientChanged">Map Client</b-button>
                        </b-field>
                    </b-field>

                    <hr>

                    <b-field label="Client details" grouped v-if="loaded_user.client">

                        <b-field label="Client Group">
                            <b-input :value="loaded_user.client.group.name" readonly></b-input>
                        </b-field>

                        <b-field label="Client Name">
                            <b-input type="email" :value="userClientName" readonly></b-input>
                        </b-field>
                    </b-field>

                </div>

            </template>
        </b-tab-item>
    </b-tabs>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "UserManagement",
    data() {
        return {
            selected: {name: "User..."},
            users: [],
            isLoading: false,
            loaded_user:{},
            newUserPassword: '',
            mapped_client:null,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    numeric: true,
                    searchable: true,
                    sortable: true,
                    width: '10%'
                },
                {
                    field: 'name',
                    label: 'Name',
                    searchable: true,
                    sortable: true,
                },
                {
                    field: 'email',
                    label: 'Email',
                    searchable: true,
                },
                {
                    field: 'client_id',
                    label: 'Client ID',
                    numeric: true,
                    searchable: true,
                    sortable: true,
                    width: '10%'
                },
                {
                    field: 'client.client_name',
                    label: 'Client Name',
                    searchable: true,
                    sortable: true,
                }
            ]
        }
    },
    methods: {
        getUsers() {
            this.isLoading = true;
            axios.get('/admin/getUsers')
                .then(response => {
                    this.users = Object.values(response.data);
                    this.isLoading = false;
                })
        },
        loadUser() {
            this.isLoading = true;
            axios.get('/admin/getUser', {params: {id: this.selected.id} } )
                .then(response => {
                    this.loaded_user = response.data;
                    this.mapped_client = this.loaded_user.client.id;
                    this.isLoading = false;
                })
        },
        editClient() {
            window.open("/admin/client?id="+this.loaded_user.client.id, "_blank");
        },
        changeUserPassword() {
            this.isLoading = true;
            axios.post('/admin/updateUserPassword', { id:this.loaded_user.id, password: this.newUserPassword})
                .then(result => {
                    this.flashMessage(result.data.message, result.data.type);
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);

                })
                .finally(() => {
                    this.newUserPassword = '';
                    this.isLoading = false;
                });
        },
        confirmSaveUserDetails(type=null) {
            this.$buefy.dialog.confirm({
                message: 'Overwrite user details?',
                onConfirm: () => this.saveUserDetails(type)
            })
        },
        saveUserDetails(type=null) {
            this.isLoading = true;
            let update_data = {};
            if(type === 'map') {
                update_data = { id:this.loaded_user.id, client_id: this.mapped_client }
            } else {
                update_data = {
                    id: this.loaded_user.id,
                    name: this.loaded_user.name,
                    loginname: this.loaded_user.loginname,
                    email: this.loaded_user.email
                }
            }
            axios.post('/admin/saveUserDetails', update_data)
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    this.selected.client_id = this.mapped_client
                    this.selected.name = this.loaded_user.name
                    this.selected.email = this.loaded_user.email
                    this.loadUser();
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                    this.isLoading = false;
                });
        },
        flashMessage(message, type, duration = 5000) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                duration: duration,
                position: 'is-bottom',
            })
        },
    },
    computed: {
        userClientName() {
          return this.loaded_user.client.prefix??''
              + " " + this.loaded_user.client.firstname??''
              + " " + this.loaded_user.client.lastname??'';
        },
        isSavePasswordDisabled() {
            return this.newUserPassword.length < 8;
        },
        clientChanged() {
            return this.mapped_client === this.selected.client_id;
        }
    },
    watch: {
        selected: function() {
            this.isLoading = true;
            this.loadUser();
        },
    },
    mounted() {
        this.getUsers();
    }
}
</script>

<style scoped>
 #big-box {
     width: 100%;
     margin-top: 30px;
     padding:5px;
     background: white;
     color: black;
 }
</style>
