window.resetCustomNotification = function resetCustomNotification (value) {
	value = newLinesToSpacesNoMatterWhat(value);
    $('#formNotificationText').val(value);
};

window.preValidateAccountForm = function preValidateAccountForm () {
    return true;
};

/*$.each($('#newAccountForm').serializeArray(), function(i, field) {
	//alert(field.value);
});

$('#newAccountForm :input').on('keyup change paste','input',function(){
	$(this).attr('value', $(this).val());
});*/