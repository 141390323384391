<template>
    <form ref="form">
        <span class="field" v-bind:key="field.key" v-for="(field, key) in fields" v-if="field.section === name">
        <b-field :id="field.key" :label="field.type === 'check' ? '' : field.label">
                <template #label v-if="field.mandatory && field.type !== 'check'">
                    {{ field.label }} <small class="has-text-danger">* required</small>
                        <b-tooltip
                            :label="field.help"
                            class="help-tooltip"
                            multilined
                            position="is-bottom"
                            size="is-small"
                            type="is-light"
                            v-if="field.help !== undefined && field.help !== '' && field.help !== null">
                        </b-tooltip>
                </template>
                <template #label v-else-if="field.type !== 'check'">
                    {{ field.label }}
                        <b-tooltip
                            :label="field.help"
                            class="help-tooltip"
                            multilined
                            position="is-bottom"
                            size="is-small"
                            type="is-light"
                            v-if="field.help !== undefined && field.help !== '' && field.help !== null">
                        </b-tooltip>
                </template>
                <b-input :required="field.mandatory || injuryRequired" :type="getType(field.key)" :value="field.value"
                         @blur="updateField($event, field.key)"
                         v-if="field.type === 'text'"></b-input>
                <b-input :required="field.mandatory || injuryRequired" :value="field.value"
                         @blur="updateField($event, field.key)"
                         maxlength="200" type="textarea"
                         v-if="field.type === 'longtext'"></b-input>
                <b-checkbox :required="field.mandatory || injuryRequired" :type="$store.getters.theme"
                            :value="field.value"
                            @input="updateField($event, field.key)"
                            v-if="field.type === 'check'">
                    {{ field.label }}
                </b-checkbox>
                <b-select expanded v-if="field.type === 'select'" @input="updateField($event, field.key)" placeholder="Click to select an option">
                    <option
                        v-for="option in field.options"
                        :value="option.id"
                        :key="option.id">
                        {{ option.label }}
                    </option>
                </b-select>
                <b-datepicker
                    :required="field.mandatory || injuryRequired"
                    :value="field.value ? getValue(field.value) : null"
                    @input="updateField($event, field.key)"
                    icon="calendar"
                    placeholder="Click to select..."
                    trap-focus
                    v-if="field.type === 'date'"
                    editable>
                </b-datepicker>
        </b-field>
        <span class="" v-bind:key="dependent.key" v-for="(dependent, key1) in field.dependent"
              v-if="field.dependent !== null">
                <b-field :id="dependent.key" :label="dependent.type === 'check' ? '' : dependent.label"
                         v-if="$store.state.private_claims.pendingClaim.prefilled[0][key].value">
                <template #label
                          v-if="(dependent.mandatory || (field.type === 'check' && field.value === true)) && dependent.type !== 'check'">
                        {{ dependent.label }} <small class="has-text-danger">* required</small>
                            <b-tooltip
                                :label="dependent.help"
                                class="help-tooltip"
                                multilined
                                position="is-bottom"
                                size="is-small"
                                type="is-light"
                                v-if="dependent.help !== undefined && dependent.help !== '' && dependent.help !== null">
                            </b-tooltip>
                    </template>
                    <template #label v-else-if="dependent.type !== 'check'">
                        {{ dependent.label }} regular
                            <b-tooltip
                                :label="dependent.help"
                                class="help-tooltip"
                                multilined
                                position="is-bottom"
                                size="is-small"
                                type="is-light"
                                v-if="dependent.help !== undefined && dependent.help !== '' && dependent.help !== null">
                            </b-tooltip>
                    </template>
                    <b-input :required="dependent.mandatory || (field.type === 'check' && field.value === true)" :type="getType(dependent.key)"
                             :value="dependent.value"
                             @blur="updateField($event, dependent.key)" v-if="dependent.type === 'text'"
                    ></b-input>
                    <b-input :required="dependent.mandatory || (field.type === 'check' && field.value === true)" :value="dependent.value"
                             @blur="updateField($event, dependent.key)" maxlength="200"
                             type="textarea" v-if="dependent.type === 'longtext'"
                    ></b-input>
                    <b-checkbox :required="dependent.mandatory" :type="$store.getters.theme"
                                :value="dependent.value"
                                @input="updateField($event, dependent.key)" v-if="dependent.type === 'check'"
                    >{{ dependent.label }}
                    </b-checkbox>
                    <b-datepicker
                        :required="dependent.mandatory || (field.type === 'check' && field.value === true)"
                        :value="dependent.value ? getValue(dependent.value) : null"
                        @input="updateField($event, dependent.key)"
                        icon="calendar"
                        placeholder="Click to select..."
                        trap-focus
                        v-if="dependent.type === 'date'"
                        editable
                    >
                    </b-datepicker>
                </b-field>
            <span class="" v-for="(dependent1) in dependent.dependent" v-if="dependent.dependent !== null">
                <b-field :id="dependent1.key"
                         :label="dependent1.type === 'check' ? '' : dependent1.label"
                         v-if="$store.state.private_claims.pendingClaim.prefilled[0][key].dependent[key1].value">
                    <template #label
                              v-if="(dependent1.mandatory || (dependent.type === 'check' && dependent.value === true)) && dependent1.type !== 'check'">
                        {{ dependent1.label }} <small class="has-text-danger">* required</small>
                            <b-tooltip
                                :label="dependent1.help"
                                class="help-tooltip"
                                multilined
                                position="is-bottom"
                                size="is-small"
                                type="is-light"
                                v-if="dependent1.help !== undefined && dependent1.help !== '' && dependent1.help !== null">
                            </b-tooltip>
                    </template>
                    <template #label v-else-if="dependent1.type !== 'check'">
                        {{ dependent1.label }} regular
                            <b-tooltip
                                :label="dependent1.help"
                                class="help-tooltip"
                                multilined
                                position="is-bottom"
                                size="is-small"
                                type="is-light"
                                v-if="dependent1.help !== undefined && dependent1.help !== '' && dependent1.help !== null">
                            </b-tooltip>
                    </template>
                    <b-input :required="dependent1.mandatory || (dependent.type === 'check' && dependent.value === true)" :type="getType(dependent1.key)"
                             :value="dependent1.value"
                             @blur="updateField($event, dependent1.key)" v-if="dependent1.type === 'text'"
                    ></b-input>
                    <b-input :required="dependent1.mandatory || (dependent.type === 'check' && dependent.value === true)" :value="dependent1.value"
                             @blur="updateField($event, dependent1.key)" maxlength="200"
                             type="textarea" v-if="dependent1.type === 'longtext'"
                    ></b-input>
                    <b-checkbox :required="dependent1.mandatory" :type="$store.getters.theme"
                                :value="dependent1.value"
                                @input="updateField($event, dependent1.key)"
                                v-if="dependent1.type === 'check'"
                    >{{
                        dependent1.label }}
                    </b-checkbox>
                    <b-datepicker
                        :required="dependent1.mandatory || (dependent.type === 'check' && dependent.value === true)"
                        :value="dependent1.value ? getValue(dependent1.value) : null"
                        @input="updateField($event, dependent1.key)"
                        icon="calendar"
                        placeholder="Click to select..."
                        trap-focus
                        v-if="dependent1.type === 'date'"
                        editable
                    >
                    </b-datepicker>
                </b-field>
            </span>
        </span>
        </span>
    </form>
</template>

<script>
    import {DateTime} from "luxon";

    export default {
        props: {
            user: Object,
            name: String,
        },
        data() {
            return {
                fields: this.$store.state.private_claims.pendingClaim.prefilled[0],
            }
        },
        computed: {
            injuryRequired: function () {
                if (this.name !== 'injury') return false;
                for (let i = 0; i < this.fields.length; i++) {
                    if (this.fields[i].key === 'injury') {
                        if (this.fields[i].value) {
                            for (let j = 0; j < this.fields[i].dependent.length; j++) {
                                let dependent = this.fields[i].dependent[j];
                                if (dependent.type !== 'check') {
                                    dependent.mandatory = true;
                                }
                            }
                        } else {
                            for (let j = 0; j < this.fields[i].dependent.length; j++) {
                                let dependent = this.fields[i].dependent[j];
                                dependent.mandatory = false;
                            }
                        }
                        this.$forceUpdate();
                        return true;
                    }
                }
                return false;
            },
        },
        methods: {
            updateField(event, key) {
                let value = null;
                if (event instanceof Date) {
                    value = DateTime.fromJSDate(event).toFormat('dd/MM/yyyy');
                } else if (typeof event === 'object' && event !== null) {
                    value = event.target.value;
                } else {
                    value = event;
                }
                this.$store.dispatch('updatePendingClaim', {key, value});
                this.$forceUpdate();
            },
            parseDate(date) {
                return DateTime.fromJSDate(date)
            },
            getValue(val) {
                if (typeof val === 'string') {
                    let jsDate = DateTime.fromFormat(val, 'dd/MM/yyyy').toJSDate()
                    return jsDate;
                } else {
                    return null;
                }
            },
            getType(key) {
                const email_regex = /email/g;
                const phone_regex = /phone/g;

                if (key.match(email_regex)) return 'email';
                if (key.match(phone_regex)) return 'tel';
                return 'text';
            }
        },
    }
</script>

<style lang="scss">
    .field {
        margin-top: 10px;
    }

    .help-tooltip {
        vertical-align: top;
        margin-left: 5px;
    }
</style>
