<template>
    <section>
        <div class="columns is-centered claim-new">
            <div class="column is-three-quarters">
                <b-steps
                    v-model="activeStep"
                    :animated="true"
                    :has-navigation="true">
                    <b-step-item label="Patient">
                        <div class="box has-text-centered">
                            <b-field label="Select a Patient">
                                <b-select
                                    placeholder="Client"
                                    icon="user"
                                    icon-pack="fas">
                                    <option value="1">Option 1</option>
                                    <option value="2">Option 2</option>
                                </b-select>
                            </b-field>
                            <button class="button is-info">Add Patient</button>
                        </div>
                    </b-step-item>

                    <b-step-item label="Special Type Consultation">
                        <div class="box has-text-centered">
                        <b-field label="Select a STC">
                            <b-select
                                placeholder="Special Type Consultation"
                                 icon="pen"
                                icon-pack="fas">
                                <option value="1">Option 1</option>
                                <option value="2">Option 2</option>
                            </b-select>
                        </b-field>
                        </div>
                    </b-step-item>

                    <b-step-item label="Extra Info">
                        <div class="box has-text-centered">
                            <button class="button is-primary is-medium" @click="skipToSig">Skip to Signing</button>
                        </div>
                    </b-step-item>

                    <b-step-item label="Signature">
                        <div class="box has-text-centered">
<!--                        <a class="newClaimFormButton" id="newClaimSignatureButton" onclick="newClaimSignatureShow()">Capture Signature</a>-->
<!--                        <signature-component id="newClaimSignatureField"></signature-component>-->
<!--                        <span id="sigConfirmation"></span>-->
<!--                        <VueSignaturePad ref="signaturePad" width="100vw" height="40vh"/>-->
<!--                        <button class="button is-info" @click="save">Save</button>-->
<!--                        <button class="button is-info" @click="undo">Undo</button>-->

                            <button class="button is-primary is-medium"
                                    @click="openModal = true">
                                Open Signature Widget
                            </button>
                        </div>
                    </b-step-item>

                    <b-step-item label="Finish">
                        <div class="box has-text-centered">
                            <h1 class="title">Review Claim</h1>
                            <h3 class="subtitle">Patient Info</h3>

                            <button class="button is-success">Save Claim</button>
                        </div>
                    </b-step-item>
                </b-steps>
            </div>
        </div>

        <b-modal :active.sync="openModal" full-screen scroll="keep">
            <div class="box sigbox">
                <VueSignaturePad class="sigarea" ref="signaturePad" width="100%" height="90%"/>
                <button class="button is-info" @click="save">Save</button>
                <button class="button is-info" @click="undo">Undo</button>
                <button class="button is-pulled-right is-danger" @click="closeModal">Close</button>
            </div>
        </b-modal>
    </section>
</template>

<script>
    export default {
        props : {

        },
        data() {
            return {
                activeStep: 3,
                openModal: false,
            };
        },
        methods: {
            showOptions: function () {
                this.showDropDown = true;
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            },
            save() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                console.log(isEmpty);
                console.log(data);
            },
            closeModal(){
                this.openModal = false;
            },
            skipToSig(){
                this.activeStep = 3;
            }
        }
    };
</script>

<style scoped>
    .claim-new {
        margin-top: 20px;
        margin-right: 5px;
        margin-left: 5px;
    }

    .sigbox {
        height: 100vh;
        weidth: 100vh;
        margin-bottom: auto;
    }

    .sigarea {
        border: 1px solid darkgrey;
        margin-bottom: 5px;
        border-radius: 10px;
    }
</style>
