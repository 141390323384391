    import axios from 'axios';
    import moment from 'moment';

    export default {
        data() {
            return {
                patient_fetched: false,
                loading: false,
                error: false,
                patient: {
                    pps: '',
                },
                first_name: '',
                last_name: '',
                submitStatus: null,
                form_debounce: false,
            };
        },
        methods: {
            flashMessage(message, type) {
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
            submitForm() {
                if(!this.form_debounce){
                    this.form_debounce = true;
                    let body = {
                        "firstName": this.first_name,
                        "lastName": this.last_name,
                        "pps": this.patient.pps,
                    };
                    axios({
                        method: 'post',
                        url: '/newPatStatic',
                        data: body
                    })
                        .then(response => {
                            if (response.status === 200) {
                                this.flashMessage('New Patient Created', 'is-success');
                                this.$emit('new-patient', this.form.pps);
                                this.resetForm();
                            } else if (response.status === 206) {
                                // Off panel patient
                                this.flashMessage('Off Panel Patient', 'is-primary')
                            }
                            this.form_debounce = false;
                        })
                        .catch(error => {
                            if (error.message === 'Request failed with status code 422') {
                                this.flashMessage('Something went wrong', 'is-warning');
                            } else if(error.message === 'Server ErrorNetwork Error'){
                                this.flashMessage('No internet connection. Please check your connection', 'is-danger')
                            } else {
                                this.flashMessage('Server Error' + error.message, 'is-danger');
                            }
                            this.form_debounce = false;
                        });
                }
            },
            resetForm(){
                this.patient = null;
                this.form = null;
            }
        },
        mounted() {
        }
    };