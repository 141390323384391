<template>
      <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column">
                    <div class="title">Active Sessions</div>
                </div>
                <div class="column">
                    <div class="is-pulled-right">
                        <div class="button is-medium is-primary" @click="refresh">Refresh</div>
                    </div>
                </div>
            </div>
            <div class="columns is-tablet">
               <div v-if="this.loaded == undefined || this.loaded == false" class="columns is-centered">
            	<div class="column is-narrow">
            		<div class="has-text-centered">
            			<div class="spring-spinner">
                        	<div class="spring-spinner-part top">
                            	<div class="spring-spinner-rotator"></div>
                            </div>
                            <div class="spring-spinner-part bottom">
                                <div class="spring-spinner-rotator"></div>
                            </div>
                        </div>
	            	</div>
	            </div>
	    	</div>
            <div v-if="this.loaded">
                <div>
	                <template>
                        <section>
                            <b-field grouped group-multiline>
                                <b-button
                                    label="Delete"
                                    type="is-danger"
                                    class="field"
                                    @click="deleteSessions(checkedRows)" />
                                    <b-switch
                                        v-model="getGuests">Show Guests
                                    </b-switch>
                            </b-field>
                            <b-tabs>
                                <b-tab-item label="Table">
                                    <b-table
                                        :data="sessions"
                                        :columns="columns"
                                        :checked-rows.sync="checkedRows"
                                        :is-row-checkable="(row) => row.id !== 3 && row.id !== 4"
                                        checkable
                                        :checkbox-position="checkboxPosition">
                                        <template #bottom-left>
                                            <b>Total checked</b>: {{ checkedRows.length }}
                                        </template>
                                    </b-table>
                                </b-tab-item>

                                <b-tab-item label="Checked rows">
                                    <pre>{{ checkedRows }}</pre>
                                </b-tab-item>
                            </b-tabs>
                        </section>
	                </template>
              </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
    let axios = require('axios');
    export default {
        props: [],
        data() {
            return {
                loaded: true,
                getGuests: true,
                sessions: [],
                checkedRows: [],
                checkboxPosition: 'left',
                columns: [
                    {
                        field: 'User_ID',
                        label: 'User ID',
                        numeric: true,
                        width: '90',
                    },
                    {
                        field: 'User_Name',
                        label: 'User Name',
                        width: '120',
                    },
                    {
                        field: 'Email',
                        label: 'Email',
                        width: '40'
                    },
                    {
                        field: 'IP_Address',
                        label: 'IP Address',
                    },
                    {
                        field: 'Last_Active',
                        label: 'Last Active',
                    }
                ]
            } 
        },
        watch: {
            getGuests(){
                this.loadSessions();
            }
        },
        methods: {
            refresh(){
                location.reload();
            },
            loadSessions(){
                this.loaded = false;
                axios.post('/admin/getActiveSessions', { params: { 'get_guests': this.getGuests } })
                .then(response => {
                    this.sessions = response.data.ActiveSessions;
                    this.loaded = true;
                })
                .catch(error => {
                    this.flashMessage('Error loading sessions', 'is-danger', 10000)
                })
            },
            flashMessage(message, type, duration = 3000){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                    duration: duration
                })
            },
            deleteSessions(data){
                this.loaded = false;
                let sessionIDs = [];
                for(let dat in data){
                    sessionIDs.push(data[dat].Session_ID);
                }
                
                if(Array.isArray(sessionIDs) && sessionIDs.length == 0){
                    this.flashMessage('No rows checked', 'is-danger')
                    return
                }

                axios.post('/admin/deleteActiveSessions', {'session_ids': sessionIDs})
                .then(response => {
                    let res = response.data.status;

                    if(res === 200)
                        this.refresh();
                    else 
                        this.flashMessage(response.data.error, 'is-danger', 20000)

                    this.loaded = true;
                })
                .catch(error => {
                	console.log(error);
                    this.flashMessage('Error deleting sessions', 'is-danger', 10000)
                })
                
            },
        },
        mounted() {
            this.loadSessions();
        }
    }
</script>