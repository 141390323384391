import { render, staticRenderFns } from "./NewDetailedClaim.vue?vue&type=template&id=631e8222&scoped=true&"
import script from "./NewDetailedClaim.vue?vue&type=script&lang=js&"
export * from "./NewDetailedClaim.vue?vue&type=script&lang=js&"
import style0 from "./NewDetailedClaim.vue?vue&type=style&index=0&id=631e8222&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631e8222",
  null
  
)

export default component.exports