<template>
    <div class="columns is-centered">
        <div class="column is-full-tablet is-three-quarters-widescreen">
        	<h1 class="title is-3" style="color: white;">Summary charts</h1>
        	<div id="filter-box" class="toast-container box has-text-left">
            	<div id="report-filters">
            		<div class="columns">
            			<div class="column">
            				<button class="button is-light" v-on:click="showFilters = !showFilters">Toggle&nbsp;filters&nbsp;&#8645;</button>
            				<button class="button is-light" v-on:click="exportData()" title="In progress">Export</button>
            			</div>
		           	</div>
	            	<div>
	            		<section>
	            			<input type="radio" v-model="reportType" value="0" id="reportType_0">
	            			<label for="reportType_0">By STC</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="reportType" value="1" id="reportType_1">
	            			<label for="reportType_1">By Doctor</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="reportType" value="2" id="reportType_2">
	            			<label for="reportType_2">By Date</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="reportType" value="3" id="reportType_3">
	            			<label for="reportType_3">Detailed</label>
	            			<span class="is-inline-block" v-if="this.loaded == undefined || this.loaded == false">
	            				<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
			            		&nbsp;Loading...
			            	</span>
	            		</section>
	            	</div>
	            	<div>
	            		<section class="has-text-grey-lighter" title="This function is in progress">
	            			<input type="radio" v-model="chartType" value="0" id="chartType_0" disabled>
	            			<label for="chartType_0">Table</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="chartType" value="1" id="chartType_1" disabled>
	            			<label for="chartType_1">Bars</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="chartType" value="2" id="chartType_2" disabled>
	            			<label for="chartType_2">Chart</label>
	            			<span class="has-text-grey-lighter">&nbsp;&#124;&nbsp;</span>
	            			<input type="radio" v-model="chartType" value="3" id="chartType_3" disabled>
	            			<label for="chartType_3">Cake</label>
	            		</section>
	            	</div>
            		<div v-if="showFilters">
            			<b-field label="Date range">
				        	<div class="columns">
				        		<b-datepicker
				        		    v-bind:class="[dPicker(1) ? 'hasActiveFilter' : '', '']"
				                    placeholder="Date from"
				                    v-model="loadDateFrom['_d']"
				                    icon="calendar"
				                    class="column"
				                    title="From"
				                    :date-formatter="formatter"
				                    :min-date="minDate"
				                    :max-date="loadDateTo['_d']">
				                </b-datepicker>
				                <b-datepicker
				                    v-bind:class="[dPicker(2) ? 'hasActiveFilter' : '', '']"
				                    placeholder="Date to"
				                    v-model="loadDateTo['_d']"
				                    icon="calendar"
				                    class="column"
				                    title="To"
				                    :date-formatter="formatter"
				                    :min-date="loadDateFrom['_d']"
				                    :max-date="maxDate">
				                </b-datepicker>
				        	</div>
				        </b-field>
				        <b-field label="Filters">
				        	<div class="columns is-fullwidth">
			                    <b-select placeholder="Status"
			                              class="column"
			                              v-bind:class="[filterStatus >= 0 ? 'hasActiveFilter' : '', '']"
			                              title="Status"
			                              v-model="filterStatus">
			                        <option :value="-1" selected>All statuses</option>
			                        <option
			                            v-for="(statusName, statusID) in statuses"
			                            :value="statusID">
			                            {{ statusName }}
			                        </option>
			                        <option :title="filterStatus" v-if="filterStatus >= 0 && statuses[[filterStatus]] == undefined" :value="filterStatus">&#35;N&#47;A</option>
			                    </b-select>
			                    <b-select placeholder="STC Type"
			                              class="column"
			                              v-bind:class="[filterSTC >= 0 ? 'hasActiveFilter' : '', '']"
			                              title="STC Type"
			                              v-model="filterSTC">
			                        <option :value="-1" selected>All STC types</option>
			                        <option
			                            v-for="(stcName, stcID) in stcTypes"
			                            :value="stcID">
			                            {{ stcName }}
			                        </option>
			                        <option :title="filterSTC" v-if="filterSTC >= 0 && stcTypes[[filterSTC]] == undefined" :value="filterSTC">&#35;N&#47;A</option>
			                    </b-select>
			                    <b-select placeholder="Service"
			                              class="column is-one-third"
			                              v-bind:class="[filterService >= 0 ? 'hasActiveFilter' : '', '']"
			                              title="STC Service"
			                              v-model="filterService">
			                        <option :value="-1" selected>All services</option>
			                        <option
			                            v-for="(serviceData, serviceID) in services"
			                            :value="serviceID">
			                            {{ serviceData['symbol'] + ' ' + serviceData['name'] }}
			                        </option>
			                        <option :title="filterService" v-if="filterService >= 0 && services[[filterService]] == undefined" :value="filterService">&#35;N&#47;A</option>
			                    </b-select>
			                    <b-field class="column">
			                    	&nbsp;
			                    </b-field>
				        	</div>
				        </b-field>
				        <b-field label="Personal">
				        	<div class="columns">
			                    <b-select placeholder="Group"
			                              title="Group"
			                    		  class="column is-one-third"
			                    		  v-bind:class="[filterGroup >= 0 ? 'hasActiveFilter' : '', '']"
			                              v-model="filterGroup">
			                        <option :value="-1" selected>All groups</option>
			                        <option
			                            v-for="(groupName, groupID) in groups"
			                            :value="groupID">
			                            {{ groupName }}
			                        </option>
			                        <option :title="filterGroup" v-if="filterGroup >= 0 && groups[[filterGroup]] == undefined" :value="filterGroup">&#35;N&#47;A</option>
			                    </b-select>
			                    <b-select placeholder="Client"
			                              title="Client"
			                    		  class="column is-one-third"
			                    		  v-bind:class="[filterClient >= 0 ? 'hasActiveFilter' : '', '']"
			                              v-model="filterClient">
			                        <option :value="-1" selected>All clients</option>
			                        <option
			                            v-for="(clientName, clientID) in clients"
			                            :value="clientID">
			                            {{ clientName }}
			                        </option>
			                        <option :title="filterClient" v-if="filterClient >= 0 && clients[[filterClient]] == undefined" :value="filterClient">&#35;N&#47;A</option>
			                    </b-select>
			                    <b-field class="column">
			                    	&nbsp;
			                    </b-field>
				        	</div>
				        </b-field>
				        <b-field>
				        	<div class="columns">
				        		<div class="column">
				        			<button class="button is-primary is-medium is-pulled-right" v-on:click="fetchData()">Filter</button>
				        		</div>
				        	</div>
				        </b-field>
					</div>
				</div>
        	</div>
            <div id="report-box" class="toast-container box has-text-centered">
           		<div v-if="this.loaded == undefined || this.loaded == false" class="columns is-centered">
                   	<div class="column is-narrow">
	            		<div class="has-text-centered">
	            			<div class="spring-spinner">
	                        	<div class="spring-spinner-part top">
	                            	<div class="spring-spinner-rotator"></div>
	                            </div>
	                            <div class="spring-spinner-part bottom">
	                                <div class="spring-spinner-rotator"></div>
	                            </div>
	                        </div>
		            	</div>
		            </div>
		    	</div>
                <div id="report-table" v-if="this.loaded && this.reportType == 1">
                	<div>
                		Cluster claims count: {{this.filteredClusterClaimsCount}}
                		<hr>
                	</div>
                	<div v-for="(groupDetails, group) in this.filteredData">
                		<table class="table is-striped is-hoverable is-fullwidth resultTable">
	                		<thead>
	                			<th>ID</th>
	                			<th>Name</th>
	                			<th>Submitting as</th>
	                			<th>HSE Number</th>
	                			<th>Claims count</th>
	                		</thead>
	                		<tbody>
		                		<tr v-for="(clientsDetails, client) in groupDetails">
		                			<td>{{client}}</td>
		                			<td :title="client">{{clientsDetails['name']}}</td>
		                			<td :title="clientsDetails['submittingID']">{{clientsDetails['submittingName']+' ('+clientsDetails['submittingHSE']+')'}}</td>
		                			<td>{{clientsDetails['HSE']}}</td>
		                			<td>{{clientsDetails['count']}}</td>
		                		</tr>
		                		<tr class="has-text-weight-bold">
		                			<td>{{group}}</td>
		                			<td>{{groups[group]}}</td>
		                			<td></td>
		                			<td></td>
		                			<td>{{filteredGroupsClaimsCount[group]}}</td>
		                		</tr>
	                		</tbody>
	                	</table>
                		<hr>
                	</div>
                </div>
                <div id="report-table" v-if="this.loaded && this.reportType == 0">
                	<div>
                		Cluster claims count: {{this.filteredClusterClaimsCount}}
                		<hr>
                	</div>
                	<div v-for="(groupDetails, group) in this.filteredDataSTC">
                		<table class="table is-striped is-hoverable is-fullwidth resultTable">
	                		<thead>
	                			<th>ID</th>
	                			<th>STC symbol</th>
	                			<th>STC service</th>
	                			<th>Claims count</th>
	                		</thead>
	                		<tbody>
		                		<tr v-for="(stcDetails, stc) in groupDetails">
		                			<td>{{stc}}</td>
		                			<td :title="stc">{{stcDetails['symbol']}}</td>
		                			<td :title="stc">{{stcDetails['name']}}</td>
		                			<td>{{stcDetails['count']}}</td>
		                		</tr>
		                		<tr class="has-text-weight-bold">
		                			<td>{{group}}</td>
		                			<td></td>
		                			<td>{{groups[group]}}</td>
		                			<td>{{filteredGroupsClaimsCount[group]}}</td>
		                		</tr>
	                		</tbody>
	                	</table>
                		<hr>
                	</div>
                </div>
                <div id="report-table" v-if="this.loaded && this.reportType == 2">
                	<div>
                		Cluster claims count: {{this.filteredClusterClaimsCount}}
                		<hr>
                	</div>
                	<div v-for="(groupDetails, group) in this.filteredDataDate">
                		<table class="table is-striped is-hoverable is-fullwidth resultTable">
	                		<thead>
	                			<th>Date</th>
	                			<th>Claims count</th>
	                		</thead>
	                		<tbody>
		                		<tr v-for="(dateDetails, currentDate) in groupDetails">
		                			<td :title="currentDate">{{dateDetails['date']}}</td>
		                			<td>{{dateDetails['count']}}</td>
		                		</tr>
		                		<tr class="has-text-weight-bold">
		                			<td>{{groups[group]}}</td>
		                			<td>{{filteredGroupsClaimsCount[group]}}</td>
		                		</tr>
	                		</tbody>
	                	</table>
                		<hr>
                	</div>
                </div>
                <div id="report-table" v-if="this.loaded && this.reportType == 3">
                	<div>
                		Cluster claims count: {{this.filteredClusterClaimsCount}}
                		<hr>
                	</div>

                	<div v-for="(groupDetails, group) in this.filteredDataDetailed">
                		<table class="table is-striped is-hoverable is-fullwidth resultTable">
	                		<thead>
	                			<th>Name</th>
	                			<th>Submitting Name</th>
	                			<th>HSE Number</th>
	                			<th>STC symbol</th>
	                			<th>STC service</th>
	                			<th>Date</th>
	                			<th>Claims count</th>
	                		</thead>
	                		<tbody>
	                			<template v-for="(dateDetails, dateKey) in groupDetails">
		                			<template v-for="(stcDetails, stc) in dateDetails">
		                				<template v-for="(clientDetails, client) in stcDetails">
		                					<tr>
			                					<td :title="client">{{clientDetails['clientName']}}</td>
			                					<td :title="clientDetails['submittingID']">{{clientDetails['submittingName']}}</td>
			                					<td :title="clientDetails['submittingHSE']">{{clientDetails['HSE']}}</td>
			                					<td :title="stc">{{clientDetails['serviceSymbol']}}</td>
			                					<td :title="stc">{{clientDetails['serviceName']}}</td>
			                					<td :title="dateKey">{{clientDetails['date']}}</td>
			                					<td>{{clientDetails['count']}}</td>
			                				</tr>
		                				</template>
		                			</template>
		                		</template>
		                		<tr class="has-text-weight-bold">
		                			<td>{{groups[group]}}</td>
		                			<td></td>
		                			<td></td>
		                			<td></td>
		                			<td></td>
		                			<td></td>
		                			<td>{{filteredGroupsClaimsCount[group]}}</td>
		                		</tr>
	                		</tbody>
	                	</table>
                		<hr>
                	</div>
                </div>
            </div>
        </div>
        <div id="testLog"></div>
    </div>
</template>
<script>
	import axios from 'axios';
	import moment from 'moment';
    export default {
        components: {
        	// void
        },
        props: {
        	// those params affect reportData and can be used for URLs/efficiency (decrease overal number of data from backend)
        	dfrom: {default: 'null', type: String},
        	dto: {default: 'null', type: String}
        },
        data(){
            return {
            	logging: false, // primitive debugging
            	blockAutoExecution: true, // first load -> no watchers
            	message: '',
            	showFilters: false,
            	reportType: 0,
            	chartType: 0,
            	loaded: false,
            	percentage: 0,
            	rawData: {},
                reportData: {},
                reportDataSTC: {},
                reportDataDate: {},
                reportDataDetailed: {},
                filteredData: {},
                filteredDataSTC: {},
                filteredDataDate: {},
                filteredDataDetailed: {},
                currentView: {},
                errorCount: 0,
                clusterClaimsCount: 0,
                groupsClaimsCount: {},
                filteredClusterClaimsCount: 0,
                filteredGroupsClaimsCount: {},
                groups: {},
                clients: {},
                stcTypes: {},
                statuses: {},
                services: {},
                dfromUpdated: 'null', // fetch call param
                dtoUpdated: 'null',   // fetch call param
                minDate: new Date(0),
                maxDate: new Date(),
                dateFrom: moment(0), // oldest date in fetched data
                dateTo: moment(0),   // newest date in fetched data
                dateFromNum: 0,      // oldest date key
                dateToNum: 0,        // newest date key
                // those params affect rawData and reportData (loaded from server)
                loadDateFrom: moment().subtract(1, "month"),
                loadDateTo: moment(),
                // those params affect filteredData and are used for JS-driven filtering, the result of backend call (reportData) remains
                filterSTC: -1,
                filterStatus: -1,
                filterService: -1,
                filterGroup: -1,
                filterClient: -1,
            }
        },
        methods: {
        	log(message) {
        		this.message = message;
    			if (this.logging) {
    				console.log(this.message);
    				//let testLog = document.getElementById('testLog');
    		    	//testLog.innerHTML += this.message;
    			}
        	},
        	// dFrom and dTo are the filters for backend call, all other filters are JS side
			fetchData(dFrom = this.dfromUpdated, dTo = this.dtoUpdated) {
        		this.blockAutoExecution = true; // turning off the watchers until the view loaded
				this.log('Fetching data');
				this.loaded = false;

				axios.get('/reportData/summary?dateFrom=' + dFrom + '&dateTo=' + dTo)
					.then(response => {
                        this.rawData = Object.values(response.data);
                        this.loaded = true;
                        this.log('Fetched ' + this.rawData.length);
                        this.calculateData();
                        this.filterData();
                        this.blockAutoExecution = false; // turning on the watchers -> ready
					});
			},
			oneIterationConversion(rawDataRow) {
				this.log('oneIterationConversion');
				// Converting a row of a raw data to a set of ready-to-apply report-relevant data
				let resultStructure = {};
				let resultStructureSTC = {};
				let resultStructureDate = {};
				let resultStructureDetailed = {};
				let clusterClaimsCount = 0;
				let groupsClaimsCount = {};
				let fullResult = {};
				let currentClient = rawDataRow['client'];
				let currentGroup = rawDataRow['group'];
				let currentSTC = rawDataRow['service'];
				let currentDate = rawDataRow['dateKey'];

				resultStructure = {
						'count'          : 1,
						'name'           : this.toProper(rawDataRow['clientName']),
						'HSE'            : rawDataRow['clientHSE'],
						'submittingName' : this.toProper(rawDataRow['submittingName']),
						'submittingHSE'  : rawDataRow['submittingHSE'],
						'submittingID'   : rawDataRow['submitting'],
				};
				if (resultStructure['HSE'] == null) {
					resultStructure['HSE'] = rawDataRow['submittingHSE'];
				}

				resultStructureSTC = {
						'count'          : 1,
						'name'           : this.toProper(rawDataRow['serviceName']),
						'symbol'         : rawDataRow['serviceSymbol'],
				};

				resultStructureDate = {
						'count'   : 1,
						'date'    : moment(rawDataRow['dateKey'], 'YYYYMMDD').format("DD[/]MM[/]YYYY"),
				};

				resultStructureDetailed = {
						'count'          : 1,
						'clientName'     : resultStructure['name'],
						'HSE'            : resultStructure['HSE'],
						'submittingName' : resultStructure['submittingName'],
						'submittingHSE'  : resultStructure['submittingHSE'],
						'submittingID'   : resultStructure['submittingID'],
						'serviceName'    : resultStructureSTC['name'],
						'serviceSymbol'  : resultStructureSTC['symbol'],
						'date'           : resultStructureDate['date'],
				};

				if (this.groups[[currentGroup]] == undefined) {
					this.groups[[currentGroup]] = this.toProper(rawDataRow['groupName']);
				}

				if (this.clients[[currentClient]] == undefined) {
					this.clients[[currentClient]] = this.toProper(rawDataRow['clientName']);
				}

				if (this.stcTypes[[rawDataRow['STCType']]] == undefined) {
					this.stcTypes[[rawDataRow['STCType']]] = this.toProper(rawDataRow['STCTypeName']);
				}

				if (this.statuses[[rawDataRow['status']]] == undefined) {
					this.statuses[[rawDataRow['status']]] = this.toProper(rawDataRow['statusName']);
				}

				if (this.services[[rawDataRow['service']]] == undefined) {
					this.services[[rawDataRow['service']]] = {'name' : this.toProper(rawDataRow['serviceName']), 'symbol' : rawDataRow['serviceSymbol']};
				}

				if (this.dateFromNum == 0 || rawDataRow['dateKey'] < this.dateFromNum) {
					this.dateFromNum = rawDataRow['dateKey'];
					this.dateFrom = moment(rawDataRow['dateKey'], 'YYYYMMDD');
				}

				if (this.dateToNum == 0 || rawDataRow['dateKey'] > this.dateToNum) {
					this.dateToNum = rawDataRow['dateKey'];
					this.dateTo = moment(rawDataRow['dateKey'], 'YYYYMMDD');
				}

				groupsClaimsCount[[currentGroup]] = 1;
				clusterClaimsCount = 1;

				fullResult = {
					'currentGroup' : currentGroup,
					'currentClient' : currentClient,
					'currentSTC' : currentSTC,
					'currentDate' : currentDate,
					'resultStructure' : resultStructure,
					'resultStructureSTC' : resultStructureSTC,
					'resultStructureDate' : resultStructureDate,
					'resultStructureDetailed' : resultStructureDetailed
				};

				return fullResult;
			},
			calculateData() {
				this.log('Calculating data');
				this.loaded = false;
				let i = 0;
				let j = 0;

				this.resetData();

				let currentClient = 0;
				let currentGroup = 0;
				let currentSTC = 0;
				let currentDate = 0;

				let loopSize = this.rawData.length;
				let lastIterator = loopSize - 1;

				let fullResult = {};

				for (i = 0; i < loopSize; i++) {

					this.log('Calculating row ' + i);

					if (this.rawData[i] !== undefined) {

						fullResult = this.oneIterationConversion(this.rawData[i]);

						currentClient = fullResult['currentClient'];
						currentGroup = fullResult['currentGroup'];
						currentSTC = fullResult['currentSTC'];
						currentDate = fullResult['currentDate'];

						if (this.reportData[[currentGroup]] == undefined) {
							this.reportData[[currentGroup]] = {};
						}

						if (this.reportDataSTC[[currentGroup]] == undefined) {
							this.reportDataSTC[[currentGroup]] = {};
						}

						if (this.reportDataDate[[currentGroup]] == undefined) {
							this.reportDataDate[[currentGroup]] = {};
						}

						if (this.reportDataDetailed[[currentGroup]] == undefined) {
							this.reportDataDetailed[[currentGroup]] = {};
						}

						if (this.reportDataDetailed[[currentGroup]][[currentDate]] == undefined) {
							this.reportDataDetailed[[currentGroup]][[currentDate]] = {};
						}

						if (this.reportDataDetailed[[currentGroup]][[currentDate]][[currentSTC]] == undefined) {
							this.reportDataDetailed[[currentGroup]][[currentDate]][[currentSTC]] = {};
						}

						if (this.reportData[[currentGroup]][[currentClient]] == undefined) {
							this.reportData[[currentGroup]][[currentClient]] = fullResult['resultStructure'];
						} else {
							this.reportData[[currentGroup]][[currentClient]]['count']++;
						}

						if (this.reportDataSTC[[currentGroup]][[currentSTC]] == undefined) {
							this.reportDataSTC[[currentGroup]][[currentSTC]] = fullResult['resultStructureSTC'];
						} else {
							this.reportDataSTC[[currentGroup]][[currentSTC]]['count']++;
						}

						if (this.reportDataDate[[currentGroup]][[currentDate]] == undefined) {
							this.reportDataDate[[currentGroup]][[currentDate]] = fullResult['resultStructureDate'];
						} else {
							this.reportDataDate[[currentGroup]][[currentDate]]['count']++;
						}

						if (this.reportDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]] == undefined) {
							this.reportDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]] = fullResult['resultStructureDetailed'];
						} else {
							this.reportDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]]['count']++;
						}

						if (i == lastIterator) {
							if (this.dateFrom['_d'] != undefined && this.dateTo['_d'] == undefined) {
								this.dateTo = this.dateFrom;
							}
							if (this.dateFrom['_d'] == undefined && this.dateTo['_d'] != undefined) {
								this.dateFrom = this.dateTo;
							}
						}

						if (this.groupsClaimsCount[[currentGroup]] == undefined) {
							this.groupsClaimsCount[[currentGroup]] = 1;
						} else {
							this.groupsClaimsCount[[currentGroup]]++;
						}
						this.clusterClaimsCount++;
					} else {
						this.errorCount++;
					}
					//this.log(this.percentageCount(i, loopSize));
				}
				//this.percentageCount();
				this.log('Errors: ' + this.errorCount);
				this.loaded = true;
			},
			resetData() {
				this.log('resetData');
				var i = 0;
				this.clusterClaimsCount = 0;
				this.groupsClaimsCount = {};
				this.reportData = {};
				this.reportDataSTC = {};
				this.reportDataDate = {};
				this.reportDataDetailed = {};
				this.groups = {};
				this.clients = {};
				this.stcTypes = {};
				this.statuses = {};
				this.services = {};
			},
			resetFilteredData() {
				this.log('resetFilteredData');
				this.filteredData = {};
				this.filteredDataSTC = {};
				this.filteredDataDate = {};
				this.filteredDataDetailed = {};
				this.filteredClusterClaimsCount = 0;
				this.filteredGroupsClaimsCount = {};
			},
			filterData() {
				this.loaded = false;
				this.resetFilteredData();
				let filters = false;
				let filteredData = {};
				let filteredDataSTC = {};
				let filteredDataDate = {};
				let filteredDataDetailed = {};
				let filteredClusterClaimsCount = 0;
				let filteredGroupsClaimsCount = {};
				let i = 0;
				let loopSize = 0;
				let lastIterator = 0;
				let valid = false;
				this.log('Filtering');

				// Claim filters

				let stc = this.filterSTC >= 0;
				let status = this.filterStatus >=0;
				let service = this.filterService >= 0;

				// Personal filters

				let group = this.filterGroup >= 0;
				let client = this.filterClient >= 0;

				if (stc || status || service || group || client) {
					filters = true;
				}

				if (!filters) {
					filteredData = this.reportData;
					filteredDataSTC = this.reportDataSTC;
					filteredDataDate = this.reportDataDate;
					filteredDataDetailed = this.reportDataDetailed;
					filteredClusterClaimsCount = this.clusterClaimsCount;
					filteredGroupsClaimsCount = this.groupsClaimsCount;
				} else {
					loopSize = this.rawData.length;
					lastIterator = loopSize - 1;

					let currentClient = 0;
					let currentGroup = 0;
					let currentSTC = 0;
					let currentDate = 0;
					let fullResult = {};

					let dateFromValid = true;
					let dateToValid = true;
					let stcValid = true;
					let statusValid = true;
					let serviceValid = true;
					let groupValid = true;
					let clientValid = true;

					// Filtering
					for (i = 0; i < loopSize; i++) {

						valid = false;

						if (stc) {
							if (this.rawData[i]['STCType'] == this.filterSTC) {
								stcValid = true;
							} else {
								stcValid = false;
							}
						}

						if (status) {
							if (this.rawData[i]['status'] == this.filterStatus) {
								statusValid = true;
							} else {
								statusValid = false;
							}
						}

						if (service) {
							if (this.rawData[i]['service'] == this.filterService) {
								serviceValid = true;
							} else {
								serviceValid = false;
							}
						}

						if (group) {
							if (this.rawData[i]['group'] == this.filterGroup) {
								groupValid = true;
							} else {
								groupValid = false;
							}
						}

						if (client) {
							if (this.rawData[i]['client'] == this.filterClient) {
								clientValid = true;
							} else {
								clientValid = false;
							}
						}

						if (dateFromValid && dateToValid && stcValid && statusValid && serviceValid && groupValid && clientValid) {
							valid = true;
						}

						if (valid) {

							fullResult = this.oneIterationConversion(this.rawData[i]);

							currentClient = fullResult['currentClient'];
							currentGroup = fullResult['currentGroup'];
							currentSTC = fullResult['currentSTC'];
							currentDate = fullResult['currentDate'];

							if (filteredData[[currentGroup]] == undefined) {
								filteredData[[currentGroup]] = {};
							}

							if (filteredDataSTC[[currentGroup]] == undefined) {
								filteredDataSTC[[currentGroup]] = {};
							}

							if (filteredDataDate[[currentGroup]] == undefined) {
								filteredDataDate[[currentGroup]] = {};
							}

							if (filteredDataDetailed[[currentGroup]] == undefined) {
								filteredDataDetailed[[currentGroup]] = {};
							}

							if (filteredDataDetailed[[currentGroup]][[currentDate]] == undefined) {
								filteredDataDetailed[[currentGroup]][[currentDate]] = {};
							}

							if (filteredDataDetailed[[currentGroup]][[currentDate]][[currentSTC]] == undefined) {
								filteredDataDetailed[[currentGroup]][[currentDate]][[currentSTC]] = {};
							}

							if (filteredData[[currentGroup]][[currentClient]] == undefined) {
								filteredData[[currentGroup]][[currentClient]] = fullResult['resultStructure'];
							} else {
								filteredData[[currentGroup]][[currentClient]]['count']++;
							}

							if (filteredDataSTC[[currentGroup]][[currentSTC]] == undefined) {
								filteredDataSTC[[currentGroup]][[currentSTC]] = fullResult['resultStructureSTC'];
							} else {
								filteredDataSTC[[currentGroup]][[currentSTC]]['count']++;
							}

							if (filteredDataDate[[currentGroup]][[currentDate]] == undefined) {
								filteredDataDate[[currentGroup]][[currentDate]] = fullResult['resultStructureDate'];
							} else {
								filteredDataDate[[currentGroup]][[currentDate]]['count']++;
							}

							if (filteredDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]] == undefined) {
								filteredDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]] = fullResult['resultStructureDetailed'];
							} else {
								filteredDataDetailed[[currentGroup]][[currentDate]][[currentSTC]][[currentClient]]['count']++;
							}

							if (filteredGroupsClaimsCount[[currentGroup]] == undefined) {
								filteredGroupsClaimsCount[[currentGroup]] = 1;
							} else {
								filteredGroupsClaimsCount[[currentGroup]]++;
							}
							filteredClusterClaimsCount++;
						}
					}
				}
				this.filteredData = filteredData;
				this.filteredDataSTC = filteredDataSTC;
				this.filteredDataDate = filteredDataDate;
				this.filteredDataDetailed = filteredDataDetailed;
				this.filteredClusterClaimsCount = filteredClusterClaimsCount;
				this.filteredGroupsClaimsCount = filteredGroupsClaimsCount;
				this.log('Filtered: ' + this.filteredClusterClaimsCount);
				this.loaded = true;
			},
			percentageCount(element = 0, count = 0) {
				let percentage = 0;
				if (element == 0 && count == 0) {
					percentage = 0;
				} else if (!isNaN(element) && !isNaN(count)) {
					percentage = Math.round((element/count)*100);
				} else {
					percentage = 0;
				}
				if (percentage > 100) {
					percentage = 100;
				}
				this.percentage = percentage;
				return percentage;
			},
			dPicker(dateInt) {
				let result = false;
				if (dateInt == 1) {
					result = !(this.loadDateFrom['_d'].getTime() == this.minDate.getTime());
				} else if (dateInt == 2) {
					result = !(this.loadDateTo['_d'].getTime() == this.maxDate.getTime());
				}
				return result;
			},
			formatter(d) {
		    	return d.toDateString();
		    },
		    toProper(possibleString) {
		    	let newString = '';
		    	if (typeof possibleString === 'string' || possibleString instanceof String) {
		    		String.prototype.toProperCase = function(opt_lowerCaseTheRest) {
	    				return (opt_lowerCaseTheRest ? this.toLowerCase() : this)
	    					.replace(/(^|[\s\xA0])[^\s\xA0]/g, function(s){ return s.toUpperCase(); });
	    			};
		    		newString = possibleString.toProperCase(true);
		    	}
		    	return newString;
		    },
		    firstLoad() {
		    	this.log('First load');
		    	this.loaded = false;
		    	axios.get('/reportData/dates/')
		    	.then(response => {
		    		this.log('Date range loaded');

                    this.minDate = (new Date(response.data['minDate']));
                    this.maxDate = (new Date(response.data['maxDate']));

                    this.minDate.setHours(0,0,0);
                    this.maxDate.setHours(0,0,0);

                    this.maxDate.setDate(this.maxDate.getDate() + 1);

                    if (this.dfrom != 'null' && this.dto != 'null') {
                    	this.loadDateFrom = moment(this.dfrom, 'DDMMYYYY');
                    	this.loadDateTo = moment(this.dto, 'DDMMYYYY');
                    } else if (this.dfrom != 'null') {
                    	this.loadDateFrom = moment(this.dfrom, 'DDMMYYYY');
                    	this.loadDateTo = moment(this.maxDate);
                    } else if (this.dto != 'null') {
                    	this.loadDateFrom = moment(this.minDate);
                    	this.loadDateTo = moment(this.dto, 'DDMMYYYY');
                    } else {
                    	this.loadDateFrom = moment(this.maxDate);
                    	this.loadDateFrom.subtract(1, "month");
                        this.loadDateTo = moment(this.maxDate);
                    }

                    this.updateDateParams();

                    this.loaded = true;
                    this.fetchData();
				});
		    },
		    updateDateParams() {
		    	this.dfromUpdated = this.loadDateFrom.format('YYYY-MM-DD');
                this.dtoUpdated = this.loadDateTo.format('YYYY-MM-DD');
		    },
		    getCurrentView() {
		    	this.log('Getting current view');
		    	this.loaded = false;
		    	this.currentView = {};
		    	let i = 0;
		    	let j = 0;
		    	let k = 0;
		    	let l = 0;
		    	let tables = document.getElementsByClassName('resultTable');
		    	this.log('Tables count: ' + tables.length);
		    	let cells = null;
		    	let key = '';
		    	let rowCount = 0;
		    	let cellCount = 0;
		    	let content = '';

		    	for (i = 0; i < tables.length; i++) {
		    		rowCount = tables[[i]].rows.length;
		    		this.log('Table ' + i + ', ' + rowCount + ' rows');
		    		for (j = 0; j < rowCount; j++) {
		    			cells = tables[[i]].rows.item(j).cells;
		    			cellCount = cells.length;
		    			this.log('Row ' + j + ', ' + cellCount + ' cells');
		    			for (k = 0; k < cellCount; k++) {
		    				key = tables[[i]].tHead.children[[k]].innerHTML;
		    				content = cells.item(k).innerHTML;
		    				this.log('Cell ' + k + ' header: ' + key + ', content: ' + content);
		    				if (this.currentView[[i]] == undefined) {
		    					this.currentView[[i]] = {};
		    				}
	    					if (this.currentView[[i]][[j]] == undefined) {
	    						this.currentView[[i]][[j]] = {};
	    					}
	    					this.currentView[[i]][[j]][[key]] = content;
		    			}
		    		}
		    	}
		    	this.loaded = true;
		    },
		    exportData() {
		    	this.loaded = false;
		    	this.getCurrentView();

		    	let i = 0;
		    	let csvContent = "data:text/csv;charset=utf-8,";

		    	for (let [tableKey, csvTable] of Object.entries(this.currentView)) {
		    		i = 0;
		    		for (let [rowKey, csvRow] of Object.entries(csvTable)) {
		    			if (csvRow != undefined) {
		    				if (i == 0) {
			    				csvContent += '"' + Object.keys(csvRow).join('","') + '"';
			    				csvContent += "\n";
			    			}
			    			//csvContent += Object.entries(csvRow).join(",");
			    			csvContent += '"' + Object.values(csvRow).join('","') + '"';
			    			csvContent += "\n";
			    			i++;
		    			}
		    		}
		    		csvContent += "\n";
		    		csvContent = csvContent.replace(/(^\[)|(\]$)/gm, "");
		    	}

		    	this.log(csvContent);

		        const data = encodeURI(csvContent);
		        const link = document.createElement("a");
		        link.setAttribute("href", data);
		        link.setAttribute("download", "export.csv");
		        link.click();
		        this.loaded = true;
		    },
        },
        watch: {
        	'minDate' : function(newVal, oldVal) {
        		this.log('minDate was: ' + oldVal + ', is: ' + newVal);
        	},
        	'maxDate' : function(newVal, oldVal) {
        		this.log('maxDate was: ' + oldVal + ', is: ' + newVal);
        	},
	        'filterSTC' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.log('filterSTC modified, filterData() execution triggered');
        		}
	        },
	        'filterStatus' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.log('filterStatus modified, filterData() execution triggered');
        		}
	        },
	        'filterService' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.log('filterService modified, filterData() execution triggered');
        		}
	        },
	        'filterGroup' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.log('filterGroup modified, filterData() execution triggered');
        		}
	        },
	        'filterClient' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.log('filterClient modified, filterData() execution triggered');
        		}
	        },
	        'loadDateFrom._d' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.updateDateParams();
        		}
	        },
	        'loadDateTo._d' : function() {
	        	if (!this.blockAutoExecution) {
	        		this.updateDateParams();
        		}
	        },
        },
        computed: {
        	showPercentage() {
        		let result = '';
                if (this.percentage > 0 && this.percentage <= 100){
                	result = percentage + '&nbsp;&#37;';
                }
                return result;
            },
        },
        mounted() {
        	this.log('mounted, firstLoad() execution triggered');
        	this.blockAutoExecution = true; // no watchers during the first load
        	this.firstLoad();
        },
        beforeDestroy() {
        	// void
        },
    }
</script>
<style>

	.hasActiveFilter select, .hasActiveFilter input {
	    background-color: #ffff95;
	}

	.hidden {
		display: none;
	}

</style>
