<template>
    <div>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Add New Patient</p>
            </header>
            <section class="modal-card-body">
                <b-field grouped>
                    <b-field label="First Name" expanded>
                        <b-input v-model="patient.firstName" icon="user" placeholder="John"></b-input>
                    </b-field>
                    <b-field label="Last Name" expanded>
                        <b-input v-model="patient.lastName" icon="user" placeholder="Doe"></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field label="Gender">
                        <b-select placeholder="Select a Gender" v-model="patient.sex">
                            <option
                                v-for="option in genders"
                                :value="option.id"
                                :key="option.id">
                                {{ option.type }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Date of Birth" expanded>
                        <b-datepicker
                            v-model="patient.dob"
                            placeholder="Type or select a date..."
                            icon="calendar">
                        </b-datepicker>
                    </b-field>
                </b-field>


                <b-field grouped>
                    <b-field label="Patient Type">
                        <b-select placeholder="Select a Patient" v-model="patient.type">
                            <option
                                v-for="option in patient_type"
                                :value="option.id"
                                :key="option.id">
                                {{ option.type }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="MRN / PSN" expanded>
                        <b-input v-model="patient.pps" icon="id-card" placeholder="A1234"></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field label="Email" expanded>
                        <b-input v-model="patient.email" icon="envelope" placeholder="john@doe.com"></b-input>
                    </b-field>

                    <b-field label="Phone" expanded>
                        <b-input v-model="patient.phone" icon="phone" placeholder="0851112233"></b-input>
                    </b-field>
                </b-field>

                <b-field label="Address">
                    <b-input v-model="patient.address" maxlength="200" type="textarea"></b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-outlined" type="button" @click="$parent.close()">Close</button>
                <button class="button is-primary" @click="submitForm">Register Patient</button>
            </footer>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                patient : {
                    firstName : '',
                    lastName : '',
                    sex : '',
                    dob : new Date(),
                    type : '',
                    cardno: '',
                    pps : '',
                    email : '',
                    phone : '',
                    address: '',
                    otherName: '',
                    mrn: '',
                    expDate: '',
                },
                patient_type: [
                    {id: 0, type: 'Private'},
                    {id: 1, type: 'HSE'},
                    {id: 2, type: 'Other'},
                ],
                genders: [
                    {id: 0, type: 'Female'},
                    {id: 1, type: 'Male'},
                    {id: 2, type: 'Other'},
                ]
            }
        },
        methods: {
            resetForm(){
                this.patient = {
                    firstName : '',
                    lastName : '',
                    sex : '',
                    dob : new Date(),
                    type : '',
                    cardno: '',
                    pps : '',
                    email : '',
                    phone : '',
                    address: '',
                    otherName: '',
                    mrn: '',
                    expDate: '',
                }
            },
            flashMessage(message, type){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
            submitForm(){
                let body = this.patient;
                axios({
                    method: 'post',
                    url: '/newPatStatic',
                    data: body
                })
                    .then(response => {
                        if(response.status === 200){
                            this.flashMessage('New Patient Created', 'is-success');
                            this.resetForm();
                            this.$emit('new-patient');
                            // this.$parent.fetchPatients();
                            this.$parent.close();
                        }
                    })
                    .catch(error => {
                        if(error.message === 'Request failed with status code 422'){
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else {
                            this.flashMessage('Server Error' + error.message, 'is-danger');
                        }
                    });
            }
        }
    }
</script>
<style>

</style>
