import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import moment from "moment";

Vue.use(Vuex);

const statics = {
    namespaced: true,
    state: () => ({
        group_members: [],
        patients: [],
        procedures: [],
        stc_types: [],
        stc_list: [],
        mrc_codes: [],
        state_identifiers: [],
        vacc_batches: [],
        outbreak_codes: new Set(),
        filtered_tags: [],
        group_settings: [],
        statuses: [],
        claims_graph_data: {}

    }),
    mutations: {
        setGroupMembers(state, members) {
            state.group_members = members;
        },
        setGroupSettings(state, settings) {
            state.group_settings = settings;
        },
        setStateIdentifiers(state, identifiers) {
            state.state_identifiers = identifiers;
        },
        setSTCTypes(state, stc_types) {
            state.stc_types = stc_types
        },
        setMRC(state, mrc) {
            state.MRCs = mrc
        },
        setPatients(state, patients) {
            state.patients = patients
        },
        setProcedures(state, procedures) {
            state.procedures = procedures
        },
        setOutbreakCodes(state, codes) {
            state.outbreak_codes = codes
        },
        addToOutBreakCode(state, code) {
            state.outbreak_codes.add(code)
        },
        setVaccBatches(state, batches) {
            state.vacc_batches = batches
        },
        setSTCs(state, stcs) {
            state.stc_list = stcs
        },
        setFilteredTags(state, tags) {
            state.filtered_tags = tags
        },
        setStatuses(state, statuses) {
            state.statuses = statuses
        },
        setClaimsGraphData(state, data){
            state.claims_graph_data = data
        }
    },
    actions: {
        fetchGroupMembers({commit, state}) {
            axios.get('/getGroupMembers')
                .then(response => {
                    commit('setGroupMembers', response.data)
                })
                .catch(error => {
                    throw 'Unable to fetch group members'
                })
        },
        fetchGroupSettings({commit, state}) {
            axios.get('/getClientsGroupSettings')
                .then(response => {
                    commit('setGroupSettings', response.data)
                })
                .catch(error => {
                    throw 'Unable to fetch group settings'
                })
        },
        fetchStateIdentifiers({commit, state}) {
            axios.get('/getStateIdentifiers')
                .then(response => {
                    commit('setStateIdentifiers', response.data.StateArr)
                })
                .catch(error => {
                    throw 'Unable to fetch state identifiers'
                })
        },
        fetchSTCTypes({commit, state}) {
            axios.get('/getSTCTypes')
                .then(response => {
                    commit('setSTCTypes', response.data.STCArr)
                })
                .catch(error => {
                    throw 'Unable to fetch STC types'
                })
        },
        fetchMRC({commit, state}, vaccine_type = null) {
            axios.get('/getMRC', { params: vaccine_type })
                .then(response => {
                    let mrc_codes = response.data;
                    let filteredTags = mrc_codes.MRCArr;
                    commit('setMRC', response.data)
                    // Create the display field from the ID and Name concatenation
                    for (let i = 0; i < filteredTags.length; i++) {
                        if (filteredTags[i].code) {
                            filteredTags[i].display = filteredTags[i].code + ': ' + filteredTags[i].name;
                        } else {
                            filteredTags[i].display = filteredTags[i].name;
                        }
                    }
                    commit('setFilteredTags', filteredTags)
                })
                .catch(error => {
                    console.log(error);
                    throw 'Unable to fetch MRC data'
                })
        },
        fetchOutbreakCodes({commit, state}, gms = null) {
            axios.get('/outbreakCodes')
                .then(response => {
                    commit('setOutbreakCodes', response.data)
                    // this.outbreakCodes = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        fetchVaccBatches({commit, state}) {
            axios.get('/vaccBatches')
                .then(response => {
                    let receivedBatches = response.data;
                    // Filter the outbreak / public vaccines as theres loads
                    let outbreakCodes = new Set();
                    let filteredOutbreaks = response.data.publicHealthAdvised.filter((val, index, self) => {
                        let expDate = moment(val.expDate, 'YYYY-MM-DD');
                        let today = moment(new Date());
                        if (expDate.isBefore(today)) {
                            // Get rid of it
                            return false;
                        }

                        // Keep it
                        outbreakCodes.add(val.obIDOutbreak)

                        return index === self.findIndex((t) => (
                            t.name === val.name && t.batch === val.batch && t.obIDOutbreak === val.obIDOutbreak
                        ))
                    });
                    receivedBatches.publicHealthAdvised = filteredOutbreaks;
                    this.batches = receivedBatches;
                    commit('setVaccBatches', receivedBatches)
                    commit('setOutbreakCodes', outbreakCodes)
                })
                .catch(error => {
                    console.log(error);
                });
        },
        fetchSTC({commit, state}, type) {
            let t = type
            if(t == 'temporary') t = 'temp_visit'
            if(t == 'EU') t = 'default'
            axios.get('/services?type=' + t)
                .then(response => {
                    // Set defaults
                    commit('setSTCs', Object.values(response.data))
                    // this.services = Object.values(response.data);
                    // Set default stc selections for optional claim types
                    if (type === 'emergency') {
                        for (let i = 0; i < state.STCs.length; i++) {
                            let entry = this.services[i];
                            if (entry.symbol === 'No STC' && entry.subtype === 'Emergency') {
                                commit('setSTC', 'No STC - Emergency')
                                commit('setSTCObject', entry)
                                // this.select_stc = 'No STC - Emergency';
                                // this.select_stc_obj = entry;
                            }
                        }
                    } else if (type === 'temporary') {
                        for (let i = 0; i < state.STCs.length; i++) {
                            let entry = this.services[i];
                            if (entry.symbol === 'No STC' && entry.subtype === 'Temporary Resident') {
                                commit('setSTC', 'No STC - Temporary Resident')
                                commit('setSTCObject', entry)
                                // this.select_stc = 'No STC - Temporary Resident';
                                // this.select_stc_obj = entry;
                            }
                        }
                    }
                    this.loading_stc = false;

                })
                .catch(error => {
                    console.log(error);
                    this.loading_stc = false;
                });
        },
        fetchStatuses({commit, state}) {
            axios.get('/getStatuses').then(response => {
                let exclude = [1,6,9,11,12,14];
                let statuses = Object.values(response.data).filter((el) => {
                    if(exclude.indexOf(el.id) === -1) {
                        return el;
                    }
                });
                commit('setStatuses', Object.values(statuses));
            }).catch(error => {
                console.log(error);
            });
        },
        fetchClaimsGraphData({commit, state}) {
            axios.get('/fetchGraphData').then(response => {
                let graphData =response.data
                commit('setClaimsGraphData', graphData);
            }).catch(error => {
                console.log(error);
            });
        }
    },
    getters: {}
}

const defaultPrivateClaimState = {
    pendingClaim: null,
}
function checkForUpdate(array, key, value){
    for (let i = 0; i < array.length; i++){
        if (array[i].key === key){
            // Due to limitations in JavaScript, Vue cannot detect the following changes
            // to an array if it updates an existing index:
            // array[i].value = value;
            // It must be spliced in at the index to be picked up
            let element = array[i];
            element.value = value;
            array.splice(i, 1, element)
            return true;
        }
        if(array[i].dependent !== null){
            if(checkForUpdate(array[i].dependent, key, value)){
                return true;
            }
        }
    }
    return false;
}
const private_claims = {
    namespaced: false,
    state: defaultPrivateClaimState,
    mutations: {
        resetState(state) {
            Object.assign(state, defaultState)
        },
        setPendingClaim(state, claim) {
            state.pendingClaim = claim;
        },
        updatePrefilledClaim(state, update) {
            let key = update.key;
            let value = update.value;
            // console.log("Array: " + state.pendingClaim.prefilled[0]);
            // console.log("Key: " + key);
            // console.log("Value: " + value);
            let res = checkForUpdate(state.pendingClaim.prefilled[0], key, value);
            // if (res) {
            //     let stringClaim = state.pendingClaim.prefilled;
            //     axios.post('/updateClaim', {
            //         claim: stringClaim,
            //         id: state.pendingClaim.id,
            //     }).then(res => {
            //         // console.log(res);
            //     }).catch(err => {
            //         console.log(err);
            //     });
            // }
        },
        attachSignature(state, signature) {
            state.pendingClaim.signature = signature;
        },
    },
    actions: {
        // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
        reset({commit}) {
            commit('resetState')
        },
        setPendingClaim({commit}, claim) {
            commit('setPendingClaim', claim);
        },
        updatePendingClaim({commit}, update) {
            commit('updatePrefilledClaim', update);
        },
        attachSignature({commit, state}, signature) {
        	commit('attachSignature', signature);
        },
    },
    getters: {
        isInjury: state => {
            for (let i = 0; i < state.pendingClaim.prefilled[0].length; i++) {
                let entry = state.pendingClaim.prefilled[0][i];
                if (entry.key === 'injury' && typeof entry.value !== 'undefined' && entry.value !== null && entry.value !== '' && entry.value !== false) {
                    return true;
                }
            }
            return false;
        },
        requiredKeys: state => {
            let keys = [];
            for (let i = 0; i < state.pendingClaim.prefilled[0].length; i++) {
                let e = state.pendingClaim.prefilled[0][i];
                if (e.mandatory) {
                    keys.push(i);
                }
            }
            return keys;
        },
        claimValidity: state => {
            let validKeys = [];
            let invalidKeys = [];
            let requiredKeys = [];
            let valid = true;
            for (let i = 0; i < state.pendingClaim.prefilled[0].length; i++) {
                let e = state.pendingClaim.prefilled[0][i];
                if (e.mandatory) {
                    if (typeof e.value !== 'undefined' && e.value !== null && e.value !== '') {
                        // Valid
                        validKeys.push(i);
                    } else {
                        // Invalid
                        valid = false;
                        invalidKeys.push({
                            'index': i,
                            key: e.key
                        });
                    }
                    requiredKeys.push(i);
                } else if (e.type === 'check' && e.value === true && e.dependent !== null) {
                    for (let j = 0; j < e.dependent.length; j++) {
                        let f = e.dependent[j];
                        if(f.type === 'check' && typeof f.value === 'undefined') {
                            f.value = 'false';
                        }
                        if (typeof f.value !== 'undefined' && f.value !== null && f.value !== '') {
                            // Valid
                            validKeys.push(j);
                        } else {
                            // Invalid
                            valid = false;
                            invalidKeys.push({
                                'index': j,
                                key: f.key
                            });
                        }
                        if (f.type === 'check' && f.value === true && f.dependent !== null) {
                            for (let k = 0; k < f.dependent.length; k++) {
                                let g = f.dependent[k];
                                if (typeof g.value !== 'undefined' && g.value !== null && g.value !== '') {
                                    // Valid
                                    validKeys.push(k);
                                } else {
                                    // Invalid
                                    valid = false;
                                    invalidKeys.push({
                                        'index': k,
                                        key: g.key
                                    });
                                }
                            }
                        }
                    }
                }
            }
            return {
                valid,
                validKeys,
                invalidKeys,
                requiredKeys
            };
        },
    }
}

const defaultState = {
    count: 0,
    select_pat: '', // Text value to store value to enter into autocomplete box. Do not attach functionality to this
    select_pat_obj: {cardno: '', pps:'', id: null, fullName: '', dob: ''}, // Global patient object
    select_stc: '',
    select_stc_obj: '',
    signature: '', // Global signature, taken from any source
    signature_id: '', // Global signature ID, taken from any source, used instead of signature when recieved from remote device
    sig_taken: false, // Boolean for easier infix testing of signature presence
    private_insurer: 0,
    select_procedure: '',
    select_procedure_obj: {code: '', description: ''},
    private_claim_doctor: '',
    claim_graph_data: {},
    claim_date: '',
}

const store = new Vuex.Store({
    modules: {
        statics: statics,
        private_claims: private_claims
    },
    state: defaultState,
    mutations: {
        setSigTaken(state, value){
            state.sig_taken = value
        },
        setSignature(state, sig){
            state.signature = sig
        },
        setSignatureID(state, sig_id){
            state.signature_id = sig_id
        },
        increment(state) {
            state.count++
        },
        setPatient(state, patient) {
            state.select_pat = patient.fullName;
            state.select_pat_obj = patient;
        },
        setPatientText(state, patient) {
        	state.select_pat = patient;
        },
        setProcedure(state, procedure) {
            state.select_procedure = procedure.code;
            state.select_procedure_obj = procedure;
        },
        setProcedureText(state, procedure) {
        	state.select_procedure = procedure;
        },
        setSTC(state, stc) {
            state.select_stc = stc;
        },
        setSTCObject(state, stc) {
            state.select_stc_obj = stc;
        },
        setPrivateInsurer(state, insurer) {
        	state.private_insurer = insurer;
        },
        setPrivateClaimDoctor(state, doctor) {
            state.private_claim_doctor = doctor;
        },
        setClaimDate(state, data) {
            state.claim_date = data
        }
    },
    actions: {
        increment({commit, state}) {
            commit('increment');
        },
        setPatient({commit, state}, patient) {
            commit('setPatient', patient);
        },
        setPatientText({commit, state}, patient) {
        	commit('setPatientText', patient);
        },
        setSTC({commit, state}, stc) {
            commit('setSTC', stc)
        },
        setSTCObject({commit, state}, stc) {
            commit('setSTCObject', stc)
        },
        setSignature({commit, state}, sig){
            commit('setSignature', sig)
        },
        setSignatureID({commit, state}, sig_id){
            commit('setSignatureID', sig_id)
        },
        setSigTaken({commit, state}, value){
            commit('setSigTaken', value)
        },
        setPrivateInsurer({commit, state}, value) {
        	commit('setPrivateInsurer', value)
        },
        setProcedure({commit, state}, procedure) {
            commit('setProcedure', procedure);
        },
        setProcedureText({commit, state}, procedure) {
        	commit('setProcedureText', procedure);
        },
        setPrivateClaimDoctor({commit, state}, doctor) {
            commit('setPrivateClaimDoctor', doctor);
        },
        setClaimDate({commit, state}, claimdate) {
            commit('setClaimDate', claimdate);
        },
    },
    getters: {
        getCount: state => {
            return state.count;
        },

    }
})

export default store;
