<template>
    <div>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Add New Patient</p>
            </header>
            <section class="modal-card-body">
                <div v-if="error">
                    <b-message title="Error Verifying Patient" type="is-danger" aria-close-label="Close message">
                        <ul>
                            <li v-for="err in error">
                                {{ err[0] }}
                            </li>
                        </ul>
                    </b-message>
                    <hr>
                </div>
                <b-loading :is-full-page="true" :active.sync="loading"></b-loading>
                <b-field v-if="this.type !== 'pps' && this.type !== 'maternity'" label="GMS Number">
                    <b-input placeholder="755990" v-model="form.gms"></b-input>
                </b-field>
                <b-field v-else label="PPS Number">
                    <b-input placeholder="0118999T" v-model="form.pps"></b-input>
                </b-field>
                <div class="has-text-centered">
                    <b-button size="is-medium" type="is-primary" @click="checkGMS">Check Patient</b-button>
                </div>

                <div v-if="patient_fetched">
                    <hr>
                    <div class="box">
                        <div class="has-text-centered">
                            <div v-if="this.patient.validity === 'Valid' && this.pps_require_more_data !== true">
                                <div class="columns is-desktop is-vcentered">
                                    <div class="column is-one-quarter">
                                        <div class="tag is-success is-large">
                                            Eligible Patient
                                        </div>
                                    </div>
                                    <div class="column is-three-fifths">
                                        <p>Please confirm the below details with the patient and proceed to register. Name fields are optional</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="this.pps_require_more_data === true">

                                <div class="tag is-danger is-large">
                                    Additional patient info is required for submission
                                </div>
                                <div class="columns is-centered">
                                    <b-field grouped>
                                        <b-field label="First Name" expanded>
                                            <b-input required v-model="patient.first_name" icon="user" placeholder="Jane"></b-input>
                                        </b-field>
                                        <b-field label="Last Name" expanded>
                                            <b-input required v-model="patient.last_name" icon="user" placeholder="Doe"></b-input>
                                        </b-field>
                                    </b-field>
                                </div>
                                <div class="columns is-centered">
                                    <b-field grouped>
                                        <b-field label="Sex">
                                            <b-select required v-model="patient.sex" placeholder="Select sex">
                                                <option value="F">Female</option>
                                                <option value="M">Male</option>
                                            </b-select>
                                        </b-field>
                                        <b-field label="Date of Birth">
                                            <b-datepicker
                                                icon="birthday-cake"
                                                placeholder="DD/MM/YYYY"
                                                :max-date="new Date()"
                                                v-model="patient.pat_dob"
                                                editable>
                                            </b-datepicker>
                                        </b-field>
                                    </b-field>
                                </div>
                                <div class="columns is-centered">
                                    <b-field grouped>
                                        <b-field label="Address line 1" expanded>
                                            <b-input required maxlength="200" v-model="patient.addr1"></b-input>
                                        </b-field>
                                        <b-field label="Address line 2" expanded>
                                            <b-input maxlength="200" v-model="patient.addr2"></b-input>
                                        </b-field>
                                    </b-field>
                                </div>
                                <div class="columns is-centered">
                                    <b-field grouped>
                                        <b-field label="Town" expanded>
                                            <b-input required maxlength="200" v-model="patient.town"></b-input>
                                        </b-field>
                                        <b-field label="County">
                                            <b-select required v-model="patient.county" placeholder="County">
                                                <option value="antrim">Antrim</option>
                                                <option value="armagh">Armagh</option>
                                                <option value="carlow">Carlow</option>
                                                <option value="cavan">Cavan</option>
                                                <option value="clare">Clare</option>
                                                <option value="cork">Cork</option>
                                                <option value="derry">Derry</option>
                                                <option value="donegal">Donegal</option>
                                                <option value="down">Down</option>
                                                <option value="dublin">Dublin</option>
                                                <option value="fermanagh">Fermanagh</option>
                                                <option value="galway">Galway</option>
                                                <option value="kerry">Kerry</option>
                                                <option value="kildare">Kildare</option>
                                                <option value="kilkenny">Kilkenny</option>
                                                <option value="laois">Laois</option>
                                                <option value="leitrim">Leitrim</option>
                                                <option value="limerick">Limerick</option>
                                                <option value="longford">Longford</option>
                                                <option value="louth">Louth</option>
                                                <option value="mayo">Mayo</option>
                                                <option value="meath">Meath</option>
                                                <option value="monaghan">Monaghan</option>
                                                <option value="offaly">Offaly</option>
                                                <option value="roscommon">Roscommon</option>
                                                <option value="sligo">Sligo</option>
                                                <option value="tipperary">Tipperary</option>
                                                <option value="tyrone">Tyrone</option>
                                                <option value="waterford">Waterford</option>
                                                <option value="westmeath">Westmeath</option>
                                                <option value="wexford">Wexford</option>
                                                <option value="wicklow">Wicklow</option>
                                            </b-select>
                                        </b-field>
                                    </b-field>
                                </div>
                            </div>

                            <div v-else>
                                <div class="columns is-centered">
                                    <div class="column"></div>
                                    <div class="column is-half">
                                        <div class="tag is-danger is-large">
                                            Ineligible Patient
                                        </div>
                                        <p>This card number is not eligible for HSE claims.</p>
                                    </div>
                                    <div class="column"></div>
                                </div>
                            </div>
                            <div v-if="this.pps_require_more_data === false">
                                <hr>
                                <table class="table is-fullwidth has-text-centered">
                                    <thead>
                                    <th>Patient DOB</th>
                                    <th>Current GP</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Scheme Type</th>
                                    </thead>
                                    <tbody>
                                    <td>{{ patient.pat_dob }}</td>
                                    <td>{{ patient.doctor_name }}</td>
                                    <td>{{ patient.start_date }}</td>
                                    <td>{{ patient.end_date }}</td>
                                    <td>{{ patient.scheme_type }}</td>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div v-if="this.pps_require_more_data === false">
                        <b-field grouped>
                            <b-field label="First Name" expanded>
                                <b-input v-model="patient.first_name" icon="user" placeholder="Jane"></b-input>
                            </b-field>
                            <b-field label="Last Name" expanded>
                                <b-input v-model="patient.last_name" icon="user" placeholder="Doe"></b-input>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="container">
                    <div v-if="this.notValidPatient === true" class="">
                        
                        <div class="notification is-danger is-large">
                            Patient GMS could NOT be verified, proceed with caution
                        </div>
                        <hr>
                    </div>

                    <div v-if="this.allowProceed()" class="">
                        <div class="has-text-centered">
                            <button :disabled="form_debounce" class="button is-large is-primary" @click="precheckForm">Proceed with Patient</button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    props: ['gms', 'pps', 'type'],
    data() {
        return {
            pps_require_more_data: false,
            patient_fetched: false,
            loading: false,
            error: false,
            patient: {
                validity: null,
                pat_name: null,
                first_name: null,
                last_name: null,
                pat_dob: null,
                addr1: null,
                addr2: null,
                town: null,
                county: null,
                address: null,
                pps: null
            },
            form: {
                gms_number: null,
                pps: null
            },
            submitStatus: null,
            form_debounce: false,
            notValidPatient: false,
        };
    },
    methods: {
        allowProceed() {
            if(this.patient.validity === 'Valid' || this.pps_require_more_data === true){
                this.notValidPatient = false;
                return true
            } else if (this.patient_fetched == true && this.patient.validity !== 'Valid') {
                this.notValidPatient = true
                return true
            } else {
                return false
            }
        },
        ppsn(ppsn) {
            ppsn = ppsn.trim();
            let regex = /^[0-9]{7}[A-Z]{1,2}$/;
            if (ppsn.match(regex)) {
                return true;
            }
            regex = /^[0-9]{7}[A-Z][\ WTX]?$/;
            if (ppsn.match(regex)) {
                return true;
            }
            return false;
        },
        checkGMS() {
            let postdata = {}
            let checkURL = '/check-gms'
            this.loading = true;
            this.error = false;
            this.pps_require_more_data = false
            if(this.type === 'pps' || this.type === 'maternity') {
                if (this.form.pps === null || !this.ppsn(this.form.pps)) {
                    this.flashMessage('Please enter a correct PPS number', 'is-danger')
                    this.loading = false;
                    return
                }
                checkURL = '/check-pps'
                postdata.pps = this.form.pps
            } else {
                postdata.gms_number = this.form.gms
            }
            axios.post(checkURL, postdata)
                .then(res => {
                    this.loading = false;
                    this.patient_fetched = true;
                    this.patient = res.data;
                    this.patient.pat_dob = this.fixDate(this.patient.pat_dob)
                    let found_address = JSON.parse(this.patient.address)
                    let patname = ''
                    if(this.patient.pat_name.includes('\n')) {
                        patname = this.patient.pat_name.split('\n')
                    } else {
                        patname = this.patient.pat_name.split(' ')
                    }
                    this.patient.addr1 = found_address.addr1
                    this.patient.addr2 = found_address.addr2
                    this.patient.town = found_address.town
                    this.patient.county = found_address.county.toLowerCase()
                    //because there's so many Dublin addresses... I'm yoloing this to a single one
                    if(this.patient.county.toLowerCase().includes('dublin')){
                        this.patient.county = 'dublin'
                    }
                    this.patient.first_name = patname[0].trim();
                    this.patient.last_name = patname[1].trim();
                })
                .catch(err => {
                    this.error = err.response.data.errors;
                    this.requirePPSData(postdata.pps)
                    this.loading = false;
                    this.patient_fetched = false;
                }).finally(() => {
                    this.requirePPSData(postdata.pps)
            })
        },
        requirePPSData( pps ) {
            if(this.type === 'pps' || this.type === 'maternity') {
                this.fetchPatient(pps, this.type)
                this.pps_require_more_data = true
            } else {
                this.pps_require_more_data = false
            }
        },
        fixDate(date, split = "/", join = '-') {
            return new Date(this.patient.pat_dob.split(split).reverse().join(join))
        },
        fetchPatient(num, type) {
            if(!this.ppsn(num)) return
            this.loading = true;
            axios.get(`/patients?search=${num}&type=${type}`)
                .then(({ data }) => {
                    let patients = Object.values(data.data)
                    if(!jQuery.isEmptyObject(patients)) {
                        patients.forEach((item) => {
                            this.patient.first_name = item.fName
                            this.patient.last_name = item.lname
                            this.patient.sex = item.sex
                            if(item.dob !== '' && item.dob !== null) {
                                this.patient.pat_dob = this.fixDate(item.dob)
                            }
                        })
                    }
                })
                .catch((error) => {
                    throw error
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        flashMessage(message, type) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                position: 'is-bottom',
            })
        },
        precheckForm() {
            if(this.pps_require_more_data === true || this.notValidPatient === true) {
                if(typeof this.patient.last_name === 'undefined' || this.patient.last_name === '' || typeof this.patient.first_name === 'undefined' || this.patient.first_name === '') {
                    this.flashMessage('Please fill first and last name fields','is-danger')
                } else {
                    this.submitForm()
                }
            } else {
                this.submitForm()
            }
        },
        submitForm() {
            if(!this.form_debounce){
                this.form_debounce = true;
                console.log(this.patient.pat_dob);
                let body = {
                    "firstName": this.patient.first_name,
                    "lastName": this.patient.last_name,
                    "dob": this.patient.pat_dob ? moment(this.patient.pat_dob, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
                    "pps": this.form.pps,
                    "sex": this.patient.sex,
                    "addr1": this.patient.addr1,
                    "addr2": this.patient.addr2,
                    "town": this.patient.town,
                    "county": this.patient.county,
                    "cardno": this.patient.scheme_id,
                    "dr_number": this.patient.doctor_num,
                    "start_date": this.patient.start_date ? moment(this.patient.start_date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
                    "end_date": this.patient.end_date ? moment(this.patient.end_date, 'DD/MM/YYYY').format('DD/MM/YYYY') : null,
                    'validity': this.patient.validity,
                };
                axios({
                    method: 'post',
                    url: '/newPatStatic',
                    data: body
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.flashMessage('New Patient Created', 'is-success');
                            this.$emit('new-patient', response.data.key);
                            this.resetForm();
                            this.$parent.close();
                        } else if (response.status === 206) {
                            // Off panel patient
                            this.flashMessage('Off Panel Patient', 'is-primary')
                        }
                        this.form_debounce = false;
                        this.pps_require_more_data = false
                    })
                    .catch(error => {
                        if (error.message === 'Request failed with status code 422') {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else if(error.message === 'Server ErrorNetwork Error'){
                            this.flashMessage('No internet connection. Please check your connection', 'is-danger')
                        } else {
                            this.flashMessage('Server Error' + error.message, 'is-danger');
                        }
                        this.form_debounce = false;
                    });
            }
        },
        resetForm(){
            this.patient = null;
            this.form = null;

            this.patient = {
                validity: null,
            };
            this.form = {
                gms: null,
                pps: null,
                first_name: '',
                last_name: '',
            };
        }
    },
    mounted() {
        // If the prop is a valid GMS number then auto fill in and search
        if(this.gms.match(/\d{5,10}[A-Za-z]/)){
            this.form.gms = this.gms;
            this.checkGMS();
        } else if(this.gms.match(/\d+/)){
            this.form.gms = this.gms;
        }

        if(this.ppsn(this.pps)) {
            this.form.pps = this.pps;
            this.checkGMS();
        }
    }
};
</script>

<style scoped>

</style>
