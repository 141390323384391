<template>
    <div class="container">
        <div class="box">
            <div class="columns">
                <div class="column">
                    <div class="title">Patients Information List</div>
                </div>
                <div class="column">
                    <div class="is-pulled-right">
                        <div class="button is-medium is-primary" @click="refresh">Refresh</div>
                    </div>
                    <div class="is-pulled-right">
                        <JsonExcel
                            class="button is-medium is-primary"
                            :data="excelExport"
                            worksheet="My Worksheet"
                            type="xls"
                            name="PatientsListExport.xls"
                            >
                            Download Excel
                        </JsonExcel>
                    </div>
                </div>
            </div>
           <template>
            <div class="columns is-tablet">
                <div class='column is-4'>
                    <b-field label="Search First Name">
                        <b-input v-model="search_query.firstName" placeholder="Enter patients first name" icon="search" icon-pack="fas"/>
                    </b-field>
                    <b-dropdown
                            :scrollable="isScrollable"
                            :max-height="maxHeight"
                            v-model="search_query.doctor"
                            aria-role="list"
                    >
                        <template #trigger>
                            <b-button
                                @click="getDocs()"
                                :label="search_query.doctor ?  search_query.doctor.firstname : 'Doctors List' "
                                type="is-primary" />
                        </template>
                        
                        <b-dropdown-item
                            v-for="(doc, index) in docs"
                            :key="index"
                            :value="doc" aria-role="listitem">
                            <div class="media">
                                <div class="media-content" >
                                    <h3>{{ doc.prefix}} {{ doc.firstname }} {{ doc.lastname }} </h3>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                            :scrollable="isScrollable"
                            :max-height="maxHeight"
                            v-model="search_query.group"
                            aria-role="list"
                    >
                        <template #trigger>
                            <b-button
                                @click="getGroups()"
                                :label="search_query.group ?  search_query.group.name : 'Group List' "
                                type="is-primary" />
                        </template>

                        <b-dropdown-item
                            v-for="(g, index) in groups"
                            :key="index"
                            :value="g" aria-role="listitem">
                            <div class="media">
                                <b-icon class="media-left" ></b-icon>
                                <div class="media-content"  >
                                    <h3>{{ g.name }} </h3>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="column is-4">
                    <b-field label="Search Last Name">
                        <b-input v-model="search_query.lastName" placeholder="Enter patients last name" icon="search" icon-pack="fas"/>
                    </b-field>
                    
                </div>       
                <div class="column is-3">
                    
                    <b-field label="Number of results">
                        <b-numberinput v-model="pagi" placeholder="100" :min="1"></b-numberinput>
                    </b-field>
                    <b-button type="is-success is-pulled-right" @click="search()">Search</b-button>
                    <b-button type="is-warning is-pulled-right" @click="clearSearch()">Clear</b-button>
                </div>   
            </div> 
            </template>
			<div v-if="this.loaded == undefined || this.loaded == false" class="columns is-centered">
            	<div class="column is-narrow">
            		<div class="has-text-centered">
            			<div class="spring-spinner">
                        	<div class="spring-spinner-part top">
                            	<div class="spring-spinner-rotator"></div>
                            </div>
                            <div class="spring-spinner-part bottom">
                                <div class="spring-spinner-rotator"></div>
                            </div>
                        </div>
	            	</div>
	            </div>
	    	</div>
			<div v-if="this.loaded">
                <b-table 
                    :data="isEmpty ? [] : data"
                    :paginated="true"
                    :current-page="pagination.current_page"
                    backend-pagination
                    :per-page="pagination.per_page"
                    :total="pagination.total"
                    @page-change="getData"
                    detailed
                    detailed-key="id"
                    hoverable>
                <template slot-scope="props">
                    
                    <b-table-column
                        field="first_name"
                        label="first Name"
                    >
                        {{ props.row.first_name }}
                    </b-table-column> 

                    <b-table-column
                        field="last_name"
                        label="Last Name"
                    >
                        {{ props.row.last_name }}
                    </b-table-column> 

                    <b-table-column
                        field="dob"
                        label="DOB"
                    >
                        {{ props.row.dob }}
                    </b-table-column> 

                    <b-table-column
                        field="pps"
                        label="PPS Number"
                    >
                        {{ props.row.pps }}
                    </b-table-column> 
                                            
                    <b-table-column
                        field="email"
                        label="E-Mail"
                    >
                        {{ props.row.email }}
                    </b-table-column>

                    <b-table-column
                        field="phone"
                        label="Phone Number"
                    >
                        {{ props.row.phone }}
                    </b-table-column>

                </template>
                <template slot="detail" slot-scope="props">
                        <tr>
                            <th>Doctor</th>
                            <th>Group</th>
                        </tr>
                        <tr v-for="client in props.row.clients" :key="client.id" >
                            <td field="sold" label="Doctors">&nbsp;&nbsp;
                                {{ client.id}}: {{ client.firstname }} {{ client.lastname }}
                            </td>
                            <td field="sold" label="Groups">&nbsp;&nbsp;
                                {{ client.group.name  === null ? 'N/A' : client.group.name }}
                            </td>
                        </tr>
                </template>
                
                </b-table>
                <span>Total Records: {{ pagination.total }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import JsonExcel from "vue-json-excel";
    let axios = require('axios');
    export default {
        components: {
            JsonExcel,
        },
        props: [],
        data() {
            return {
               loaded: true,
                data: {},
                docs: {},
                groups: {},
                excelExport: {},
                isPaginated: true,
                search_query: {
                    group: null,
                    doctor: null,
                    firstName: null,
                    lastName: null,
                },
                docIDS: null,
                celExport: {},
                pagination: {},
                page: 1,
                isScrollable: true,
                maxHeight: 500,
                loaded: true,
                param: null,
                pagi: 25,
            };
        },
        methods: {
            
        	getData(page ) {
                this.page = page;
        		this.loaded = false;
                axios.get(`/admin/AdminReturnAllPatients?page=${page}`, {params: this.param}) 
                    .then(response => {
                    this.data = response.data[0].data;
                    this.pagination = {
                        current_page: response.data[0].current_page,
                        total: response.data[0].total,
                        per_page: response.data[0].per_page,
                    }
                    this.loaded = true;
                    this.excelExport = response.data[1];
                    })
                .catch(error => {
                	console.log(error);
                    this.flashMessage('Error getting patinets', 'is-danger')
                })
        	},
            getDocs(){
                axios.get(`/admin/getClientList`) 
                    .then(response => {
                    this.docs = response.data; 
                    })
                .catch(error => {
                	console.log(error);
                    this.flashMessage('Error getting doctor list', 'is-danger')
                })
            },
            getGroups(){
                axios.get(`/admin/getGroupList`) 
                    .then(response => {
                    this.groups = response.data;
                    })
                .catch(error => {
                	console.log(error);
                    this.flashMessage('Error getting groups list', 'is-danger')
                })
            },
            refresh(){
                location.reload();
            },
            flashMessage(message, type){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
            search(){
                let doc = this.search_query.doctor ? this.search_query.doctor.id : null;
                let group = this.search_query.group ? this.search_query.group.id : null;
                let first_name = this.search_query.firstName;
                let last_name = this.search_query.lastName;
                let pagi = this.pagi;
                this.param = {doc, group, first_name, last_name, pagi}

                this.getData(this.page, this.param);
            },
            clearSearch(){
                this.search_query.doctor = null;
                this.search_query.group = null;
                this.search_query.firstName = null;
                this.search_query.lastName = null;
                this.param = null;
            }
        },
        mounted() {
            this.getData();
        }
    };
</script>

<style scoped>
    .container {
        margin-top: 50px;
    }
    @media only screen and (max-width: 800px) {
        .container {
            margin-top: 3px;
        }
    }
    .button {
            margin: 1px;
    }
    .box {
        margin-top: 10px;
    }

    .is-horizontal >>> .field-label{
        text-align: left;
    }

</style>
