
// Loader logic

export default {
	init() {
		//void
	},
	finalize() {
    	//void
	},
};

window.addEventListener('DOMContentLoaded', function(event) {
    document.getElementById('loader').style.display = 'none';
});

window.addEventListener('pageshow', function(event) {
    document.getElementById('loader').style.display = 'none';
});

window.addEventListener('beforeunload', function(event) {
    document.getElementById('loader').style.display = 'inline-block';
});
