<template>
    <section>
        <div class="">
            <div v-if="this.user.isPasswordExpired === true">
                <div class="label" style="color:red;">Expired PCRS password</div>
                <b-button
                    class="button is-danger"
                    @click="passwordExpiredModal"
                    size="is-medium">Update password
                </b-button>
            </div>

            <div v-if="type === 'EU'">
                <div class="label">EU Patient Details</div>
                <hr>
                <b-field grouped>
                    <b-field expanded>
                        <template #label>
                            Name <small class="has-text-danger">* required</small>
                        </template>
                        <b-input icon="user" expanded v-model="extra.ec_firstname"></b-input>
                    </b-field>
                    <b-field expanded>
                        <template #label>
                            Last name <small class="has-text-danger">* required</small>
                        </template>
                        <b-input icon="signature" expanded v-model="extra.ec_lastname"></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field>
                        <template #label>
                            Date of Birth <small class="has-text-danger">* required</small>
                        </template>
                        <b-datepicker
                            icon="birthday-cake"
                            placeholder="Patient's DOB"
                            v-model="extra.ec_dob"
                            required>
                        </b-datepicker>
                    </b-field>
                    <b-field expanded class="is-half">
                        <template #label>
                            Personal Identification Number <small class="has-text-danger">* required</small>
                        </template>
                        <b-input expanded icon="fingerprint" v-model="extra.ec_personal_id_nr"></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field expanded class="is-half">
                        <template #label>
                            Identification Number of the Institution <small class="has-text-danger">* required</small>
                        </template>
                        <b-input expanded icon="address-card" v-model="extra.ec_institution_nr"></b-input>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field expanded class="is-half">
                        <template #label>
                            Identification Number of the card <small class="has-text-danger">* required</small>
                        </template>
                        <b-input expanded icon="id-card" v-model="extra.ec_id_nr" ></b-input>
                    </b-field>
                    <b-field expanded class="is-half">
                        <template #label>
                            Card Expiry Date <small class="has-text-danger">* required</small>
                        </template>
                        <b-datepicker
                            expanded
                            placeholder="Expiry Date"
                            v-model="extra.ec_exp_date"
                            icon="calendar"
                            required>
                        </b-datepicker>
                    </b-field>
                </b-field>

                <b-field grouped>
                    <b-field expanded label="Prescription Serial Number">
                        <b-input icon="hashtag" expanded v-model="extra.ec_prescr_nr"></b-input>
                    </b-field>
                    <b-field expanded label="State Identifier">
                        <template #label>
                            State Identifier <small class="has-text-danger">* required</small>
                        </template>
                        <b-select icon="globe" expanded v-model="extra.ec_state_id" >
                            <option
                                v-for="option in state_identifiers"
                                :value="option.id"
                                :key="option.name"
                            >
                                {{ option.id + ' - ' + option.name }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>
            </div>
            <div v-else-if="type === 'pps'">
                <div class="label">PPSN Patient Select</div>
                <b-field>
                    <b-autocomplete
                        v-model="select_pat"
                        ref="patautocomplete"
                        size="is-medium"
                        :data="asyncPatients"
                        :loading="loading_patients"
                        @keyup.enter.native="getAsyncData"
                        field="fullName"
                        placeholder="Find a Patient (Search by name / PPS number)"
                        icon="user-injured"
                        clearable
                        open-on-focus
                        @select="selectPatient" expanded>
                        <template slot-scope="props">
                            <div class="media is-size-5-touch">
                                <div class="media-content has-text-left">
                                    {{ props.option.fullName }}
                                    <br>
                                    <small>
                                        <b>DOB: </b>{{ props.option.DOB }}
                                        <b>PPSN: </b> {{ props.option.PPS }}
                                    </small>
                                </div>
                            </div>
                        </template>
                        <template slot="empty">
                            <div v-if="loading_patients">
                                <div class="columns is-centered">
                                    <div class="column is-narrow">
                                        <div class="has-text-centered">
                                            <div class="spring-spinner">
                                                <div class="spring-spinner-part top">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                                <div class="spring-spinner-part bottom">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No patients Found
                            </div>
                        </template>
                    </b-autocomplete>
                    <p class="control">
                        <b-button
                            class="button is-primary"
                            @click="getAsyncData"
                            size="is-medium">Search
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                            class="button is-secondary"
                            @click="addPatient"
                            size="is-medium" grouped>
                            <span> Add New Patient </span>
                        </b-button>
                    </p>
                </b-field>
                <div v-if="pps_require_more_data === 1" >
                    <div class="tile is-ancestor">
                        <b-field grouped class="tile is-parent">
                            <b-field label="Patient First Name" class="tile is-child">
                                <b-input size="is-medium" v-model="pps_details.pps_first_name"></b-input>
                            </b-field>
                            <b-field label="Patient Last Name" class="tile is-child">
                                <b-input size="is-medium" v-model="pps_details.pps_last_name"></b-input>
                            </b-field>
                            <b-field label="Patient DOB" class="tile is-child">
                                <b-input size="is-medium" v-model="pps_details.pps_dob"></b-input>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </div>

            <div v-else-if="type === 'maternity'">
                <div class="label">Mother's PPS Select</div>
                <b-field>
                    <b-autocomplete
                        v-model="select_pat"
                        ref="patautocomplete"
                        size="is-medium"
                        :data="asyncPatients"
                        :loading="loading_patients"
                        @keyup.enter.native="getAsyncData"
                        field="fullName"
                        placeholder="Find a Patient (Search by name / PPS number)"
                        icon="user-injured"
                        clearable
                        open-on-focus
                        @select="selectPatient" expanded>
                        <template slot-scope="props">
                            <div class="media is-size-5-touch">
                                <div class="media-content has-text-left">
                                    {{ props.option.fullName }}
                                    <br>
                                    <small>
                                        <b>DOB: </b>{{ props.option.DOB }}
                                        <b>PPSN: </b> {{ props.option.PPS }}
                                    </small>
                                </div>
                            </div>
                        </template>
                        <template slot="empty">
                            <div v-if="loading_patients">
                                <div class="columns is-centered">
                                    <div class="column is-narrow">
                                        <div class="has-text-centered">
                                            <div class="spring-spinner">
                                                <div class="spring-spinner-part top">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                                <div class="spring-spinner-part bottom">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No patients Found
                            </div>
                        </template>
                    </b-autocomplete>
                    <p class="control">
                        <b-button
                            class="button is-primary"
                            @click="getAsyncData"
                            size="is-medium">Search
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                            class="button is-secondary"
                            @click="addPatient"
                            size="is-medium" grouped>
                            <span> Add New Patient </span>
                        </b-button>
                    </p>
                </b-field>

                <b-field v-if="select_pat_obj.id != null" label="Select Claim ID">
                    <b-select placeholder="Select Claim" expanded size="is-medium"
                              @input="matClaimSelected($event)"
                              v-model="selected_mat_claim_obj">
                        <option :value=0 :key="0" selected>New Claim</option>
                        <option
                            v-for="option in pat_mat_claims"
                            :value="option"
                            :key="option.pcrs_id">
                            ID - {{ option.pcrs_id }}; Visits - {{ option.v_num }}; Reg. Date - {{ option.reg_date }}; Exp. Delivery - {{ option.exp_delivery }}
                        </option>
                    </b-select>
                </b-field>

                <b-field label="Select visit type">
                    <b-select icon="stethoscope" placeholder="Select visit type" expanded size="is-medium"
                              v-model="maternity.visit_type">
                        <option value="antenatal">Antenatal Visit</option>
                        <option value="additional">Additional Visit</option>
                        <option value="postnatal">Postnatal Visit</option>
                    </b-select>
                </b-field>
                <hr>

                <div v-if="maternity.visit_type !== null">

                    <div class="columns">
                        <b-field class="column" label="Claim Date">
                            <b-datepicker
                                icon="calendar"
                                placeholder="DD/MM/YYYY"
                                v-model="maternity.claim_date">
                            </b-datepicker>
                        </b-field>
                        <b-field v-if="maternity.visit_type === 'antenatal'" class="column" label="Registration Date">
                            <b-datepicker
                                icon="calendar"
                                placeholder="DD/MM/YYYY"
                                v-model="maternity.registration_date">
                            </b-datepicker>
                        </b-field>
                        <b-field class="column" v-else><p>&nbsp;</p></b-field>
                        <b-field v-if="maternity.visit_type === 'antenatal'" class="column" label="Expected delivery date">
                            <b-datepicker
                                icon="calendar"
                                placeholder="DD/MM/YYYY"
                                v-model="maternity.expected_delivery_date">
                            </b-datepicker>
                        </b-field>
                        <b-field class="column" v-else><p>&nbsp;</p></b-field>
                        <hr>
                    </div>

                    <div v-if="maternity.visit_type === 'postnatal'">
                        <b-field class="column" label="Week number">
                            <b-numberinput
                                           v-model="maternity.week_number"
                                           min="2"
                                           max="6"
                                           step="4"></b-numberinput>
                        </b-field>
                        <div>
                            <b-field label="Did pregnancy result in a live birth(s)?">
                                <b-radio v-model="maternity.live_birth"
                                         native-value=1
                                         type="is-left">
                                    Yes
                                </b-radio>
                            </b-field>
                            <b-field>
                                <b-radio v-model="maternity.live_birth"
                                         native-value=0
                                         type="is-left">
                                    No
                                </b-radio>
                            </b-field>
                        </div>
                        <div>
                            <hr>
                            <div class="label">Baby's details</div>

                            <div v-for="baby in maternity.babies">
                                <b-field grouped>
                                    <b-field label="Baby">
                                        <b>{{ baby.id }}</b>
                                    </b-field>
                                    <b-field label="Date of Birth">
                                        <b-datepicker
                                            icon="birthday-cake"
                                            placeholder="Baby's DOB"
                                            v-model="maternity.babies_dob"
                                        >
                                        </b-datepicker>
                                    </b-field>
                                    <b-field label="PPSN">
                                        <b-input
                                            maxlength="20"
                                            placeholder="Baby's PPSN"
                                            type="input"
                                            v-model="baby.ppsn"></b-input>
                                    </b-field>
                                </b-field>
                            </div>

                            <b-field class="buttons" grouped>
                                <b-button class="is-primary" @click="addMaternityChild">Add</b-button>
                                <b-button class="is-danger" v-if="maternity.babies.length > 1" @click="removeMaternityChild" outlined>Remove</b-button>
                            </b-field>
                        </div>

                    </div>

                    <div v-else>

                        <div class="columns">
                            <b-field class="column" label="Gestational age (weeks)">
                                <b-numberinput v-model="maternity.gestational_age" placeholder="1" :min="0" :max="45"></b-numberinput>
                            </b-field>
                            <div class="column">
                                <b-field label="Is the pregnancy continuing?">
                                    <b-radio v-model="maternity.continuing"
                                             native-value=1
                                             type="is-left">
                                        Yes
                                    </b-radio>
                                </b-field>
                                <b-field>
                                    <b-radio v-model="maternity.continuing"
                                             native-value=0
                                             type="is-left">
                                        No
                                    </b-radio>
                                </b-field>
                            </div>
                            <b-field v-if="maternity.visit_type === 'antenatal'" class="column" label="Previous births">
                                <b-numberinput v-model="maternity.previous_births" placeholder="0" :min="0" :max="27"></b-numberinput>
                            </b-field>
                        </div>

                        <div v-if="maternity.continuing == 0" class="columns">
                            <div class="column">
                                <b-field label=" Has pregnancy resulted in Miscarriage?">
                                    <b-radio v-model="maternity.miscarriage"
                                             native-value=1
                                             type="is-left">
                                        Yes
                                    </b-radio>
                                </b-field>
                                <b-field>
                                    <b-radio v-model="maternity.miscarriage"
                                             native-value=0
                                             type="is-left">
                                        No
                                    </b-radio>
                                </b-field>
                            </div>
                            <div class="column" v-if="maternity.miscarriage == 1">
                                <b-field label="Miscarriage Date">
                                    <b-datepicker
                                        icon="calendar"
                                        placeholder="DD/MM/YYYY"
                                        v-model="maternity.miscarriage_date">
                                    </b-datepicker>
                                </b-field>
                            </div>
                            <div class="column" v-else>&nbsp;</div>
                            <div class="column">&nbsp;</div>
                        </div>

                        <div v-if="maternity.continuing == 0">
                            <b-field
                                label="Reason">
                                <b-input
                                    maxlength="900"
                                    placeholder=""
                                    type="textarea"

                                    v-model="maternity.continuing_reason"></b-input>
                            </b-field>
                        </div>


                        <div v-if="maternity.visit_type === 'additional'">
                            <hr>
                            <b-field
                                label="Reason for the additional visit"
                                title="Additional claim may be rejected if insufficient clinical information is provided.">
                                <b-input
                                    maxlength="900"
                                    placeholder="Enter reason .."
                                    type="textarea"

                                    v-model="maternity.additional_reason"></b-input>
                            </b-field>
                        </div>
                    </div>

                </div>
            </div>

            <div v-else>
                <div class="label">Select Patient</div>
                <b-field>
                    <b-autocomplete
                        v-model="select_pat"
                        ref="patautocomplete"
                        size="is-medium"
                        :data="asyncPatients"
                        :loading="loading_patients"
                        @keyup.enter.native="getAsyncData"
                        field="fullName"
                        placeholder="Find a Patient (Search by name / GMS number)"
                        icon="user-injured"
                        clearable
                        open-on-focus
                        @select="selectPatient" expanded>
                        <template slot-scope="props">
                            <div class="media is-size-5-touch">
                                <div class="media-content has-text-left">
                                    {{ props.option.fullName }}
                                    <br>
                                    <small>
                                        <b>DOB: </b>{{ props.option.DOB }}
                                        <b>GMS: </b> {{ props.option.cardno }}
                                        <b v-if="pastExpDate(props.option.exp)" style="color:gray">Check required!</b>
                                    </small>
                                </div>
                            </div>
                        </template>
                        <template slot="empty">
                            <div v-if="loading_patients">
                                <div class="columns is-centered">
                                    <div class="column is-narrow">
                                        <div class="has-text-centered">
                                            <div class="spring-spinner">
                                                <div class="spring-spinner-part top">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                                <div class="spring-spinner-part bottom">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No patients Found
                            </div>
                        </template>
                    </b-autocomplete>
                    <p class="control">
                        <b-button
                            class="button is-primary"
                            @click="getAsyncData"
                            size="is-medium">Search
                        </b-button>
                    </p>
                    <p class="control">
                        <b-button
                            class="button is-secondary"
                            @click="addPatient"
                            size="is-medium" grouped>
                            <span> Add New Patient </span>
                        </b-button>
                    </p>
                </b-field>
            </div>

            <hr>

            <div v-if="require_special_type">
                <div class="label">Select Special Type Consultation</div>
                <b-field>
                    <b-autocomplete
                        size="is-medium"
                        v-model="select_stc"
                        :data="filteredSTCData"
                        open-on-focus
                        placeholder="Select an STC"
                        icon="pen"
                        field="subtype"
                        @click.native="selectSTCHandler"
                        @select="selectSTC">
                        <template slot-scope="props">
                            <div class="media is-size-5-touch">
                                <div class="media-content has-text-left">
                                    {{ props.option.symbol }} - {{ props.option.subtype }}
                                    <br>
                                    <small>
                                        <b>{{ props.option.description }}</b>
                                    </small>
                                </div>
                            </div>
                        </template>
                        <template slot="empty">
                            <div v-if="loading_stc">
                                <div class="columns is-centered">
                                    <div class="column is-narrow">
                                        <div class="has-text-centered">
                                            <div class="spring-spinner">
                                                <div class="spring-spinner-part top">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                                <div class="spring-spinner-part bottom">
                                                    <div class="spring-spinner-rotator"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                No STCs Found
                            </div>
                        </template>
                    </b-autocomplete>
                </b-field>
                <hr>
            </div>

            <div v-if="this.user.role === 2 || this.user.role === 4">
                <b-field label="Select a doctor">
                    <b-select icon="stethoscope" placeholder="Select a name" expanded size="is-medium"
                              v-model="doctor_selection">
                        <option
                            v-for="option in group_members.DetGroupArr"
                            v-if="option.public == 1"
                            :value="option.id"
                            :key="option.fname">
                            {{ option.fname + ' ' + option.lname }}
                        </option>
                    </b-select>
                </b-field>
                <hr>
            </div>
        </div>

        <div v-if="vaccine_form">
            <div class="box">
                <h3 class="has-text-bold is-size-5">Vaccine Details</h3>
                <div class="button is-text" aria-controls="vaccine_fields" @click="openVaccine">{{
                        open_vaccine_text }}
                </div>
                <br>
                <b-collapse aria-id="vaccine_fields" :open.sync="open_vaccine">
                    <hr>
                    <!-- The following could be done with a simple JSON mapping -->
                    <div class="heading has-text-weight-bold is-size-6">
                    	<span v-if="this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O'">
                    		Public/Outbreak
                    	</span>
                        <span v-else-if="this.$store.state.select_stc[0] === 'S'">
                    		Influenza
                    	</span>
                        <span v-else-if="this.$store.state.select_stc[0] === 'U'">
                    		Hepatitis B
                    	</span>
                        <span v-else-if="this.$store.state.select_stc[0] === 'R' || this.$store.state.select_stc[0] === 'T'">
                    		Pneumococcal
                    	</span>
                        <span v-else>
                    		Vaccination
                    	</span>
                    </div>
                    <div v-if="this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O'">
                        <b-field class="is-half" label="Outbreak Code" expanded>
                            <b-select icon="hashtag" placeholder="Select an outbreak code" expanded
                                      v-model="vaccine.outbreakCode">
                                <option value="-1">Please select outbreak</option>
                                <option
                                    v-for="option in outbreakCodes"
                                    :value="option"
                                    :key="option">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <b-field grouped>
                        <b-field class="is-half" label="Injection Site" expanded>
                            <b-select icon="syringe" placeholder="Select a name" expanded
                                      v-model="vaccine.injection_site">
                                <option value="Arm">Arm</option>
                                <option value="Leg">Leg</option>
                                <option value="Nasal">Nasal</option>
                            </b-select>
                        </b-field>

                        <b-field v-if="this.$store.state.select_stc[0] === 'T'" class="is-half" label="Batch Number" expanded>
                            <b-select icon="hashtag" placeholder="Select a batch" expanded
                                      v-model="vaccine.batch_number" @input="selectBatch">
                                <option
                                    v-for="option in batches.pneumococcal"
                                    :value="option.batch"
                                    :key="option.vaccBatchBaseID">
                                    {{ option.batch + ' : ' + option.name}}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field v-else class="is-half" label="Batch Number" expanded>
                            <b-select icon="hashtag" placeholder="Select a batch" expanded
                                      v-model="vaccine.batch_number" @input="selectBatch">
                                <option
                                    v-for="option in custom_batches"
                                    :value="option.batch"
                                    :key="option.obID">
                                    {{ option.batch + ' : ' + option.name + ' : ' + option.obIDOutbreak}}
                                </option>
                            </b-select>
                        </b-field>

                    </b-field>
                    <div v-if="this.$store.state.select_stc[0] === 'U'">
                        <b-field grouped>
                            <b-field class="is-half" label="Shot (Hep. B Only)" expanded>
                                <b-input icon="vial" v-model="vaccine.shot" placeholder="1"></b-input>
                            </b-field>
                        </b-field>
                    </div>
                    <div v-if="this.$store.state.select_stc[0] === 'T'"><!--Pneumonical / Influenza-->
                        <hr>
                        <div class="heading has-text-weight-bold is-size-6">Influenza</div>
                        <b-field grouped>
                            <b-field class="is-half" label="Injection Site" expanded>
                                <b-select icon="syringe" placeholder="Select an injection site" expanded
                                          v-model="vaccine.secondInjSite">
                                    <option value="Arm">Arm</option>
                                    <option value="Leg">Leg</option>
                                </b-select>
                            </b-field>

                            <b-field class="is-half" label="Batch Number" expanded>
                                <b-select icon="hashtag" placeholder="Select a batch" expanded
                                          v-model="vaccine.secondBatchNum">
                                    <option
                                        v-for="option in batches.influenza"
                                        :value="option.batch"
                                        :key="option.batch">
                                        {{ option.batch + ' : ' + option.name}}
                                    </option>
                                </b-select>
                            </b-field>

                            <b-field label="Gender">
                                <b-select v-model="vaccine.gender">
                                    <option value="N">N/A</option>
                                    <option value="F">Female</option>
                                    <option value="M">Male</option>
                                    <option value="O">Other</option>
                                </b-select>
                            </b-field>
                        </b-field>
                        <hr>
                    </div>
                    <b-field grouped>
                        <b-field expanded container>
                            <b-field label="Add Medical Risk Codes">
                                <b-taginput
                                    v-model="vaccine.mrc"
                                    :data="filteredTags"
                                    autocomplete
                                    :allow-new="false"
                                    open-on-focus
                                    readonly
                                    field="display"
                                    icon="tags"
                                    size="is-medium"
                                    type="is-bland"
                                    placeholder="Add a Medical Risk Code"
                                    @typing="getFilteredTags">
                                </b-taginput>
                            </b-field>
                        </b-field>
                        <div v-if="this.select_stc === 'S* - Influenza Vaccination'">
                            <b-field label="Gender">
                                <b-select size="is-medium" v-model="vaccine.gender">
                                    <option value="N">N/A</option>
                                    <option value="F">Female</option>
                                    <option value="M">Male</option>
                                    <option value="O">Other</option>
                                </b-select>
                            </b-field>
                        </div>
                    </b-field>
                </b-collapse>
            </div>
            <br>
        </div>
        <div v-if="this.type !== 'maternity'" class="box">
            <h3 class="has-text-bold is-size-5">Additional Details</h3>
            <div v-if="!open_extra" class="is-text">
                Optional
            </div>
            <br>
            <b-collapse aria-id="extra_fields">
                <div v-if="input_standard">
                    <b-field grouped>
                        <b-field label="Location" expanded>
                            <b-select v-model="extra.location" expanded>
                                <option value="S">Surgery</option>
                                <option value="D">Domiciliary</option>
                            </b-select>
                        </b-field>
                        <b-field expanded label="Select Date & Time">
                            <datetime type="datetime" v-model="extra.fulldate" :max-datetime="maxDatetime"></datetime>
                        </b-field>
                    </b-field>
                </div>
                <div v-if="input_extended_oh">
                    <!-- Must open Extra automatically! -->
                    <hr>
                    <b-field grouped>
                        <b-field expanded label="Patient's signature"
                                 title="Is the patient signature (or representative) present?">
                            <b-checkbox v-model="extra.oh_patients_sig">Present</b-checkbox>
                        </b-field>
                        <b-field expanded label="Patient's confirmation"
                                 title="Has the patient confirmed Non-Routine Out-of-Hours consultation?">
                            <b-checkbox v-model="extra.oh_patients_confirm">Present</b-checkbox>
                        </b-field>
                        <b-field expanded label="Claiming GP's confirmation"
                                 title="Has the claiming GP confirmed Non-Routine Out-of-Hours consultation?">
                            <b-checkbox v-model="extra.oh_claiming_gp_confirm">Present</b-checkbox>
                        </b-field>
                        <b-field expanded label="Out of Hours Co-Operative"
                                 title="Was this consultation conducted while you were working in a rota for the Out of Hours Co-Operative?">
                            <b-checkbox v-model="extra.oh_cooperative">Yes</b-checkbox>
                        </b-field>
                    </b-field>
                </div>
                <div v-if="input_extended_so">
                    <!-- Must open Extra automatically! -->
                    <hr>
                    <b-field grouped>
                        <b-field expanded label="Referring doctor's signature">
                            <b-checkbox v-model="extra.so_referring_sig">Present</b-checkbox>
                        </b-field>
                        <b-field expanded label="Treating doctor's signature">
                            <b-checkbox v-model="extra.so_treating_sig">Present</b-checkbox>
                        </b-field>
                    </b-field>
                </div>
                <div v-if="input_extended_tr">
                </div>
                <div v-if="input_extended_em">
                </div>
            </b-collapse>
        </div>
        <hr>

        <!--                    Signature Buttons -->
        <div class="columns">
            <SignatureOptions :allow-sigs="allowSigs" :sig-error-message="sigErrorMessage"
                              :user="user" :stc_text="stc_text" v-on:onCancel="sigCancelled" v-on:onSubmit="sigTaken">
                <div class="column">
                    <div>
                        <b-tooltip label="Awaiting Signature" :active="awaiting_sig" type="is-white">
                            <b-button
                                @click="validateForm"
                                :loading="awaiting_sig"
                                type="is-primary"
                                size="is-medium"
                            >
                                Submit Claim
                            </b-button>
                        </b-tooltip>
                    </div>
                </div>
                <div class="column" v-if="backendValidationError">
                    <b-checkbox
                        title="Submit a potentially incorrect claim skipping its functional validation."
                        v-model="skipBackendValidation">
                        Ignore the validation
                    </b-checkbox>
                </div>
            </SignatureOptions>

        </div>

        <b-modal :active.sync="isComponentModalActive" has-modal-card>
            <GMSChecker :type="this.type" :pps="this.pps" :gms="this.patient_gms" v-on:new-patient="newPatientListener"></GMSChecker>
        </b-modal>

    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import GMSChecker from "./GMSChecker";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import {DateTime, Settings} from 'luxon';
import SignatureOptions from "./SignatureOptions";
import {mapState} from "vuex";
import {debounce} from "debounce";
import { DateTime as LuxonDateTime } from 'luxon'

Settings.defaultLocale = 'ie';

const starting_extra_button_text = 'Extra Fields';
export default {
    components: {
        SignatureOptions,
        GMSChecker,
        Datetime,
    },
    props: ['user', 'type'],
    data() {
        return {
            // functional backend validation:
            backendValidationError: false,
            skipBackendValidation: false,
            // form flags:
            awaiting_sig: false,
            form_debounce: false,
            isComponentModalActive: false,
            asyncPatients: [],
            expiredCard: false,
            services: [],
            select_stc: '',
            select_stc_obj: '',
            doctor_selection: null,
            stc_type_selection: null,
            open_extra: false,
            open_extra_text: starting_extra_button_text,
            tags: null,
            tabNumbers: {
                default: 0,
                emergency: 1,
                temporary: 2,
                pps: 3,
                EU: 4,
                private: 5,
            },
            extra: {},
            open_vaccine: true,
            open_vaccine_text: 'Hide Vaccine Details',
            vaccine: {
                name_manufacturer: '',
                injection_site: 'Arm',
                outbreakCode: null,
                batch_number: null,
                expiry_date: new Date(),
                shot: '',
                gender: '',
                mrc: [],
                necessity: '',
                // optional second vaccine
                secondInjSite: 'Arm',
                secondBatchNum: '',
                secondExpDate: '',
            },
            maternity: {},
            pat_mat_claims: [],
            selected_mat_claim_obj: null,
            // PPS Only
            pps_require_more_data: 0,
            pps: '',
            pps_valid: true,
            pps_details: {
                pps_first_name: '',
                pps_last_name: '',
                pps_dob: '',
                pps_address: '',
            },
            pat_age: '',

            //GMS
            patient_gms: '',

            // Loading trigger
            loading_stc: false,
            loading_patients: false,
            loading_pps: false,

            // First Load Trigger
            change_stc_text: true,

            // Misc Signature
            sigErrorMessage: 'Must enter Patient and STC to sign',
            paperSignature: false,

            // STC Rules
            stc_rule_message: '',

            // Submission
            validationMessageCodes: {
                FVED1: "A duplicate claim already exists in our system.",
                FVWD1: "Too few details to detect possible duplicates.",
                FVEP1: "This claim should be submitted as an Emergency under Dr. Flynn.",
                FVE01: "Cannot bill Out of Hours for a non-panel patient, please choose an Emergency type.",
                FVE02: "Cannot bill Out of Hours if the visit took place between 9AM and 5PM.",
                FVE03: "Cannot bill Out of Hours along with the Special Service. Please leave the Service blank or choose Out of Hours.",
                FVE04: "Cannot bill Emergency with the panel patient, please use Out of Hours.",
                FVW01: "This claim will result in 2 HSE claimforms if submitted, Emergency and Special Service.",
                FVE05: "Another group member is that patient's doctor, the Special Service claim for this patient should be billed under their doctor."
            },

            // Datetime picker
            maxDatetime: LuxonDateTime.local().plus({ days: 0 }).endOf('day').toISO(),
            
        };
    },
    methods: {
        setTab(tabInt) {

            /**
             *   As of 11/01/2021 setting tabs automatically has been turned off due to the following technical and functional errors.
             *
             *   1) Client was for example able to submit an emergency for the PPS only patient
             *       (form not resetting the patient properly when switching the tabs)
             *   2) We are now allowing clients to submit the wrong claims if they find them correct from their perspective
             *       (even though the validation rejected the claim)
             *
             *   Once the validation is fully sorted, and the "Ignore the validation" doesn't conflict with the automated tabs switching,
             *   this functionality can be turned back on. (It automatically switches between the tabs... Default, Emergency, Temp etc...)
             */

            //this.$emit('settab', tabInt);

            //console.log('Functional validation detected a suggested alternative tab: ' + tabInt);

        },
        setCurrentSTC(subtypeSymb, subtypeDesc) {
            for (let i = 0; i < this.services.length; i++) {
                let entry = this.services[i];
                if (entry.symbol === subtypeSymb && entry.subtype === subtypeDesc) {
                    this.select_stc = subtypeSymb + ' - ' + subypeDesc;
                    this.select_stc_obj = entry;
                }
            }
        },
        sigCancelled(){

        },
        pastExpDate(date) {
            return moment(date).isBefore(moment());
        },
        sigTaken(sig){
            this.awaiting_sig = false;
            this.$store.dispatch('setSigTaken', true)
            // this.sig_taken = true;
        },
        // Runs
        selectBatch(val){
            let selected_batch = this.custom_batches.find(element => element.batch === val);
            if(selected_batch !== undefined && selected_batch){
                this.vaccine.outbreakCode = selected_batch.obIDOutbreak;
            }
        },
        runPPSChecks() {
            this.pat_age = ''
            this.pps_require_more_data = 0
            if(this.ppsn(this.pps))
                this.checkForNameFromPPS();
        },
        checkPatientOfAge(pat_dob) {
            let normal = pat_dob.split("/")
            let today = new Date()
            let dob = new Date(normal[2], normal[1]-1, normal[0])
            let age = today.getFullYear() - dob.getFullYear()
            let month = today.getMonth() - dob.getMonth()
            //console.log(dob)
            if( month < 0 || (month === 0 && today.getDay() < dob.getDate()) )
                age--

            return age;
        },
        checkForNameFromPPS(){
            if(this.pps === ''){
                this.pps_patient_name = '';
            } else {
                axios.get('/patients/PPS/' + this.pps)
                    .then(response => {
                        //console.log(response.data)
                        if(response.data.length === 0){
                            this.pps_patient_name = '';
                        } else {
                            this.pps_patient_name = response.data[Object.keys(response.data)[0]].fullName;
                        }
                    })
                    .catch(error => {
                        //console.log(error);
                    })
            }
        },
        checkPPS(){
            if(this.ppsn(this.pps) === false) {
                this.pps_valid = false;
                return
            }
            this.pps_valid = true;
        },
        selectSTCHandler() {
            if ((this.type === 'emergency' || this.type === 'temporary') && this.select_stc_obj && this.select_stc_obj.symbol === 'No STC' && this.change_stc_text) {
                this.select_stc = '';
                this.change_stc_text = false;
            }
        },
        selectSTC(option) {
            if(option){
                let stc = option.symbol + ' - ' + option.subtype
                this.$store.dispatch('setSTC', stc)
                this.$store.dispatch('setSTCObject', option)
                //console.log(this.$store.state.select_stc_obj.id)
                if (option.symbol === "R*" || option.symbol === "S*"
                    || option.symbol === "T*" || option.symbol === "U*" || option.symbol === "O*") {
                    this.$store.dispatch('statics/fetchMRC', { vaccine_type: option.symbol });
                }
            }
        },
        selectPatient(option) {
            this.expiredCard = false
            if( moment(option.exp).isBefore(moment()) && this.type !== 'pps') {
                this.expiredCard = true
                //if(this.user.hasCert) {
                let checkMessage = "Checking GMS card validity..."
                this.flashMessage(checkMessage, 'is-warning')
                axios({
                    method: 'post',
                    url: '/check-gms',
                    data: { gms_number: option.cardno }
                }).then(response => {
                    if(response.data.validity == "Invalid") {
                        let mess = "Patient's GMS card has expired on " + response.data.end_date +" and is invalid"
                        this.flashMessage(mess, 'is-danger')
                    } else if(response.data.validity == "Valid") {
                        let foundMessage = "Valid card found, attempting update"
                        this.flashMessage(foundMessage, 'is-warning')
                        axios({
                            method: 'post',
                            url: '/newPatStatic',
                            data: {
                                firstName: option.fName,
                                lastName: option.lname,
                                cardno: option.cardno,
                                dob: response.data.pat_dob,
                                start_date: response.data.start_date,//new Date(response.data.start_date).toLocaleDateString('en-GB'),
                                end_date: response.data.end_date,//new Date(response.data.end_date).toLocaleDateString('en-GB'),
                                dr_number: response.data.doctor_num
                            }
                        }).then(res => {
                            let updateMessage = "GMS card successfully updated, new expiration date is: " + response.data.end_date
                            this.flashMessage(updateMessage, 'is-success')
                            option.exp = response.data.end_date
                            this.expiredCard = false
                        }).catch(err =>{
                            this.flashMessage(err, 'is-danger')
                        });
                    } else {
                        this.flashMessage("Invalid GMS card number. Could not perform a validity check.", 'is-danger')
                    }
                }).catch(err =>{
                    this.flashMessage(err, 'is-danger')
                    if(err.response.data.errors.cert)
                        this.flashMessage(err.response.data.errors.cert, 'is-danger');
                });
            }
            this.$store.dispatch('setPatient', option)
            if (option) {
                if(this.type === 'pps' || this.type === 'maternity') {
                    this.pps = option.PPS
                }

                if(this.type === 'maternity') {
                    this.getPatientsMaternityClaims();
                }

                // Secretary only
                if (this.user.role === 2 || this.user.role === 4) {
                    // Grab the patients panel doctor
                    let patient_doctor = parseInt(option.drNum);
                    for (let i = 0; i < this.group_members.DetGroupArr.length; i++) {
                        // Loop through the doctors in this group and autoselect
                        if (parseInt(this.group_members.DetGroupArr[i].hseno) === patient_doctor) {
                            this.doctor_selection = this.group_members.DetGroupArr[i].id;
                        }
                    }
                }
            }
        },
        extraFieldsOnSTCSelection() {
            if (typeof this.stc_type_selection == "undefined" || this.stc_type_selection == null) {
                this.openExtra(2);
            } else {
                switch (this.stc_type_selection[0]) {
                    case 'H':
                        this.openExtra(1);
                        break;
                    case 'O':
                        this.openExtra(1);
                        break;
                    case 'C':
                        this.openExtra(1);
                        break;
                    case 'E':
                        this.openExtra(2);
                        break;
                    case 'T':
                        this.openExtra(2);
                        break;
                    default:
                        break;
                }
            }
        },
        getFilteredTags(text) {
            this.filteredTags = this.mrc_codes.MRCArr.filter((option) => {
                return option.name
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            });
        },
        getPatientsMaternityClaims() {
            this.pat_mat_claims = [];
            if(this.select_pat_obj.id === null || this.select_pat_obj.id === '') {
                this.flashMessage('There was an error fetching this patients previous claims.', 'is-warning', 5000);
                return;
            }

            axios.get('/getPatsMatClaims', {
                params: {
                    patient_id: this.select_pat_obj.id
                }
            }).then(({data}) => {
                let mat_claims = Object.values(data);
                if(!jQuery.isEmptyObject(mat_claims)) {
                    mat_claims.forEach((item) => {
                        for (const prop in item) {
                            if(moment(item[prop], "YYYY-MM-DD HH:mm:ss", true).isValid()) {
                                item[prop] = moment(item[prop]).format('YYYY-MM-DD');
                            }
                        }
                        this.pat_mat_claims.push(item)
                    })
                }
            });
        },
        matClaimSelected() {
            for (const prop in this.selected_mat_claim_obj) {
                if (moment(this.selected_mat_claim_obj[prop], 'YYYY-MM-DD', true).isValid()) {
                    switch (prop) {
                        case 'reg_date':
                            this.maternity.registration_date = new Date(this.selected_mat_claim_obj[prop]);
                            break;
                        case 'exp_delivery':
                            this.maternity.expected_delivery_date = new Date(this.selected_mat_claim_obj[prop]);
                            break;
                        default:
                            break;
                    }
                }
            }
        },
        newPatientListener(patient_id) {
            axios.get('/patients', {
                params: {
                    patient_id: patient_id
                }
            })
                .then(({data}) => {
                    let patients = Object.values(data.data);
                    for (let i = 0; i < patients.length; i++) {
                        this.$store.dispatch('setPatient', patients[i])
                        this.$store.dispatch('setPatientText', patients[i].fullName);
                    }
                })
                .catch(error => {
                    //console.log(error);
                    // this.loading_patients = true;
                });
        },
        autoSearch() {
            if (this.select_pat_obj.fullName.match(/\d{6,10}[A-Za-z]/)) {
                this.isComponentModalActive = true;
            }
        },
        openExtra(action = 0) {
            switch (action) {
                case 0:
                    this.open_extra = this.open_extra; // defaut - toggle
                    break;
                case 1:
                    this.open_extra = false; // force open
                    break;
                case 2:
                    this.open_extra = true; // force close
                    break;
                default:
                    break;
            }
            this.open_extra = !this.open_extra;
            if (this.open_extra_text === starting_extra_button_text) {
                this.open_extra_text = 'Extra Fields';
            } else {
                this.open_extra_text = starting_extra_button_text;
            }
        },
        openVaccine() {
            this.open_vaccine = !this.open_vaccine;
            if (this.open_vaccine_text === 'Show Vaccine Form') {
                this.open_vaccine_text = 'Hide and fill in later'
            } else {
                this.open_vaccine_text = 'Show Vaccine Form'
            }
        },
        showOptions: function () {
            this.showDropDown = true;
        },
        fetchSTC() {
            // TODO: This should be done in the store (index.js)
            var type = this.type
            if(type == 'temporary') type = 'temp_visit'
            this.loading_stc = true;
            axios.get('/services?type=' + type)
                .then(response => {
                    // Set defaults
                    this.services = Object.values(response.data);
                    if (this.type === 'emergency') {
                        this.setCurrentSTC('No STC', 'Emergency');
                    } else if (this.type === 'temporary') {
                        this.setCurrentSTC('No STC', 'Temporary Resident');
                    }
                    this.loading_stc = false;
                })
                .catch(error => {
                    //console.log(error);
                    this.loading_stc = false;
                });
        },
        flashMessage(message, type, duration = 5000) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                duration: duration,
                position: 'is-bottom',
            })
        },
        showNotification (message = '', type = 'is-warning') {
            this.$buefy.notification.open({
                type: type,
                message: message,
                closable: true,
                indefinite: true,
                queue: false,
            });
        },
        addMaternityChild() {
            let babiesCount = this.maternity.babies.length
            this.maternity.babies.push({id: babiesCount+1,dob:null, ppsn:null})
        },
        removeMaternityChild() {
            if(this.maternity.babies.length > 1) {
                this.maternity.babies.splice(this.maternity.babies.length-1)
            }
        },
        resetMaternity() {
            this.maternity = {
                pcrs_id: null,
                visit_type: null,
                mat_claim_id:null,
                claim_date: new Date(),
                registration_date: null,
                expected_delivery_date: new Date(new Date().setDate(new Date().getDate() + 42*7)),
                miscarriage_date: null,
                previous_births: 0,
                gestational_age: 1,
                continuing: null,
                miscarriage: null,
                additional_reason: null,
                continuing_reason: null,
                week_number: 2,
                live_birth: null,
                babies_dob: null,
                babies: [
                    {
                        id: 1,
                        ppsn: null
                    }
                ]

            }
        },
        resetEC() {
            this.extra = {
                pps: '',
                    time: '',
                    fulldate: DateTime.local().toString(),
                    location: 'S',
                    ec_state_id: null,
                    ec_prescr_nr: '',
                    ec_dob: new Date(),
                    ec_lastname: '',
                    ec_firstname: '',
                    ec_exp_date: new Date(),
                    ec_id_nr: '',
                    ec_personal_id_nr: '',
                    ec_institution_nr: '',
                    so_referring_sig: true,
                    so_treating_sig: true,
                    oh_patients_sig: true,
                    oh_patients_confirm: true,
                    oh_claiming_gp_confirm: true,
                    oh_cooperative: true,
            }
        },
        resetForm() {
            if(this.type === 'maternity') {
                this.resetMaternity();
            }

            if(this.type === 'EU') {
                this.resetEC();
            }

            this.backendValidationError = false;
            this.skipBackendValidation = false;
            this.select_stc = '';
            this.select_pat = ''; // this is needed only to reset the autocomple search on reset
            this.select_pat_obj = {};
            this.$store.dispatch('setSTC', '');
            this.$store.dispatch('setSTCObject', '');
            this.$store.dispatch('setPatient', {cardno: '', id: null, fullName: '', dob: ''})
            this.select_stc_obj = '';
            // this.signature = '';
            this.$store.dispatch('setSignatureID', null);
            this.paperSignature = false;
            this.$store.dispatch('setSignature', '');
            this.doctor_selection = null;
            this.stc_type_selection = null;
            // this.sig_taken = false;
            this.$store.dispatch('setSigTaken', false)
            this.pps = '';
            if(this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O'){
                // For outbreak vaccines reset the batch and outbreak code
                this.vaccine.outbreakCode = null;
                this.vaccine.batch_number = null;
                if (this.type === 'emergency') {
                    this.setCurrentSTC('No STC', 'Emergency');
                } else if (this.type === 'temporary') {
                    this.setCurrentSTC('No STC', 'Temporary Resident');
                }
            }
        },
        checkMOD23(value) {
            let length = value.length
            let total = 0;
            for (var i = 0; i < 7; ++i) {
                total += Number(value.charAt(i)) * (8 - i);
            }
            if (length == 9) {
                total += (value.charCodeAt(8) - 64) * 9;
            }
            let mod = (total % 23);
            if (mod === 0) {
                mod = 23;
            }
            return Number(String.fromCharCode(64 + mod) == value.charAt(7).toUpperCase())
        },
        ppsn(ppsn) {
            ppsn = ppsn.trim();
            let regex = /^[0-9]{7}[A-Z]{1,2}$/;
            if (ppsn.match(regex)) {
                return this.checkMOD23(ppsn);
            }
            regex = /^[0-9]{7}[A-Z][\ WTX]?$/;
            if (ppsn.match(regex)) {
                return this.checkMOD23(ppsn);
            } else {
                return false;
            }
        },
        gmsCheck(gms) {
            gms = gms.trim()
            let regex = /^[A-Z]?[0-9]{6,7}[A-Z]{1}$/
            return regex.test(gms)
        },
        addPatient() {
            if(this.user.isPasswordExpired) {
                this.expiredPasswordPopup()
            } /*else if (!this.user.hasCert) {
                    this.$buefy.dialog.alert({
                        title: `We're sorry`,
                        message: `This function has been temporarily disabled due to issues with the PCRS system caused by the HSE cyber attack.`,
                        confirmText: 'OK'
                    })
                }*/ else {
                this.isComponentModalActive = true
            }
        },
        expiredPasswordPopup() {
            this.$buefy.dialog.confirm({
                title: 'Expired PCRS password',
                message: "Your PCRS password has expired. This claim will be submitted to Medserv, but will not be submitted to PCRS.",
                confirmText: 'Submit to Medserv',
                cancelText: 'Cancel',
                type: 'is-danger',
                hasIcon: true,
                onCancel: () => { return false }
            });
        },
        checkMRCAge(mrc) {
            if (Object.keys(mrc).length > 0) {
                for (const[key, value] of Object.entries(mrc)) {
                    for (const[k, v] of Object.entries (value)) {
                        if(k === 'age' && this.pat_age !== '') {
                            if ((v === '65+' && this.pat_age < 65) || (v === 'child' && this.pat_age > 18)) {
                                return true
                            }
                        }
                    }
                }
            }
            return false
        },
        validateEC() { //EC EU Patient details all required except prescription
            for (let key in this.extra) {
                if (this.extra.hasOwnProperty(key) && key.startsWith('ec_') && key !== 'ec_prescr_nr') {
                    if (!this.extra[key]) {
                        return false;
                    }
                }
            }
            return true;
        },

        STCRulesBreached() {
            //for now only rules for young adult females and v young children

            let females_only = [30,72,73,74,75,76,77,83,84,85,86,87];
            let ya_females_only = [72,73,74,75,76,77];
            let up_to_six_only = [44,45,46];
            let thirty_one_to_forty_four = [83];// should be renamed to thirty_six_to_forty_four
            let older_than_thirty = [84,85,86,87];

            let pat_age = moment().diff(this.select_pat_obj.dob, 'years');
            let pat_gender = this.select_pat_obj.sex;
            let procedure_date = moment(this.extra.fulldate);
            let first_sept_twentythree = moment('2023-09-01');
            let first_july_twentyfour = moment('2024-07-01');

            // patient is male
            if ( pat_gender === "M"
                && females_only.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients.';
                return true
            }

            // patient not between 17 and 25 years old and date is before 01/09/2023
            // age range changed from 17 - 31 to 17 - 36 after 01/07/2024
            if ( (pat_age < 17 || pat_age > 26)
                && procedure_date.isBefore(first_sept_twentythree)
                && ya_females_only.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'For procedures before 1st September 2023 this STC can only be selected for female patients aged 17 to 26.';
                return true
            } else if ( (pat_age < 17 || pat_age > 31)
                && procedure_date.isSameOrAfter(first_sept_twentythree)
                && procedure_date.isBefore(first_july_twentyfour)
                && ya_females_only.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients aged 17 to 31.';
                return true
            } else if ( (pat_age < 17 || pat_age > 36)
                && procedure_date.isSameOrAfter(first_july_twentyfour)
                && ya_females_only.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients aged 17 to 36.';
                return true
            } else if ( (pat_age < 31 || pat_age > 44)
                && procedure_date.isSameOrAfter(first_sept_twentythree)
                && procedure_date.isBefore(first_july_twentyfour)
                && thirty_one_to_forty_four.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients aged 31 to 44.';
                return true;
            } else if ( (pat_age < 36 || pat_age > 44)
                && procedure_date.isSameOrAfter(first_july_twentyfour)
                && thirty_one_to_forty_four.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients aged 36 to 44.';
                return true;
            } else if ( (pat_age < 30)
                && procedure_date.isSameOrAfter(first_sept_twentythree)
                && older_than_thirty.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for female patients over 30 years old.';
                return true;
            }

            //patient is not a child of 6 years or younger
            if(pat_age > 6 && up_to_six_only.includes(this.$store.state.select_stc_obj.id)) {
                this.stc_rule_message = 'This STC can only be selected for children aged 6 or younger.';
                return true
            }

            this.stc_rule_message = '';
            return false;
        },

        validateForm() {
            let notGMS = ['pps', 'maternity', 'EU'];

            if(this.user.isPasswordExpired) {
                this.expiredPasswordPopup()
            }

            if(this.expiredCard === true && !notGMS.includes(this.type)) {
                this.flashMessage("Patient's GMS card has expired, can't submit a claim.", 'is-danger');
                return false
            }

            if(this.STCRulesBreached() === true) {
                this.flashMessage(this.stc_rule_message, 'is-danger');
                return false;
            }

            if ((!notGMS.includes(this.type)) && (!this.select_pat_obj || !this.select_pat_obj.cardno)) {
                this.flashMessage('Please select a patient from the dropdown', 'is-danger');
            } else if ((this.type !== 'EU' && this.type !== 'maternity') && (this.$store.state.select_stc[0] === '' || !this.$store.state.select_stc[0])) {
                this.flashMessage('Please select a consultation type', 'is-danger');
            } else if ((this.user.role === 2 || this.user.role === 4) && !this.doctor_selection) {
                this.flashMessage('Please select a performing doctor', 'is-danger')
            } else if((this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O') && this.vaccine.outbreakCode === null){
                this.flashMessage('Please enter outbreak code and batch number')
            } else if (((this.type !== 'EU' && this.type !== 'pps' && this.type !== 'maternity') && this.select_stc.match((/(V|v)accination/)))
                && (this.vaccine.injection_site === '' || this.vaccine.batch_number === '' || this.vaccine.batch_number === null)) {
                this.flashMessage('Please ensure vaccine details are entered', 'is-danger');
            } else if ((this.type === 'pps' || this.type === 'maternity') && (this.select_pat_obj.PPS === '' || !this.ppsn(this.select_pat_obj.PPS)) ) {
                this.flashMessage('Patient\'s PPS number is invalid', 'is-danger');
            } else if ( this.select_stc.match((/(V|v)accination/)) && Object.keys(this.vaccine.mrc).length === 0 ) {
                this.flashMessage("Medical Risk Code field cannot be left empty", 'is-danger')
            } else if ( this.select_stc.match((/(V|v)accination/)) && Object.keys(this.vaccine.mrc).length > 0 && this.checkMRCAge(this.vaccine.mrc) ) {
                this.flashMessage("Patient's age is not correct for the chosen Medical Risk Code", 'is-danger')
            } else if ( this.type === 'maternity' && (this.maternity.visit_type === null) ) {
                this.flashMessage("Please select a visit type", 'is-danger')
            } else if (this.type === 'maternity' && this.maternity.visit_type === 'antenatal' && this.maternity.expected_delivery_date === null) {
                this.flashMessage("Please specify the expected delivery date", 'is-danger')
            } else if ( this.type === 'maternity' && (this.maternity.visit_type === 'additional' && (this.maternity.additional_reason === null || this.maternity.additional_reason.length < 5)) ) {
                this.flashMessage("Please state a reason for additional visit", 'is-danger')
            } else if (this.type === 'maternity' && (this.maternity.visit_type === 'postnatal' && this.maternity.live_birth === null ) ) {
                this.flashMessage("Please specify pregnancy result", 'is-danger')
            } else if (this.type === 'maternity' && this.maternity.visit_type !== 'postnatal' && this.maternity.continuing === null) {
                this.flashMessage("Please tick maternity continuation selection", 'is-danger')
            } else if(this.type === 'maternity' && this.maternity.visit_type !== 'postnatal' && this.maternity.continuing == '0' && this.maternity.miscarriage === null) {
                this.flashMessage("Please tick miscarriage selection", 'is-danger');
            } else if (this.type === 'maternity' && this.maternity.visit_type !== 'postnatal' && this.maternity.continuing == '0' && this.maternity.miscarriage == '1' && this.maternity.miscarriage_date === null) {
                this.flashMessage("Please provide a miscarriage date", 'is-danger')
            } else if (this.type === 'maternity' && this.maternity.visit_type !== 'postnatal' && this.maternity.continuing == '0' && this.maternity.continuing_reason === null) {
                this.flashMessage("Please provide a reason", 'is-danger')
            } else if (this.type === 'EU' && !this.validateEC() ) {
                this.flashMessage("All fields except Prescription SN are required to continue.", 'is-danger')
            } else if (!this.maternityDatesCheck()) { //reg date has to be before claim and birth dates
                this.flashMessage("Registration date has to be before claim date and birth date", 'is-danger');
            } else {
                if (!this.sig_taken) {
                    if (this.select_stc.substring(0,2) == 'CB' ||
                        this.select_stc.substring(0,2) == 'CD') {
                        this.$buefy.dialog.confirm({
                            title: 'No signature taken',
                            message: 'Are you sure you want to continue with this claim without a taking a signature?',
                            confirmText: 'Continue without signature',
                            type: 'is-primary',
                            hasIcon: true,
                            onConfirm: async () => await this.submitPreChecks(),
                        });
                    } else {
                        this.$buefy.dialog.confirm({
                            title: 'Signature required',
                            message: "This service requires patient's signature, submit only if you have already collected a signature.",
                            confirmText: 'I have already collected a signature',
                            type: 'is-danger',
                            hasIcon: true,
                            onConfirm: async () => await this.submitPreChecks(true),
                        });
                    }
                } else {
                    this.submitPreChecks();
                }
            }
        },
        submitPreChecks(paperSignature = false){
            // A signature collected previously would show up as a signed claim
            if (paperSignature) {
                this.paperSignature = true;
                this.$store.dispatch('setSignature', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAAfCAIAAACqHD4dAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAARdSURBVGhD7VnLceMwDHUtW40OW47vacRNbAU+pBCfc8isO0jwxyNEWop3/VEmbzQTEgQegCeaka3dRwfH/Y6wP9r0G+CmHX2Z/K6inw4T8U6Hk83vhw2Ifiv8iK5g0U+HPYtBmPZHFqRlOfqqQUUTAafDEWJDzONhyhCzq+AJYS/l4tT4jd5sQAzpEL3UbL6OecDG/tD2Icir/INdtjPHac+YdB1iq1YEEL3A2DUcwPbqL+ylSJy2/myb0WZgoJuacRXziK3Xu4ZgBz7zEhIpuuxxBYTKEMV0CktMe53nNtNsp9PRyehDlHbzgiIgUZ06YzjrorddIh3D1FcxD9iiWV1rekeuQY16vOT5QdKzEfMqY8I5dCEEbBLwedWEhb2JmZWF0+o7K4QRkYFR6uuY+2xVzNK7sRttuiXiHymfaurdsNiNVzSnWls6hMjQbl+73Wq7FuWGpswF3z4Gqc/nM5SxmnnUSNQpAVVdoyfwn265O7mZeqBzqHhpFcwiDN3IUnqG2LCBFWTlCbD8Fupc+AlzXqNNDFL/+vM7y1vPPGzkQtmMjOsqxzsdGPxg1yhh8Z3Od46x1zN8VjqEcIxT8u2kP1FQ/tO3wPRlauHExtqq2dmZGdmno596SfQR87iRYdkCVx1MDRae0yWc74VAMw1u35Ph/f2dtC7Xy8uLLd8Uto9Hmi+IDp+CxJDrefD6+lrkjusOui9pvrTTy+eOjhd/hnokSFMb9XBBcboux/4PLGq+KPrzITS1eYtHK74KGxO9aGpWxyYUJ2xJ9CKiXra2HcUJmxG9iIgXrW5IccI2RKevlEVHvLalOOEK0fF7EI5vi7e3N1IQM6Kyo+sJFSdsRnTC+e/5S6I/p+KEh4ne+zK+BmtFf7DiUuZIl+8p+q0V5yIul75CdP6pkXshqvzK2XwZnextBWEs+vB1WuGvvy5Y/Ci8+XUpf2ItQsd1hz3+76J3JahGgZKMRNcxQu1z/m7GteECXkKh0U3DMM70YQu/GzWr+UFe82OL/qgohuLQ1DPMJUHWKaTXsb+uYwdeoE3FRMbi257M5kQTHWs6GOvQE+RChCK/ZzB3wii8VuJTmYXoYjYjA+cx1lhJoUMxnqwCSSkhmZzRcZBhlN7N5c5iUNcMctE9hWFmBAOu5VjbKOCFLr/7t5VX9MLr3Ha6mmPBpwFOBALAuHGVcLZEYT2H1qObS4zBxiOIKS+m+VhlVyO6eqcDzN7yu8rpPgq3u2GVdN88kuhyjtta2zMghc5xOrajKKTnMPIAFGc9rjyEznQxAsTVGmhgMeoPhM0Y0berFROIZRTeq8SWEvSfWooTElrUoOKlRuki1j0AI9g2lxQpw8jo5kIPGROCU55e+KRVu+9IMcYjB5nTbhyQPdPj8w4B7WZqeMzoxSyH996NGbyzWAoDI5WeIoU6qmQEWRAbR4ZAPYckL1OBhIrFS1GKpLziOX2zUNGj9fuhJv4R/faQXZ95Pz4+AWBAQCsY4m1QAAAAAElFTkSuQmCC');
            } else {
                this.paperSignature = false;
            }

            if(moment(this.extra.fulldate).isBefore(moment().subtract(90, 'days'))) {
                this.$buefy.dialog.confirm({
                    title: 'Claim older than 90 days',
                    message: 'This claim is outside the submission dates for PCRS and cannot be claimed via PCRS online. '
                        + 'Please post directly to PCRS. Note that the claim can still be recorded for claim figures.',
                    cancelText: `Cancel`,
                    confirmText: 'Record claim',
                    type: 'is-success',
                    onConfirm: () => {
                        this.submitForm();
                    },
                });
            } else {
                this.submitForm();
            }

        },

        maternityDatesCheck()
        {
            if (this.type !== 'maternity') {
                return true;
            }

            let registration_date = moment(this.maternity.registration_date);
            let claim_date = moment(this.extra.fulldate);

            return !(this.maternity.visit_type === 'antenatal' && registration_date > claim_date);
        },

        check_is_ooh() {
            //this seems odd, but it is how it is
            //we used to confirm OOH only with time, but now we need to check the service id
            //which could possibly be updated to only check the id
            //but I'm leaving it as it is for I expect this to change again soon (TM)

            let time = this.extra.fulldate;
            let date = new Date(time);
            let hours = date.getHours();
            let day = date.getDay();
            return (day === 0 || day === 6) || (hours < 8 && hours >= 17) || (this.$store.state.select_stc_obj.id === 54);
        },

        submitForm() {
            if (!this.form_debounce) {
                this.form_debounce = true;
                let full_date = DateTime.fromISO(this.extra.fulldate);
                this.extra.time = full_date.toFormat('HH:mm');
                let is_ooh = this.check_is_ooh();
                let body = {
                    // Only takes ID's
                    'type': this.select_stc_obj.id,
                    'subtype': this.select_stc_obj.id,
                    'patient': this.select_pat_obj.id,
                    'date': full_date.toSQLDate(),
                    'signature_id': this.$store.state.signature_id,
                    'capturedSignature': this.signature,
                    'visit': '',
                    'service': this.$store.state.select_stc_obj.id,
                    'stc': this.$store.state.select_stc_obj.id,
                    'insurance': this.select_stc_obj.id,
                    'gms': this.select_stc_obj.id,
                    'distance': this.select_stc_obj.id,
                    'location': this.extra.location,
                    'extra': this.extra
                };
                if (this.doctor_selection) {
                    body.cliID = this.doctor_selection;
                }

                if (this.stc_type_selection != null) {
                    body.stcType = this.stc_type_selection;
                }

                if(body.stcType == null && is_ooh && !this.vaccine_form) {
                    body.stcType = 'H';
                    body.visit = 'N';
                }

                // Using ppsn as the field to check as this will get reset on submitting the form
                // This allows batch submitting of vaccines in the case
                if (this.vaccine_form) {
                    body.vacc = true;
                    body.vaccName = this.vaccine.name_manufacturer;
                    body.vaccInjSite = this.vaccine.injection_site;
                    body.vaccBatchNum = this.vaccine.batch_number;
                    body.vaccExpDate = this.vaccine.expiry_date;
                    body.vaccShot = this.vaccine.shot;
                    // body.vaccPPSN = this.vaccine.ppsn;
                    body.vaccPPSN = '';
                    body.vaccGender = this.vaccine.gender;
                    body.vaccMRC = this.vaccine.mrc;
                    body.vaccNecessity = this.vaccine.necessity;
                    if (this.$store.state.select_stc[0] === 'T') {
                        body.secondInjSite = this.vaccine.secondInjSite;
                        body.secondBatchNum = this.vaccine.secondBatchNum;
                    }
                    if (this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O') {
                        body.outbreakCode = this.vaccine.outbreakCode;
                    }
                }

                if(this.type === 'pps' || this.type === 'maternity'){
                    body.pps = this.select_pat_obj.PPS;
                    if(this.type === 'pps')
                        body.stcType = 'P';
                    if(this.type === 'maternity') {
                        body.is_maternity = true
                        body.maternity = this.maternity
                        if(this.selected_mat_claim_obj !== null && this.selected_mat_claim_obj.id > 0) {
                            body.maternity.pcrs_id = this.selected_mat_claim_obj.id;
                        }
                    }
                }

                if (this.paperSignature) {
                    body.paperSignature = true;
                } else {
                    body.paperSignature = undefined;
                }

                let submissionUrl = this.skipBackendValidation ? '/newClaimStatic/forceinsert' : '/newClaimStatic';

                axios({
                    method: 'post',
                    url: submissionUrl,
                    data: body
                })
                    .then(response => {
                        this.form_debounce = false;
                        if (response.status === 200) {
                            this.flashMessage('Claim Submitted', 'is-success');
                            this.resetForm();
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else if (error.response.status === 400) {

                            /**
                             * Validation error handling and actioning (backend-side validation)
                             */

                            let validationCode = error.response.data.message.substring(0,5);
                            let message = '';

                            switch (validationCode) {
                                case 'FVED1': // Duplicates detected
                                    break;
                                case 'FVEP1': // Dr. Olinger's patients to be billed as an emergency under Dr. Flynn
                                    this.setTab(this.tabNumbers['emergency']);
                                    break;
                                case 'FVE01': // OOH for a non-panel prohibited (emergency required)
                                    this.setTab(this.tabNumbers['emergency']);
                                    break;
                                case 'FVE02': // OOH time between 9AM and 5PM (default required)
                                    this.setTab(this.tabNumbers['default']);
                                    break;
                                case 'FVE03': // OOH with special service prohibited (emergency required)
                                    this.setTab(this.tabNumbers['emergency']);
                                    break;
                                case 'FVE04': // Emergency with the panel prohibited (OOH required, default with OOH service)
                                    this.setTab(this.tabNumbers['default']);
                                    this.setCurrentSTC('No STC', 'Out of Hours');
                                    break;
                                case 'FVE05': // Wrong doctor selected (another group member is that patient's doctor)
                                    break;
                                default:
                                    console.log('Undefined validation error detected.');
                            }

                            if (this.validationMessageCodes[[validationCode]] !== undefined) {
                                message = this.validationMessageCodes[[validationCode]];
                            } else {
                                message = 'Undefined error';
                            }

                            console.log(error.response.data.message + ' ' + message);

                            message = 'Claim seems incorrect: ' + message + ' If you find this wrong and you wish to submit the claim anyway, you can now select the "Ignore the validation" and re-submit.';

                            this.showNotification(message, 'is-warning');

                            this.backendValidationError = true;

                        } else {
                            this.flashMessage('Server Error: ' + error.message, 'is-danger');
                        }
                        this.form_debounce = false;
                    });
            }
        },
        currentDateTime() {
            return moment().format('DD-MMM-YYYY HH:mm');
        },
        passwordExpiredModal() {
            if(this.user.isPasswordExpired === true) {
                this.$buefy.dialog.prompt({
                    title: 'Expired PCRS password',
                    message: `
                            <p style="color:red;"><b>Submission account's PCRS password has expired and requires renewal.</b></p><br><br>
                            Please check your submission account's email for a message from PCRS and proceed as per instructions to update your password.
                            Please <b>provide us with the new, updated password</b> for the PCRS account username <b>${ this.user.certUsername }</b>
                            via the input field below.<br><br>
                            If you haven't received the email from PCRS please visit
                            <a href="https://secure.sspcrs.ie/portal/userAdmin/pub/password/forgotten" target="_blank">this site</a>.<br>
                            Username: <b>${ this.user.certUsername }</b><br>
                            Password:
                        `,
                    inputAttrs: {
                        type: 'password',
                        placeholder: 'New password...',
                    },
                    confirmText: 'Update',
                    trapFocus: true,
                    onConfirm: (value) => {
                        let cliID = this.user.id
                        axios({
                            method: 'post',
                            url: '/updatePcrsPass',
                            data: {
                                cliID: cliID,
                                certPass: value
                            }
                        }).then(res => {
                            let updateMessage = "PCRS password succesfully updated."
                            this.flashMessage(updateMessage, 'is-success')
                            this.user.isPasswordExpired = false
                        }).catch(err =>{
                            this.flashMessage(err, 'is-danger')
                        });
                    }
                })
            }
        },
        getAsyncData: debounce(function () {
            this.$refs.patautocomplete.focus()
            let search = this.select_pat
            if (!search.length)
                return

            this.filteredPatientData.splice(0)
            this.loading_patients = true
            axios.get(`/patients?search=${search}&type=${this.type}`)
                .then(({ data }) => {
                    let patients = Object.values(data.data)
                    if(!jQuery.isEmptyObject(patients)) {
                        patients.forEach((item) => {
                            this.filteredPatientData.push(item)
                        })
                    } else if((this.type !== 'pps' && this.type !== 'maternity') && this.gmsCheck(search)) {
                        this.patient_gms = search
                        this.isComponentModalActive = true
                    } else if(this.type === 'pps' || this.type === 'maternity') {
                        this.pps = search
                        this.runPPSChecks()
                        this.isComponentModalActive = true
                    }
                    this.asyncPatients = this.filteredPatientData
                    this.loading_patients = false
                })
                .catch((error) => {
                    this.filteredPatientData.splice(0)
                    throw error
                })
        }, 50)

    },
    watch: {
        // whenever stc changes, automatically select the default STC
        select_stc: function (newSTC, oldSTC) {
            if (newSTC === 'No STC - Out of Hours') {
                this.stc_type_selection = 'H';
            }
            if (newSTC === 'No STC - Secondary Medical Opinion') {
                this.stc_type_selection = 'O';
            }

            this.extraFieldsOnSTCSelection()
        },
    },
    computed: {
        // ...mapState([{
        //     mrc_codes: state => state.statics.MRCs,
        // }]),
        ...mapState('statics', {
            mrc_codes: state => state.MRCs,
            filteredTags: state => state.filtered_tags,
            group_members: state => state.group_members,
            stc_types: state => state.stc_types,
            state_identifiers: state => state.state_identifiers,
            batches: state => state.vacc_batches,
            outbreakCodes: state => state.outbreak_codes,
            patients: state => state.patients
        }),
        ...mapState({
            select_pat_obj: state => state.select_pat_obj,
            select_pat: state => state.select_pat
        }),
        select_pat: {
            get: function () {
                return this.$store.state.select_pat;
            },
            set: function (newValue) {
                this.$store.dispatch('setPatientText', newValue);
            },
        },
        select_stc: {
            get: function() {
                return this.$store.state.select_stc;
            },
            set: function() {
                this.$store.dispatch('setSTC', newValue);
            }
        },
        sig_taken(){
            return this.$store.state.sig_taken
        },
        signature(){
            return this.$store.state.signature
        },
        validPPS() {
            return this.ppsn(this.pps);
        },
        allowSigs() {
            if(this.type === 'pps'){
                this.checkPPS()
                let valid_ppsn = this.pps_valid;
                let stc_selcted = this.select_stc != '';
                if(!valid_ppsn && !stc_selcted){
                    this.sigErrorMessage = 'PPSN is invalid and no STC is selected'
                } else if(!valid_ppsn) {
                    this.sigErrorMessage = 'PPSN is invalid'
                } else if(!stc_selcted){
                    this.sigErrorMessage = 'Must select an STC'
                }
                return !(this.pps_valid && this.select_stc !== '');
            } else if (this.type === 'maternity') {
                this.checkPPS()
                let valid_ppsn = this.pps_valid;
                if(!valid_ppsn) {
                    this.sigErrorMessage = 'Patient is not selected or PPSN is invalid'
                }
                return !this.pps_valid;
            } else if (this.type === 'EU') {
                let allow = this.validateEC();
                if(allow === false){
                    this.sigErrorMessage = 'All fields except Prescription SN are required.';
                }
                return !allow;
            } else {
                this.sigErrorMessage = 'Must enter Patient and STC to sign'
                return !(this.select_pat_obj.fullName !== '' && this.select_stc !== '');
            }
        },
        require_special_type() {
            if(this.type === 'EU' || this.type === 'maternity')
                return false;
            return true;
        },
        custom_batches() {
            if (this.$store.state.select_stc[0] === 'T') {
                // Pulmonical and Influenza
                return this.batches.pneumococcal.concat(this.batches.influenza);
            } else if (this.$store.state.select_stc[0] === 'U') {
                // Hep B
                return this.batches.hepB;
            } else if (this.$store.state.select_stc[0] === 'R') {
                // Pulmonical
                return this.batches.pneumococcal;
            } else if (this.$store.state.select_stc[0] === 'S') {
                // Influenza
                return this.batches.influenza
            } else if(this.$store.state.select_stc[0] === 'V' || this.$store.state.select_stc[0] === 'O'){
                if(this.vaccine.outbreakCode !== '' || this.vaccine.outbreakCode != null){
                    let outbreakID = this.vaccine.outbreakCode;
                    let allowedBatches = this.batches.publicHealthAdvised.filter((element) => {
                        return element.obIDOutbreak == outbreakID;
                    });
                    if(allowedBatches.length === 0){
                        return this.batches.publicHealthAdvised;
                    }
                    return allowedBatches;
                }
                return this.batches.publicHealthAdvised
            }
        },
        format() {
            return this.formatAmPm ? '12' : '24'
        },
        filteredPatientData() {
            return this.patients.filter((option, index) => {
                return (option.fullName.toString().toLowerCase().indexOf(this.select_pat.toLowerCase()) >= 0
                    || option.cardno.toString().toLowerCase().indexOf(this.select_pat.toLowerCase()) >= 0)
            })
        },
        filteredSTCData() {
            return this.services.filter((option) => {
                return (option.symbol + ' - ' + option.subtype)
                    .toString()
                    .toLowerCase()
                    .indexOf(this.select_stc.toLowerCase()) >= 0
            })
        },
        // Condition to display the vaccine form
        vaccine_form() {
            return (
                this.$store.state.select_stc[0] === 'R' ||
                this.$store.state.select_stc[0] === 'S' ||
                this.$store.state.select_stc[0] === 'T' ||
                this.$store.state.select_stc[0] === 'V' ||
                this.$store.state.select_stc[0] === 'U' ||
                this.$store.state.select_stc[0] === 'O'
            );
        },
        input_standard() {
            return (
                true
            );
        },
        input_extended_oh() {
            // out of hours
            return (
                this.stc_type_selection != null &&
                this.stc_type_selection[0] === 'H'
            );
        },
        input_extended_so() {
            // secondary medical opinion
            return (
                this.stc_type_selection != null &&
                this.stc_type_selection[0] === 'O'
            );
        },
        input_extended_ec() {
            // ec resident
            return (
                this.stc_type_selection != null &&
                this.stc_type_selection[0] === 'C'
            );
        },
        input_extended_tr() {
            // temporary resident
            return (
                this.stc_type_selection != null &&
                this.stc_type_selection[0] === 'T'
            );
        },
        input_extended_em() {
            // temporary resident
            return (
                this.stc_type_selection != null &&
                this.stc_type_selection[0] === 'E'
            );
        },
        out_of_hours_text() {
            return 'I confirm that I was seen by the doctor at ' + this.currentDateTime() +
                ' and that this was a non-routine consultation that, because of its urgency, had to be carried out ' +
                'outside of normal surgery hours. Please note the HSE may contact you independently to confirm this ' +
                'visit.'
        },
        stc_text() {
            return 'I verify that I have received the following Special Service From my Doctor: ' +
                (this.select_stc === '' || this.select_stc === null ? 'No STC selected' : this.select_stc)  +
                '. I authorise that my signature and information be saved ' +
                'by the practice\'s GPServ application for the sole purpose of the private claim or HSE treatment ' +
                'record. '
        },
        vaccine_text() {
            return 'I consent to have myself / child vaccinated with the influenza pneumoccoccal / ' +
                'Hep. B vaccine'
        }
    },
    mounted() {

        this.passwordExpiredModal()

        this.fetchSTC();

        this.resetEC();

        if (this.type === 'maternity') this.resetMaternity();
        if (this.type === 'default') this.stc_type_selection = null;
        if (this.type === 'EU') {
            this.resetEC();
            this.stc_type_selection = 'C';
        }
        if (this.type === 'temporary') this.stc_type_selection = 'T';
        if (this.type === 'emergency') this.stc_type_selection = 'E';
    },
};
</script>


<style scoped>

.is-half {
    width: 50%;
}

@media only screen and (orientation: portrait) {

}

@media only screen and (orientation: landscape) {

}

.dropdown-menu {
    @extend . is-opened-top;
}

</style>
