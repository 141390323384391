<template>
    <section>
        <div class="box">
            <b-menu>
                <b-menu-list label="Pink Sheets">
                    <div v-for="item in sheets">
                        <b-menu-item :label="item[0]">
                            <div v-for="i in item[1]">
                                <a :href="i.url" target="_blank">{{ i.name }}</a>
                            </div>
                        </b-menu-item>
                    </div>
                </b-menu-list>
            </b-menu>
        </div>
    </section>
</template>

<script>
export default {
    name: "Pinksheets",
    props: ["pinksheets"],
    data() {
        return {
        }
    },


    computed: {
        sheets() {
            return Object.entries(this.pinksheets).reverse();
        }
    },

}
</script>

<style scoped>

</style>
