<template>
    <div>
        <div class="columns">
            <div class="column">
                <!--                            Send to sig pad-->
                <div v-if="sig_taken">
                    <button
                        v-bind:class="[sig_taken ? 'is-success' : 'is-danger', 'button is-medium']">
                        Signature Taken
                    </button>
                </div>
                <div v-else>
                    <div v-if="allowSigs">
                        <b-tooltip
                            :label="sigErrorMessage">
                            <button
                                v-bind:class="[sig_taken ? 'is-success' : 'is-warning', 'button is-medium']"
                                disabled>
                                Sign on Tablet Device
                            </button>
                        </b-tooltip>
                    </div>
                    <div v-else>
                        <button
                            v-bind:class="[sig_taken ? 'is-success' : 'is-warning', 'button is-medium']"
                            :disabled="allowSigs"
                            @click="selectDevice">
                            Sign on Tablet Device
                        </button>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="sig_taken">
                    <button
                            v-bind:class="[sig_taken ? 'is-success' : 'is-danger', 'button is-medium']">
                        Signature Taken
                    </button>
                </div>
                <div v-else>
                    <div v-if="allowSigs">
                        <b-tooltip
                                :label="sigErrorMessage">
                            <button
                                    v-bind:class="[sig_taken ? 'is-success' : 'is-danger', 'button is-medium']"
                                    disabled>
                                Sign on Signature Pad
                            </button>
                        </b-tooltip>
                    </div>
                    <div v-else>
                        <button
                                v-bind:class="[sig_taken ? 'is-success' : 'is-danger', 'button is-medium']"
                                :disabled="allowSigs"
                                @click="openModal = true">
                            Sign on Signature Pad
                        </button>
                    </div>
                </div>
            </div>
            <slot></slot>
        </div>

    <b-modal :active.sync="sendToDeviceModal" scroll="keep" has-modal-card>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Sign on Tablet Device</p>
            </header>
            <section class="modal-card-body">
                <b-select placeholder="Select a device to send to" size="is-large"
                          v-model="device_selection">
                    <option
                        v-for="device in available_devices"
                        :value="device"
                        :key="device">
                        Device #{{ device }}
                    </option>
                    <option
                        v-for="device in busy_devices"
                        :value="device"
                        disabled
                        :key="device">
                        Device #{{ device }}
                    </option>
                </b-select>
            </section>
            <footer class="modal-card-foot">
                <button class="is-pulled-left button is-success is-large" @click="sendToDevice">Send to Device
                </button>
                <b-field>
                    <b-tooltip label="This will disconnect any devices" position="is-left" type="is-danger">
                        <b-button type="is-pulled-right is-text" @click="resetAllDevices">Reset All Devices
                        </b-button>
                    </b-tooltip>
                </b-field>
            </footer>
        </div>
    </b-modal>
    <b-modal :active.sync="openModal" full-screen scroll="keep">
        <div id="signatureForm">
            <div id="sigScreenOrientationMessage" ref="sigScreenOrientationMessage">
                <div class="columns is-centered">
                    <div class="column is-half has-text-centered">
                        <article class="message is-danger is-medium">
                            <div class="message-header">
                                <p>Turn Device</p>
                            </div>
                            <div class="message-body">
                                This section is only viewable in landscape mode
                            </div>
                        </article>
                        <p><img width="70%" src="/images/rotate.png"></p>
                    </div>
                </div>
            </div>
            <div class="box sigbox" id="sigbox">
                <div class="box has-text-weight-semibold">
                    <div class="content">
                        <p>{{ stc_text }}</p>
                    </div>
                </div>
                <VueSignaturePad class="sigarea" ref="signaturePad" width="100%" height="60vh" max-width="1280px" max-height="720px"/>
                <button class="button is-success" @click="save">Save</button>
                <button class="button is-warning" @click="undo">Undo</button>
                <button class="button is-pulled-right is-danger" @click="cancelSig">Close</button>
            </div>
        </div>
    </b-modal>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        name: 'SignatureOptions',
        props: {
            allowSigs: {},
            sigErrorMessage: {
                default: 'Must enter Patient and STC to sign'
            },
            stc_text: {},
            stc_html: {
                default: false
            },
            user: {},
        },
        data(){
            return {
                openModal: false,
                available_devices: [],
                busy_devices: [],
                sendToDeviceModal: false,
                device_selection: null,
                awaiting_sig: false,
                connected_devices: [],
                rand: Math.floor(Math.random() * 100) + 1

            }
        },
        computed: {
            sig_taken(){
                return this.$store.state.sig_taken
            }
        },
        methods: {
            cancelSig(){
                this.$emit('cancelSig')
                this.closeModal();
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            },
            // Save the signature, emit event and disptach to store
            save() {
                const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
                if (!isEmpty) {
                    this.$store.dispatch('setSignature', data)
                    this.closeModal();
                    this.signature = data;
                    this.$store.dispatch('setSigTaken', true)
                    this.flashMessage('Signature Recorded', 'is-primary')
                    this.$emit('onSubmit', data);
                }
            },
            closeModal() {
                this.openModal = false;
                this.sendToDeviceModal = false;
            },
            flashMessage(message, type) {
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
            checkDevices() {
                return axios.get('/getCurrentDevices')
                    .then(response => {
                        this.available_devices = response.data.available;
                        this.busy_devices = response.data.busy;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            selectDevice() {
                this.checkDevices().then(() => {
                    if (this.available_devices.length !== 1) {
                        this.sendToDeviceModal = true;
                    } else if (this.available_devices.length === 1) {
                        this.sendToDevice(this.available_devices[0]);
                    }
                });
            },
            sendToDevice(device = null) {
                // For some reason device is the button object from the onclick prop in the submit button
                // That's why the string check is here
                if (device === null || typeof device !== 'string') {
                    device = this.device_selection;
                }
                axios({
                    method: 'post',
                    url: '/sendToDevice',
                    data: {group: this.user['group'], device: device, stc_text: this.stc_text, rand: this.rand}
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.closeModal();
                            this.flashMessage('Sent to Device', 'is-success');
                            this.listenToDevice(this.user['group'], device);
                            this.connected_devices.push(device);
                            this.awaiting_sig = true;
                        }
                    })
                    .catch(error => {
                        if (error.message === 'Request failed with status code 422') {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else {
                            console.log(error);
                            this.flashMessage('Server Error: ' + error.message, 'is-danger');
                        }
                    });
            },

            listenToDevice(group, device) {
                window.Echo.private('claim.' + group + '.' + device + '.return' + '.' + this.rand)
                    .listen('SigTaken', (e, group, device) => {
                        this.$store.dispatch('setSignatureID', e.signature_id);
                        this.flashMessage('Signature Received!', 'is-success');
                        this.$store.dispatch('setSigTaken', true);
                        this.$emit('onSubmit')
                        this.awaiting_sig = false;
                        // Remove device from connected
                        const index = this.connected_devices.indexOf(device);
                        if (index > -1) {
                            this.connected_devices.splice(index, 1);
                        }
                        window.Echo.leaveChannel('claim.' + group + '.' + device);
                        window.Echo.leaveChannel('claim.' + group + '.' + device + '.return' + '.' + this.rand);
                        window.Echo.leaveChannel('claim.' + group + '.' + device + '.cancel' + '.' + this.rand);
                    });

                window.Echo.private('claim.' + group + '.' + device + '.cancel'+ '.' + this.rand)
                    .listen('SigNotTaken', (e, group, device) => {
                        this.flashMessage(e.message, 'is-danger');
                        this.awaiting_sig = false;
                        // Remove device from connected
                        const index = this.connected_devices.indexOf(device);
                        if (index > -1) {
                            this.connected_devices.splice(index, 1);
                        }
                        window.Echo.leaveChannel('claim.' + group + '.' + device);
                        window.Echo.leaveChannel('claim.' + group + '.' + device + '.return' + '.' + this.rand);
                        window.Echo.leaveChannel('claim.' + group + '.' + device + '.cancel' + '.' + this.rand);
                    });
            },
            disconnectDecives() {
                for (let i = 0; i < this.connected_devices.length; i++) {
                    let device = this.connected_devices[i];
                    let group = this.user['group'];
                    let res = this.disconnect(device, group);
                    res.then();
                }
            },
            disconnect(device, group) {
                return axios({
                    method: 'post',
                    url: '/device/disconnect',
                    data: {device, group, rand: this.rand}
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.flashMessage('Device Disconnected', 'is-success');
                        }
                    })
                    .catch(error => {
                        if (error.message === 'Request failed with status code 422') {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else {
                            this.flashMessage('Server Error' + error.message, 'is-danger');
                        }
                    });
            },
            resetAllDevices() {
                this.$buefy.dialog.confirm({
                    title: 'Reset all busy devices?',
                    confirmText: 'Reset Devices',
                    type: 'is-danger',
                    message: 'This may interrupt any busy devices that are taking signatures.<br> Are you sure you want to reset all devices?',
                    onConfirm: () => {
                        axios({
                            method: 'post',
                            url: '/device/disconnect-all',
                            data: {'group': this.user['group']}
                        })
                            .then(response => {
                                this.checkDevices();
                                this.flashMessage('Devices reset')
                            })
                            .catch(error => {
                                console.log(error);
                                this.flashMessage('There was a problem resetting the devices', 'is-danger')
                            });
                    }
                });
            },
        },
        mounted() {
            this.checkDevices();
            window.addEventListener("beforeunload", this.disconnectDecives);
        },
        beforeDestroy() {
            this.disconnectDecives();
            // Don't forget to clean up event listeners
            window.removeEventListener("beforeunload", this.disconnectDecives)
        },
    }
</script>
<style scoped>

    @media only screen and (orientation: portrait) {

    }

    @media only screen and (orientation: landscape) {

    }

    .sigbox {
        height: 100vh;
        width: 100%;
        margin-bottom: auto;
    }

    .is-half {
        width: 50%;
    }

    .sigarea {
        border: 1px solid darkgrey;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    @media only screen and (orientation: portrait) {
        #sigScreenOrientationMessage {
            display: block;
        }

        #sigbox {
            display: none;
        }
    }

    @media only screen and (orientation: landscape) {
        #sigScreenOrientationMessage {
            display: none;
        }

        #sigbox {
            display: block;
        }
    }

</style>
