<template>
    <div class="container">
        <div class="">
            <div v-if="welcome" class="has-text-centered">
                <div class="columns">
                    <div class="column">
                        <h1 class="giant">Signature Pad</h1>
                    </div>
                </div>
                <div class="">
                    <h1 class="giant sigLabel">#{{ this.number }}</h1>
                </div>
            </div>
            <div v-else id="signatureForm">
                <div id="sigScreenOrientationMessage" ref="sigScreenOrientationMessage">
                    <div class="columns is-centered">
                        <div class="column is-half has-text-centered">
                            <article class="message is-danger is-medium">
                                <div class="message-header">
                                    <p>Turn Device</p>
                                </div>
                                <div class="message-body">
                                    This section is only viewable in landscape mode
                                </div>
                            </article>
                            <p><img width="70%" src="/images/rotate.png"></p>
                        </div>
                    </div>
                </div>
                <div class="box sigbox" id="sigbox">
                    <div class="box has-text-weight-semibold">
                        <div class="content">
                            <p>{{ stc_text }}</p>
                        </div>
                    </div>
                    <VueSignaturePad class="sigarea" ref="signaturePad" width="100%" height="60vh"/>
                    <button class="button is-danger is-medium" @click="closeModal">Cancel</button>
                    <button class="button is-warning is-medium" @click="undo">Undo</button>
                    <button class="button is-pulled-right is-success is-medium" @click="save">Save Signature</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    let moment = require('moment');
    let axios = require('axios');
    export default {
        props: ["number", "user"],
        data() {
            return {
                // Signature Data
                signature_id: null,
                sig_taken: false,
                openModal: false,
                current_editing: null,
                stc_text: '',

                // Component data
                welcome: true,
                rand: null, // Random number generated by sender to specify a parent device
            };
        },
        methods: {
            closeModal(){
                this.welcome = true;
                axios({
                    method: 'post',
                    url: '/returnToHost',
                    data: { group: this.user['group'], device: this.number, message: 'Patient cancelled the signing', rand: this.rand }
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.disconnectDevice();
                            this.flashMessage('Canceled Signing', 'is-success');
                            this.welcome = true;
                        }
                    })
                    .catch(error => {
                        if (error.message === 'Request failed with status code 422') {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else {
                            console.log(error);
                            this.flashMessage('Server Error: ' + error.message, 'is-danger');
                        }
                    });
            },
            undo() {
                this.$refs.signaturePad.undoSignature();
            },
            save() {
                const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
                if(!isEmpty){
                    this.signature = data;
                    // this.flashMessage('Signature Recorded', 'is-primary')
                    this.submitForm();
                }
            },
            applyToChanel(){
                console.log('applying');
                window.Echo.private('claim.' + this.user.group + '.' + this.number)
                    .listen('RequestSignature', (e) => {
                        console.log(e);
                        this.stc_text = e.stc_text;
                        this.rand = e.rand;
                        this.flashMessage('Signature Request Received', 'is-success');
                        this.welcome = false;
                    })
                    .listen('SignatureAbort', (e) => {
                        console.log('abort');
                        if(this.welcome === false && this.rand === e.rand){
                            console.log('in');
                            this.rand = null;
                            this.flashMessage('Signature Aborted', 'is-warning');
                            this.welcome = true;
                        }
                    })
            },
            submitForm(){
                axios({
                    method: 'post',
                    url: '/saveSig',
                    data: { signature: this.signature }
                }).then(response => {
                    if (response.status === 200) {
                        this.signature_id = response.data.signature_id;
                        this.returnSignatureID();
                    }
                }).catch(error => {
                    if (error.message === 'Request failed with status code 422') {
                        this.flashMessage('Something went wrong', 'is-warning');
                    } else {
                        console.log(error);
                        this.flashMessage('Server Error: ' + error.message, 'is-danger');
                    }
                });
            },

            returnSignatureID(){
                axios({
                    method: 'post',
                    url: '/returnToHost',
                    data: { signature_id: this.signature_id, group: this.user['group'], device: this.number, rand: this.rand }
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.flashMessage('Signature Sent to Desk', 'is-success');
                            this.welcome = true;
                        }
                    })
                    .catch(error => {
                        if (error.message === 'Request failed with status code 422') {
                            this.flashMessage('Something went wrong', 'is-warning');
                        } else {
                            console.log(error);
                            this.flashMessage('Server Error: ' + error.message, 'is-danger');
                        }
                    });
            },

            disconnectDevice(){
                axios({
                    method: 'post',
                    url: '/device/disconnect',
                    data: { group: this.user['group'], device: this.number, rand: this.rand }
                });
            },
            flashMessage(message, type){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                })
            },
        },
        mounted() {
            let group = this.user['group'];
            let device = this.number;
        },
        created() {
            this.applyToChanel();
        }
    };
</script>

<style scoped>
    .giant{
        font-size: 60px;
    }
    .giant.sigLabel{
        font-size: 120px;
        font-weight: bolder;
    }

    .box {
        margin-top: 10px;
    }

    .is-horizontal >>> .field-label{
        text-align: left;
    }

    .sigbox {
        height: 100vh;
        width: 100%;
        margin-bottom: auto;
    }

    .sigarea {
        border: 1px solid darkgrey;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    @media only screen and (orientation:portrait){
        #sigScreenOrientationMessage {
            display: block;
        }
        #sigbox {
            display: none;
        }
    }

    @media only screen and (orientation:landscape){
        #sigScreenOrientationMessage {
            display: none;
        }
        #sigbox {
            display: block;
        }
    }
</style>
