<template>
    <div class="container column">
        <form action="">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Sign up for insured claims submission</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')"/>
                </header>

                <section class="modal-card-body">
                    Click <b>I'm interested</b> button below and <b>our team will soon be in contact</b> with you in regards to this matter.
                </section>

                <footer class="modal-card-foot columns is-centered">
                    <b-button
                        label="I'm interested"
                        type="is-primary"
                        @click="$emit('interested', 'interested')"/>
                    <b-button
                        label="Maybe later..."
                        @click="$emit('interested', 'maybe later')" />
                </footer>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "InterestModal",
}
</script>

<style scoped>

</style>
