export default {
  name: 'SignatureCapture',
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
      document.getElementById('sigCanvas').style.borderColor = "black";
      document.getElementById('capturedSignature').value = null;
      document.getElementById('sigConfirmation').innerHTML = ''; // External - outside the component
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        document.getElementById('sigCanvas').style.borderColor = "green";
        document.getElementById('capturedSignature').value = data;
        document.getElementById('sigConfirmation').innerHTML = '&#10004;'; // External - outside the component
        this.hideMe();
      }
    },
    hideMe() {
      //this.$refs.sigComponentContainer.parentElement.style.display = 'none';
      this.$refs.sigComponentContainer.style.display = 'none';
    }
  },
  mounted: function() {
    document.getElementById('sigContainer').addEventListener('click', function(event) {
        document.getElementById('sigCanvas').style.borderColor = "black";
        document.getElementById('capturedSignature').value = null;
        document.getElementById('sigConfirmation').innerHTML = ''; // External - outside the component
    });
  }
}