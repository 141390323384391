<template>
<div id="big-box">
    <b-loading v-model="isLoading"></b-loading>

    <b-field>
        <b-button @click="showAddClientModal()" class="is-info">New Client</b-button>
    </b-field>

    <b-tabs type="is-toggle" expanded>
        <b-tab-item label="Table">
            <b-table
                :data="preppedClients"
                :columns="clientsColumns"
                :selected.sync="selected"
                :debounce-search="300"
                :paginated="true"
                :per-page="10"
                focusable>
            </b-table>
        </b-tab-item>
        <b-tab-item
            :label=selected.name>
            <template>
                <div v-if="selected.id === undefined">
                    <p class="has-text-centered"><b>Select a client from the table.</b></p>
                </div>

                <div v-else class="container">

                    <p class="has-text-centered"><b>Client ID: {{ selected.id }}</b></p>

                    <b-field grouped>
                        <b-field label="DETAILS">
                        </b-field>
                    </b-field>

                    <b-field grouped >
                        <b-field label="prefix">
                            <b-input v-model="loaded_client.prefix"></b-input>
                        </b-field>

                        <b-field label="Client Name">
                            <b-input v-model="loaded_client.firstname"></b-input>
                        </b-field>
                        <b-field label="Client Surname">
                            <b-input v-model="loaded_client.lastname"></b-input>
                        </b-field>

                        <b-field label="Public">
                            <b-switch v-model="loaded_client.active"
                                      size="is-medium"
                            >
                                {{ this.isActive() }}
                            </b-switch>
                        </b-field>

                    </b-field>

                    <b-field grouped>
                        <b-field label="Submitting client HSE code">
                            <b-select  v-model="loaded_client.submitting_client_id" placeholder="None" :loading="isHSELoading">
                                <option
                                    v-for="member in hse_members"
                                    :key="member.id"
                                    :value="member.id">
                                    {{ member.hse_code }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Medserv Client ID">
                            <b-input v-model="loaded_client.medserv_id"></b-input>
                        </b-field>

                        <b-field label="Setup">
                            <b-switch v-model="loaded_client.fully_setup"
                                      size="is-medium"
                            >
                                {{ this.isSetup() }}
                            </b-switch>
                        </b-field>
                    </b-field>


                    <b-field grouped>

                        <b-field label="Phone">
                            <b-input v-model="loaded_client.phone"></b-input>
                        </b-field>

                        <b-field label="Mobile">
                            <b-input v-model="loaded_client.mobile"></b-input>
                        </b-field>

                    </b-field>

                    <b-field grouped>

                        <b-field label="HSE Code">
                            <b-input v-model="loaded_client.hse_code"></b-input>
                        </b-field>

                        <b-field label="Previous HSE Code">
                            <b-input v-model="previous_hse_code" readonly disabled></b-input>
                        </b-field>

                        <b-field label="Tax Code">
                            <b-input v-model="loaded_client.tax_code"></b-input>
                        </b-field>

                        <b-field label="EIR Code">
                            <b-input v-model="loaded_client.eircode"></b-input>
                        </b-field>

                        <b-field label="Cold Chain Number">
                            <b-input v-model="loaded_client.cold_chain_number"></b-input>
                        </b-field>

                    </b-field>

                    <b-field grouped>

                        <b-field label="Medical Council Number">
                            <b-input v-model="loaded_client.medical_council_number"></b-input>
                        </b-field>

                        <b-field label="Trading name">
                            <b-input v-model="loaded_client.trading_name" maxlength="255" type="textarea"></b-input>
                        </b-field>

                        <b-field label="Address">
                            <b-input v-model="loaded_client.address" maxlength="1024" type="textarea"></b-input>
                        </b-field>

                        <b-field label="Notes">
                            <b-input v-model="loaded_client.notes" maxlength="255" type="textarea"></b-input>
                        </b-field>

                    </b-field>

                    <b-field grouped>

                        <b-field label="Medical Council Number">
                            <b-input v-model="loaded_client.medical_council_number"></b-input>
                        </b-field>

                        <b-field label="Trading name">
                            <b-input v-model="loaded_client.trading_name" maxlength="255" type="textarea"></b-input>
                        </b-field>

                        <b-field label="Address">
                            <b-input v-model="loaded_client.address" maxlength="1024" type="textarea"></b-input>
                        </b-field>

                        <b-field label="Notes">
                            <b-input v-model="loaded_client.notes" maxlength="255" type="textarea"></b-input>
                        </b-field>

                    </b-field>

                    <b-field grouped>
                        <b-field>
                            <b-button @click="confirmSaveClientDetails()" type="is-primary">Save Client Details</b-button>
                        </b-field>
                    </b-field>

                    <b-field grouped>
                        <b-field label="SETTINGS">
                        </b-field>
                    </b-field>

                    <b-field grouped>
                        <b-field label="Role">
                            <b-select v-model="settings.role">
                                <option
                                    v-for="role in roles"
                                    :key="role.id"
                                    :value="role.id">
                                    {{ role.id }} - {{ role.name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Private">
                            <b-switch v-model="settings.medserv"
                                      size="is-medium"
                            >
                                {{ this.isPrivate() }}
                            </b-switch>
                        </b-field>

                        <b-field label="Public">
                            <b-switch v-model="settings.public"
                                      size="is-medium"
                            >
                                {{ this.isPublic() }}
                            </b-switch>
                        </b-field>

                        <b-field label="GPServ Admin">
                            <b-switch v-model="settings.isAdmin"
                                      size="is-medium"
                            >
                                {{ this.isGPServAdmin() }}
                            </b-switch>
                        </b-field>

                        <b-field label="Invoice Notification Email">
                            <b-input v-model="settings.invNotificationEmail"></b-input>
                        </b-field>

                    </b-field>

                    <b-field grouped>
                        <b-field>
                            <b-button @click="confirmSaveClientSettings()" type="is-primary">Save Client Settings</b-button>
                        </b-field>
                    </b-field>
                    <hr>
                </div>
            </template>
        </b-tab-item>
    </b-tabs>

    <b-modal :active.sync="isNewClientModalActive" close-button-aria-label="Close">
        <NewClientModal v-on:save-new-client="saveNewClient"></NewClientModal>
    </b-modal>

</div>
</template>

<script>
import axios from "axios";
import NewMemberModal from "./modals/NewMemberModal.vue";
import NewClientModal from "./modals/NewClientModal.vue";

export default {
    name: "ClientManagement",
    components: {
        NewClientModal
    },
    data() {
        return {
            selected: {name: "Client..."},
            clients: [],
            preppedClients: [],
            clusters: [],
            isLoading: false,
            loaded_client:{},
            settings: {},
            settings_default:{},
            submitting_client: null,
            client_default:{},
            hse_members:{},
            isHSELoading: false,
            isNewClientModalActive: false,
            previous_hse_code: '',
            roles: [
                {id: 1, name: 'Viewer (limited)'},
                {id: 2, name: 'Secretary (limited)'},
                {id: 3, name: 'Doctor (full)'},
                {id: 4, name: 'Partner/Group (full)'},
                {id: 5, name: 'Sigpad only'},
            ],
            clientsColumns: [
                {
                    field: 'id',
                    label: 'ID',
                    numeric: true,
                    searchable: true,
                    sortable: true,
                    width: '10%'
                },
                {
                    field: 'name',
                    label: 'Name',
                    searchable: true,
                    sortable: true,
                    width: '30%'
                },
                {
                    field: 'company',
                    label: 'Company',
                    searchable: true,
                    sortable: true,
                    width: '15%'
                },
                {
                    field: 'email',
                    label: 'Email',
                    searchable: true,
                    sortable: true,
                    width: '15%'
                },
                {
                    field: 'hse_code',
                    label: 'HSE Code',
                    searchable: true,
                    sortable: true,
                    width: '10%'
                },
                {
                    field: 'group.name',
                    label: 'Group',
                    searchable: true,
                    sortable: true,
                    width: '20%'
                }
            ],
        }
    },
    methods: {
        getClients() {
            this.isLoading = true;
            axios.get('/admin/getAllClients')
                .then(response => {
                    this.clients = Object.values(response.data);
                    this.isLoading = false;
                })
        },
        resetClient() {
            this.isLoading = true;
            this.loaded_client = {};
            this.settings = {};
            this.hse_members = {};
            this.client_default = {};
            this.submitting_client = {};
            this.previous_hse_code = '';
        },
        loadClient() {
            this.resetClient();
            axios.get('/admin/getClient', {params: {id: this.selected.id} } )
                .then(response => {
                    this.loaded_client = response.data;
                    this.client_default = {...this.loaded_client};
                    this.submitting_client = this.loaded_client.submitting_client != null ?
                        this.loaded_client.submitting_client:{};
                    this.settings = this.loaded_client.settings != null ?
                        this.loaded_client.settings:{};
                    this.settings_default = {...this.settings};
                    this.previous_hse_code = this.loaded_client.hse_code_previous_index;
                    this.getHSEMembers();
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                    this.resetClient();
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
        detectSettingsChanges() {
            const obj = this.settings;
            const copy = this.settings_default;
            return this.findChanges(obj, copy);
        },
        detectDetailsChanges() {
            const obj = this.loaded_client;
            const copy = this.client_default;
            return this.findChanges(obj, copy);
        },
        findChanges(obj, copy) {
            const changes = {};
            for (const key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    const subChanges = this.findChanges(obj[key], copy[key]);
                    if (Object.keys(subChanges).length > 0) {
                        changes[key] = subChanges;
                    }
                } else if (obj[key] !== copy[key]) {
                    changes[key] = obj[key];
                }
            }

            return changes;
        },
        confirmSaveClientSettings() {
            var changes = this.detectSettingsChanges();
            if(Object.keys(changes).length > 0) {
                this.$buefy.dialog.confirm({
                    message: 'Overwrite client settings?',
                    onConfirm: () => this.saveClientSettings(changes)
                })
            } else {
                this.flashMessage("No settings changes detected.", "is-danger");
            }

        },
        saveClientSettings(changes) {
            this.isLoading = true;
            let update_data = {
                id: this.loaded_client.id,
                changes: changes
            };
            axios.post('/admin/saveClientSettings', update_data)
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    this.settings_default = {...this.settings};
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        confirmSaveClientDetails() {
            var changes = this.detectDetailsChanges(changes);
            if(Object.keys(changes).length > 0) {
                this.$buefy.dialog.confirm({
                    message: 'Overwrite client details?',
                    onConfirm: () => this.saveClientDetails(changes)
                })
            } else {
                this.flashMessage("No details changes detected.", "is-danger");
            }
        },
        saveClientDetails(changes) {
            this.isLoading = true;
            let update_data = {
                id: this.loaded_client.id,
                changes: changes
            };
            axios.post('/admin/saveClientDetails', update_data)
                .then(response => {
                    if(changes.hse_code) {
                        this.previous_hse_code = this.client_default.hse_code;
                    }
                    this.flashMessage(response.data.message, response.data.type);
                    this.client_default = {...this.loaded_client};
                    this.selected.name = this.loaded_client.firstname
                        + ' ' + this.loaded_client.lastname;
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        flashMessage(message, type, duration = 5000) {
            this.$buefy.toast.open({
                container: 'toast-container',
                message: message,
                type: type,
                duration: duration,
                position: 'is-bottom',
            })
        },
        confirmSaveMappedCert() {
            this.$buefy.dialog.confirm({
                message: 'Save cert details?',
                onConfirm: () => this.saveCertDetails()
            })
        },
        showAddClientModal() {
            this.isNewClientModalActive = !this.isNewClientModalActive;
        },
        saveNewClient(firstname, lastname) {
            this.isLoading = true;
            axios.post('/admin/createClient', {firstname, lastname})
                .then(response => {
                    this.flashMessage(response.data.message, response.data.type);
                    //add new client to this.clients and select it
                    this.clients.push(response.data.client);
                    this.selected = response.data.client;
                    this.selected.name = firstname + ' ' + lastname;
                })
                .catch(error => {
                    this.flashMessage(error.message, error.type);
                    this.isLoading = false;
                })
                .finally(() => {
                    this.isNewClientModalActive = false;
                });
        },
        isActive() {
            return this.loaded_client?this.loaded_client.active?'Yes':'No':'No';
        },
        isSetup() {
            return this.loaded_client?this.loaded_client.fully_setup?'Yes':'No':'No';
        },
        isPrivate() {
            return this.loaded_client.settings?this.loaded_client.settings.medserv?'Yes':'No':'No';
        },
        isPublic() {
            return this.loaded_client.settings?this.loaded_client.settings.public?'Yes':'No':'No';
        },
        isGPServAdmin() {
            return this.loaded_client.settings?this.loaded_client.settings.isAdmin?'Yes':'No':'No';
        },
        getHSEMembers() {
            if(this.loaded_client.group_id === null) return;
            this.isHSELoading = true;
            axios.get('/admin/getGroupsHSEMembers', {params: {group_id: this.loaded_client.group_id} })
                .then(response => {
                    this.hse_members = response.data;
                }).finally(() => {
                    this.isHSELoading = false;
                });
        },
    },
    computed: {
    },
    watch: {
        clients: function() {
            this.preppedClients = this.clients.map(client => {
                if (client.firstname === null) client.firstname = '';
                if (client.lastname === null) client.lastname = '';
                return {
                    id: client.id,
                    name: client.firstname + ' ' + client.lastname,
                    company: client.company,
                    email: client.email,
                    hse_code: client.hse_code,
                    group: client.group
                }
            });
        },
        selected: function() {
            this.isLoading = true;
            this.loadClient();
        },
    },
    mounted() {
        this.getClients();
    }
}
</script>

<style scoped>
 #big-box {
     width: 100%;
     margin-top: 30px;
     padding:5px;
     background: white;
     color: black;
 }
</style>
