window.preValidatePatientForm = function preValidatePatientForm() {

	// PRE-VALIDATION (validation before submitting)

	// Error section
	var errSection = document.getElementById('customPatientFormValidation');

	var errMessages = [];
	var errText = '';
	var i;

	// Input fields
	var firstNameInput = document.getElementById('formFirstNameText');
	var lastNameInput  = document.getElementById('formLastNameText');
	var dobInput       = document.getElementById('formDOBField');
	var typeInput      = document.getElementById('formTypeSelect');
	var sexInput       = document.getElementById('formSexSelect');
	var mrnInput       = document.getElementById('formMRNText');
	var emailInput     = document.getElementById('formEmailText');
	var phoneInput     = document.getElementById('formPhoneText');
	var addressInput1  = document.getElementById('formAddressText1');
	var addressInput2  = document.getElementById('formAddressText2');
	var addressInput3  = document.getElementById('formAddressText3');
	var addressInput4  = document.getElementById('formAddressText4');
	var addressInput5  = document.getElementById('formAddressText5');

	// Fields colors reset
	firstNameInput.style.borderStyle = '';
	firstNameInput.style.borderColor = '';
	lastNameInput.style.borderStyle = '';
	lastNameInput.style.borderColor = '';
	dobInput.style.borderStyle = '';
	dobInput.style.borderColor = '';
	typeInput.style.borderStyle = '';
	typeInput.style.borderColor = '';
	sexInput.style.borderStyle = '';
	sexInput.style.borderColor = '';

	// Name validation
	if (firstNameInput.value == null || firstNameInput.value == '') {
		errMessages.push("Please type a first name");
		firstNameInput.style.borderStyle = 'solid';
		firstNameInput.style.borderColor = 'red';
	}
	if (lastNameInput.value == null || lastNameInput.value == '') {
		errMessages.push("Please type a last name");
		lastNameInput.style.borderStyle = 'solid';
		lastNameInput.style.borderColor = 'red';
	}

	// DOB validation
	if (dobInput.value == '' || dobInput.value == null) {
		errMessages.push("Please choose a correct date of birth");
		dobInput.style.borderStyle = 'solid';
		dobInput.style.borderColor = 'red';
	}

	// Type validation
	if (!(typeInput.value>0)) {
		errMessages.push("Please choose a correct patient type");
		typeInput.style.borderStyle = 'solid';
		typeInput.style.borderColor = 'red';
	}

	// Gender validation
	if (sexInput.value == 'N') {
		errMessages.push("Please choose a gender (male/female/other)");
		sexInput.style.borderStyle = 'solid';
		sexInput.style.borderColor = 'red';
	}

	// Summary
	if (errMessages.length > 0) {
		for (i=0;i<errMessages.length;i++) {
			errText = errText + errMessages[i] + '<br>';
		}
		errSection.innerHTML = errText;
		return false;
	}

}

window.showPatientsDetails = function showPatientsDetails() {
	var i;
	var elements = document.getElementsByClassName('patientsTableHiddenColumn');
	for (i=0;i<elements.length;i++) {
		elements[i].style.display = 'table-cell';
	}
	document.getElementById('patientsTableExtendButton').style.display = 'none';
	document.getElementById('patientsTableHideButton').style.display = 'inline-block';
}

window.hidePatientsDetails = function hidePatientsDetails() {
	var i;
	var elements = document.getElementsByClassName('patientsTableHiddenColumn');
	for (i=0;i<elements.length;i++) {
		elements[i].style.display = 'none';
	}
	document.getElementById('patientsTableExtendButton').style.display = 'inline-block';
	document.getElementById('patientsTableHideButton').style.display = 'none';
}