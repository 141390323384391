<template>
    <div>
        <div class="label">Select a Procedure code</div>
        <b-field v-if="this.$store.state.private_insurer === 5">
            <b-radio v-model="freetext" :native-value="false">
                Search
            </b-radio>
            <b-radio v-model="freetext" :native-value="true">
                Enter custom procedure
            </b-radio>
        </b-field>
        <div v-if="showDropdown">
            <b-field>
                <b-autocomplete
                    v-model="select_procedure"
                    ref="procautocomplete"
                    size="is-medium"
                    :data="asyncProcedures"
                    :loading="loading_procedures"
                    @keyup.enter.native="getProcedures"
                    field="description"
                    placeholder="Find a Procedure (Search by code / description)"
                    clearable
                    open-on-focus
                    @select="selectProcedure" expanded>
                    <template slot-scope="props">
                        <div class="media is-size-5-touch">
                            <div class="media-content">
                                {{ props.option.code }}
                                <br>
                                <div style="max-width:40vw; white-space:normal !important;">
                                    <small>
                                        <b>{{props.option.description}}</b>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="empty">
                        <div v-if="loading_procedures">
                            <div class="columns is-centered">
                                <div class="column is-narrow">
                                    <div class="has-text-centered">
                                        <div class="spring-spinner">
                                            <div class="spring-spinner-part top">
                                                <div class="spring-spinner-rotator"></div>
                                            </div>
                                            <div class="spring-spinner-part bottom">
                                                <div class="spring-spinner-rotator"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            No procedures Found
                        </div>
                    </template>
                </b-autocomplete>
                <p class="control">
                    <b-button
                        class="button is-primary"
                        @click="getProcedures"
                        size="is-medium">Search
                    </b-button>
                </p>
            </b-field>
        </div>
        <div v-else>
            <b-field label="Procedure">
                <b-input
                    v-model="freetext_value"
                    size="is-medium"
                    placeholder="Enter procedure description"
                    icon="pen"
                    v-on:input="selectProcedure"
                    expanded>
                </b-input>
            </b-field>
            <b-field label="Cost">
                <b-input
                    type="number"
                    step="any"
                    icon="euro-sign"
                    size="is-medium"
                    v-model="freetext_cost"
                    expanded>
                </b-input>
            </b-field>
        </div>
        <div v-if="this.user.role === 2 || this.user.role === 4">
            <b-field label="Select a doctor">
                <b-select icon="stethoscope" placeholder="Select a name" expanded size="is-medium"
                            v-model="doctor_selection">
                    <option
                        v-for="option in group_members.DetGroupArr"
                        v-if="option.medserv == 1"
                        :value="option.id"
                        :key="option.fname">
                        {{ option.fname + ' ' + option.lname }}
                    </option>
                </b-select>
            </b-field>
            <hr>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import {mapState} from "vuex";
    import {debounce} from "debounce";

export default {
    props: ['user'],
    data() {
        return {
            loading_procedures: false,
            asyncProcedures: [],
            procedureData: [],
            doctor_selection: null,
            freetext: false,
            freetext_value: '',
            freetext_cost: 0.00
        }
    },
    methods: {
        selectProcedure(option) {
            this.$store.dispatch('setProcedure', option)
        },
        getProcedures: debounce(function () {
                    this.$refs.procautocomplete.focus()
                    let search = this.select_procedure
                    let ins_id = this.$store.state.private_insurer
                    if (!search.length) {
                        this.procedureData.splice(0)
                        return
                    }
                    this.loading_procedures = true
                    axios.get(`/getProcCode?search=${search}&ins=${ins_id}`)
                        .then(({ data }) => {
                            let procedures = Object.values(data)
                            this.procedureData.splice(0)
                            procedures.forEach((item) => {
                                this.procedureData.push(item)
                            })
                            this.asyncProcedures = this.procedureData
                            this.loading_procedures = false
                        })
                        .catch((error) => {
                            this.procedureData.splice(0)
                            throw error
                        })
                }, 50)
    },
    computed: {
        ...mapState('statics', {
            group_members: state => state.group_members,
        }),
        ...mapState({
            select_procedure_obj: state => state.select_procedure_obj,
            select_procedure: state => state.select_procedure,
        }),
        select_procedure: {
            get: function () {
                return this.$store.state.select_procedure;
            },
            set: function (newValue) {
                this.$store.dispatch('setProcedureText', newValue);
            },
         },
        showDropdown: function () {
            return !(this.$store.state.private_insurer === 5 && this.freetext === true);
        }
    }
}
</script>
<style lang="">

</style>
