
window.filterDropdown = function filterDropdown (input, dropdownID) {
	var input, filter, ul, li, a, i;
	filter = input.value.toUpperCase();
	select = document.getElementById(dropdownID);
	option = select.getElementsByTagName("option");
	for (i = 0; i < option.length; i++) {
		txtValue = option[i].textContent || option[i].innerText;
	    if (txtValue.toUpperCase().indexOf(filter) > -1) {
	    	option[i].style.display = "";
	    } else {
	    	option[i].style.display = "none";
	    }
	}
	var countDiv = dropdownID + 'Count';
	var count = 0;
	if (document.getElementById(countDiv)) {
		for (i = 0; i < option.length; i++) {
			if (option[i].style.display != "none") {
				count++;
			}
		}
		document.getElementById(countDiv).innerHTML = count;
	}
};
