<template>
    <div>
        <b-field label="Select Member Status">
            <b-select expanded v-model="garda_extra.member_type">
                <option value='serving' selected>Serving Member</option>
                <option value='nonserving'>Non-Serving Member</option>
            </b-select>
        </b-field>

        <div v-if="this.garda_extra.member_type === 'serving'">
            <div class="label">Superintendent's Email*:
                <small class="has-text-danger">* required</small>
            </div>
            <b-field>
                <b-input type="email"
                         v-model="garda_extra.superintendents_email"
                         expanded

                         maxlength="100">
                </b-input>
            </b-field>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            garda_extra: {
                member_type: null,
                superintendents_email: ''
            }
        }
    },
}
</script>

<style scoped>

</style>
