<template>
      <div class="container">
        <div class="box">
            <div class="column">
                <div class="title">Private Claims</div>
            </div>
            <div class="column">
                <div class="columns is-tablet">
               <div v-if="this.loaded == undefined || this.loaded == false" class="columns is-centered">
            	<div class="column is-narrow">
            		<div class="has-text-centered">
            			<div class="spring-spinner">
                        	<div class="spring-spinner-part top">
                            	<div class="spring-spinner-rotator"></div>
                            </div>
                            <div class="spring-spinner-part bottom">
                                <div class="spring-spinner-rotator"></div>
                            </div>
                        </div>
	            	</div>
	            </div>
	    	</div>
            <div v-if="this.loaded">
                <div v-if="this.resultsLoaded">
                    <template>
                        <section>
                            <b-tabs>
                                <b-table
                                    :data="results"
                                    :columns="columns">
                                </b-table>
                            </b-tabs>
                        </section>
	                </template>
                </div>
                <div v-if="!this.resultsLoaded">
                    <b-button
                        label="Takedown Claims"
                        type="is-success"
                        class="field"
                        @click="runTakeDown()" />
                </div>
            </div>
            </div>
            
        </div>
        </div>
    </div>
</template>

<script>
    let axios = require('axios');
    export default {
        props: [],
        data() {
            return {
                loaded: true,
                results: Array,
                resultsLoaded: false,
                columns: [
                    {
                        field: 'success',
                        label: 'Success',
                        
                    },
                    {
                        field: 'failed',
                        label: 'Failed',
                    },
                    
                ]
            } 
        },
        methods: {
           
            runTakeDown(){
                this.loaded = false;
                axios.get('/admin/getPrivateClaimsToMedserv')
                .then(response => {
                    this.results = response.data.results;
                    this.loaded = true;
                    this.resultsLoaded = true; 
                })
                .catch(error => {
                    this.flashMessage('Error, Takedown failed! Try Again', 'is-danger', 10000)
                    this.loaded = true;
                    this.resultsLoaded = true;
                })
            },
           
            flashMessage(message, type, duration = 3000){
                this.$buefy.toast.open({
                    container: 'toast-container',
                    message: message,
                    type: type,
                    position: 'is-bottom',
                    duration: duration
                })
            },
            
        }
    }
</script>