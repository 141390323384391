
<template>
    <b-carousel>
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
            <div class="columns is-mobile is-centered">
                <div class="column is-half">
                    <div class="card">
                        <div class="card-content">
                            <div class="content">
                                {{ carousel.review }}
                            </div>
                            <div class="media">
                                <div class="media-content">
                                    <p class="title is-5">{{ carousel.name }}</p>
                                    <p class="subtitle is-6">{{ carousel.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-carousel-item>
    </b-carousel>
</template>

<script>
export default {
    data(){
        return {
            carousels: [
                {
                    name: 'Consultant Gastroenterologist',
                    title: 'Dublin',
                    review: 'Both my accountant and secretary insist that I use Medserv.'
                },
                {
                    name: 'Consultant Anaesthetist',
                    title: 'Galway',
                    review: 'Extremely thorough follow up of unpaid invoices'
                },
                {
                    name: 'Consultant Oncologist',
                    title: 'Dublin',
                    review: 'Medserv makes my life easier.'
                },
            ]
        }
    }
}
</script>
