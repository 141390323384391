<template>

</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        data() {
            return {
                patient_fetched: false,
                loading: false,
                error: false,
                patient: {
                    validity: null,
                },
                form: {
                    gms: null,
                    first_name: '',
                    last_name: '',
                }
            };
        },
        methods: {
            checkGMS() {
                this.loading = true;
                this.error = false;
                axios.post('/check-gms', {'gms_number': this.form.gms})
                    .then(res => {
                        console.log(res);
                        this.loading = false;
                        this.patient_fetched = true;
                        this.patient = res.data;
                    })
                    .catch(err => {
                        this.error = err.response.data.errors;
                        this.loading = false;
                        this.patient_fetched = false;
                    })
            },
        },
    };
</script>

<style scoped>

</style>
