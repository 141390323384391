<template>
    <div class="box">
        <div class="field">
            <label class="label">New Group Name</label>
            <div class="control">
                <input class="input" type="text" v-model="newGroupName">
            </div>
        </div>
        <div class="field">
            <div class="control">
                <button class="button is-primary" @click="saveNewGroup">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewGroupModal",
    data() {
        return {
            newGroupName: '',
        }
    },
    methods: {
        saveNewGroup() {
            this.$emit('save-new-group', this.newGroupName);
        }
    }
}

</script>

<style scoped>

</style>
