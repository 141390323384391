<template>
    <div>
        <b-loading v-model="isLoading"></b-loading>

        <b-field label="Add Member">
            <b-table
                :data="clients"
                :debounce-search="300"
                :paginated="true"
                :per-page="10"
                focusable>

                <b-table-column field="id" label="ID" width="5%" numeric v-slot="props"
                                searchable
                                sortable
                >
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="user.id" label="User ID" width="10%" v-slot="props"
                searchable>
                    {{ props.row.user ? props.row.user.id : 'N/A' }}
                </b-table-column>

                <b-table-column field="user.name" label="Username" width="10%" v-slot="props"
                searchable>
                    {{ props.row.user ? props.row.user.name : 'N/A' }}
                </b-table-column>

                <b-table-column field="prefix" label="Prefix" width="10%" v-slot="props">
                    {{ props.row.prefix }}
                </b-table-column>

                <b-table-column field="firstname" label="Member Name" width="25%" v-slot="props"
                                searchable
                                sortable
                >
                    {{ props.row.firstname }}
                </b-table-column>

                <b-table-column field="lastname" label="Member Surname" width="25%" v-slot="props"
                                searchable
                                sortable
                >
                    {{ props.row.lastname }}
                </b-table-column>

                <b-table-column field="actions" label="Actions" width="25%" v-slot="props">
                    <b-button @click="confirmAddMember(props.row.id)" type="is-info">Add Member</b-button>
                </b-table-column>
            </b-table>
        </b-field>

    </div>
</template>

<script>
export default {
    name: "NewMemberModal",
    props: ['group_id'],
    data() {
        return {
            isLoading: false,
            clients: [],
        }
    },
    methods: {
        getClients() {
            this.isLoading = true;
            axios.get('/admin/getClientList')
                .then(response => {
                    this.clients = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        confirmAddMember(id) {
            this.$buefy.dialog.confirm({
                title: 'Add Member',
                message: 'Are you sure you want to add this member to the group?',
                confirmText: 'Add Member',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.addMember(id);
                }
            })
        },
        addMember(id) {
            this.isLoading = true;
            this.addedMember = this.clients.find(client => client.id === id);
            axios.post('/admin/addGroupMember', {
                group_id: this.group_id,
                client_id: id
            })
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: response.data.type
                    });
                    if(response.data.type === 'is-success')
                        this.$emit('member-added', this.addedMember);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
    mounted() {
        this.getClients();
    }
}
</script>

<style scoped>

</style>
